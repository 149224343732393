import { injectBaseConstantMethods } from "./BaseConstants"

const ConfiguratorSummaryStatus = {
    Active: "ACTIVE",
    Inactive: "INACTIVE",
}

const displayTextKeys = {
    [ConfiguratorSummaryStatus.Active]: "Active",
    [ConfiguratorSummaryStatus.Inactive]: "Inactive",
}

const labelClass = {}

export default injectBaseConstantMethods(
    ConfiguratorSummaryStatus,
    displayTextKeys,
    labelClass
)
