import React, { useState } from "react"
import { CardBody, Col, FormGroup, Label } from "reactstrap"
import * as Yup from "yup"
import toast from "react-hot-toast"

import { Modal } from "components/Modal"
import { Button } from "components/Button"
import { useFormik } from "formik"
import { GetDepartmentsListing } from "api/api.service"
import { AutoCompleteStyling } from "../../Common/AutoCompleteStyling"
import { AutoComplete } from "components/AutoComplete"
import useAsyncSelect from "hooks/useAsyncSelect"
import FormikErrorText from "components/FormikErrorText/FormikErrorText"

import {
    CreateBuildStation,
    UpdateBuildStation,
} from "../../../api/api.service"

// Note: Station == Department

const ManageStations = ({
    TITLES,
    trailerId,
    id = "",
    data = null,
    isOpen = false,
    request = () => {},
    onToggleModal = () => {},
}) => {
    const [isLoading, setIsLoading] = useState(false)
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            station: data?.id
                ? {
                      label: data?.department?.departmentName,
                      value: data?.departmentId,
                  }
                : null,
        },
        validationSchema: Yup.object({
            station: Yup.object().nullable().required("Station is required"),
        }),
        onSubmit: values => {
            handleSubmit(values)
        },
    })

    const {
        setSelectedOption: setSelectedStationOption,
        selectEntity: stationOptions,
        setSelectEntity: setStationsOption,
        handleSearchOnOptions: handleSearchStations,
        selectedOption: selectedStation,
        handleSelectAOption: handleSelectAStationOption,
    } = useAsyncSelect({
        apiFunc: GetDepartmentsListing,
        labelKey: "departmentName",
        valueKey: "id",
        onOptionSelect: option => {
            formik.setFieldValue("station", option)
        },
        customExecutionHandlers: () =>
            setSelectedStationOption({
                label: data?.department?.departmentName,
                value: data?.departmentId,
            }),
    })

    const handleModalClose = () => {
        formik.resetForm()
        setSelectedStationOption(null)
        onToggleModal()
    }

    const handleSubmit = values => {
        let bodyData = {
            departmentId: values.station.value,
        }

        if (id) {
            handleUpdate(bodyData)
        } else {
            handleAdd(bodyData)
        }
    }

    const handleUpdate = async data => {
        try {
            setIsLoading(true)

            let res = await UpdateBuildStation(id, data)
            toast.success(`Station has been changed successfully !`)
            handleModalClose()
            request()
        } finally {
            setIsLoading(false)
        }
    }
    const handleAdd = async data => {
        try {
            setIsLoading(true)
            let res = await CreateBuildStation({
                ...data,
                trailerId: Number(trailerId),
            })
            toast.success(`Station has been added successfully !`)
            request()

            handleModalClose()
        } catch (error) {
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <Modal
            isOpen={isOpen}
            handleModalToggling={handleModalClose}
            bodyClassName=""
            customButton={true}
            hideModalHeaderSeparator={true}
            headerClasses="header-container mb-0"
            sizeClasses="6"
            backdrop="static"
            modalTitle={
                id ? TITLES?.MODAL_UPDATE_TITLE : TITLES?.MODAL_ADD_TITLE
            }
        >
            <div className="d-flex flex-column align-items-center mb-4">
                <CardBody className="location-add-edit-card-container shadow-none">
                    <form
                        onSubmit={formik.handleSubmit}
                        className="d-flex flex-column align-content-center"
                    >
                        <div>
                            <>
                                <Label for="location" sm={3}>
                                    Select Station
                                </Label>
                                <FormGroup>
                                    {/*  */}
                                    <Col sm={8} className=" w-100">
                                        <AutoComplete
                                            handleBlur={() =>
                                                setStationsOption(prev => {
                                                    return {
                                                        ...prev,
                                                        page: 1,
                                                        Q: "",
                                                    }
                                                })
                                            }
                                            onChange={
                                                handleSelectAStationOption
                                            }
                                            onInputChange={handleSearchStations}
                                            placeholder="Search stations"
                                            classNamePrefix="add-new-req"
                                            customStyles={AutoCompleteStyling}
                                            options={stationOptions?.data}
                                            value={selectedStation}
                                            isLoading={stationOptions?.fetching}
                                            name={"station"}
                                        />
                                    </Col>
                                </FormGroup>
                                <FormikErrorText
                                    formikInstance={formik}
                                    fieldName={"station"}
                                />
                            </>
                        </div>
                        <div>
                            <Button
                                isLoading={isLoading}
                                type="submit"
                                title={
                                    id
                                        ? TITLES?.UPDATE_BTN_TEXT
                                        : TITLES?.ADD_BTN_TEXT
                                }
                                className="gt-btn-grad-primary  w-100"
                            />
                        </div>
                    </form>
                </CardBody>
            </div>
        </Modal>
    )
}

export default ManageStations
