import { injectBaseConstantMethods } from "./BaseConstants"

const TrailerTypeColors = {
   Green: "Green",
   Blue: "Blue",
   Orange: "Orange",
   Yellow: "Yellow",
   White: "White",
   Black: "Black",
}

const displayTextKeys = {
   [TrailerTypeColors.Green]: "Green",
   [TrailerTypeColors.Blue]: "Blue",
   [TrailerTypeColors.Orange]: "Orange",
   [TrailerTypeColors.Black]: "Black",
   [TrailerTypeColors.White]: "White",
   [TrailerTypeColors.Yellow]: "Yellow",
}

const labelClass = {
   [TrailerTypeColors.Green]: "trailer-type-green",
   [TrailerTypeColors.Blue]: "trailer-type-blue",
   [TrailerTypeColors.Orange]: "trailer-type-orange",
   [TrailerTypeColors.Black]: "trailer-type-black",
   [TrailerTypeColors.White]: "trailer-type-white",
   [TrailerTypeColors.Yellow]: "trailer-type-yellow",
}

export default injectBaseConstantMethods(
   TrailerTypeColors,
   displayTextKeys,
   labelClass
)
