import React, { useState, useRef, forwardRef, useImperativeHandle } from "react"
import toast from "react-hot-toast"

import "./Style.scss"
import { Modal } from "../../../../components/Modal"
import { getMediaPath, getPreviewFile } from "../../../../utils/mediaUtils"
import PreviewImage from "../PreviewImage/PreviewImage"
import uploadImgPlaceholder from "../../../../assets/images/upload-image-placeholder.svg"
import { ImagePreview } from "components/ImagePreview"

const ImageGallery = (props, ref) => {
    let {
        previousImages = [],
        removePreviousImages = () => {},
        previewOnly = false,
        isMulti = true,
        isDetailView,
        MAX_IMAGES = 15,
    } = props

    let uploadInputRef = useRef()

    const [selectedImages, setSelectedImages] = useState([])
    const [modalPreviewImageFile, setModalPreviewImageFile] = useState(null)
    const [modalPreviewImageUrl, setModalPreviewImageUrl] = useState(null)
    const [isModalOpen, setIsModalOpen] = useState(false)

    // State to track the selected image index
    const [selectedImageIndex, setSelectedImageIndex] = useState(0)

    const handleImageSelection = e => {
        let imageCount =
            e.target.files.length +
            selectedImages.length +
            previousImages.length

        if (imageCount > MAX_IMAGES) {
            toast.error("Cannot upload more than 15 images!")
            return
        }
        setSelectedImages(prev => [...prev, ...e.target.files])
    }
    const removeImageHandler = idx => {
        setSelectedImages(prev =>
            prev.filter(item => prev.indexOf(item) != idx)
        )
    }

    useImperativeHandle(ref, () => ({ getAllImages: () => selectedImages }))

    const handleSetModalPReviewFile = img => {
        setModalPreviewImageFile(img)
    }

    const handleSetModalPreview = index => {
        setSelectedImageIndex(index)
        setIsModalOpen(true) // Open the modal
    }

    const handleCloseModalPreview = () => {
        setIsModalOpen(false)
        setModalPreviewImageUrl(null)
        setModalPreviewImageFile(null)
    }

    // Convert previousImages to the format expected by getMediaPath
    const images = previousImages.map(image => ({
        media: {
            path: image.media.path,
        },
    }))

    const sliderSettings = {
        customPaging: index => {
            const thumbnailUrl = images[index]?.media?.path
            return (
                <img
                    src={thumbnailUrl}
                    alt={`Thumbnail ${index + 1}`}
                    width="30px"
                    height="30px"
                />
            )
        },
        dots: true,
        dotsClass: "slick-dots",
        waitForAnimate: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        initialSlide: selectedImageIndex,
    }

    return (
        <>
            {!previewOnly &&
                previousImages.length < MAX_IMAGES &&
                selectedImages.length < MAX_IMAGES && (
                    <div
                        style={{ width: "fit-content", height: "fit-content" }}
                        role="button"
                        onClick={() => {
                            uploadInputRef.current.click()
                        }}
                    >
                        <div>
                            <img src={uploadImgPlaceholder} />
                        </div>

                        <input
                            multiple={isMulti}
                            accept=".jpg,.jpeg,.png,image/*"
                            type={"file"}
                            ref={uploadInputRef}
                            hidden
                            onChange={handleImageSelection}
                        />
                    </div>
                )}
            <div className="d-flex flex-wrap justify-content-start">
                {selectedImages?.length && !previewOnly
                    ? selectedImages?.map((item, index) => (
                          <PreviewImage
                              onClick={handleSetModalPReviewFile}
                              imageToPreview={getPreviewFile(item)}
                              key={index}
                              handleRemoveImage={() =>
                                  removeImageHandler(index)
                              }
                          />
                      ))
                    : null}

                {previousImages?.length
                    ? previousImages.map((item, idx) => (
                          <PreviewImage
                              isDetailView={isDetailView}
                              onClick={() => handleSetModalPreview(idx)}
                              hideCross={previewOnly}
                              imageToPreview={getMediaPath(item)}
                              key={idx}
                              handleRemoveImage={() =>
                                  removePreviousImages(prev =>
                                      prev.filter((_, i) => i !== idx)
                                  )
                              }
                          />
                      ))
                    : null}
            </div>
            {/* To preview images */}
            {isDetailView && (
                <Modal
                    isOpen={isModalOpen}
                    handleModalToggling={handleCloseModalPreview}
                    bodyClassName="d-flex justify-content-center"
                    customButton={true}
                    hideModalHeaderSeparator={true}
                    headerClasses="header-container"
                    sizeClasses="8"
                    backdrop="static"
                    modalTitle="Preview"
                    scrollable={false}
                >
                    {previousImages && previousImages.length > 0 && (
                        <ImagePreview
                            images={images}
                            settings={sliderSettings}
                            containerClasses="slider-container"
                            imageContainer="image-container"
                            sliderImage="slider-img"
                        />
                    )}
                </Modal>
            )}
        </>
    )
}

export default forwardRef(ImageGallery)
