import React, { useState } from "react"
import { Row, Col } from "reactstrap"
import toast from "react-hot-toast"
import { useSelector } from "react-redux"

import { Button } from "../../../components/Button"
import { DataTable } from "../../../components/DataTable"
import { Modal } from "../../../components/Modal"
import { PurchaseOrderStatus, OrderManagementStatus } from "../../../constants"
import { dateTimeFormat, convertDateTime } from "../../../utils/dateFormatUtils"
import { useLocalPaginatedRequest } from "../../../hooks/useLocalPaginatedRequest"
import { CancelPOItem, GetPurchaseOrdersItems } from "../../../api/api.service"
import backorderIcon from "../../../assets/images/icons/backorder-icon.svg"
import CancelPOItemRequest from "./CancelPOItem"
import POItemsBackOrder from "./OrderRequests/POItemsBackOrder"
import { UserRoles } from "../../../constants"
import { AutoComplete } from "components/AutoComplete"
import { convertToSelectOptions } from "utils/commonUtils"
import customStyles from "./HeaderStatusStyling"
import { SearchBox } from "components/SearchBox"

const COLUMNS = {
    SKU: "SKU No.",
    ITEM_NAME: "Item Name",
    REMAINING_QTY: "Remaining Qty",
    ORDERED_QUANTITY: "Ordered Qty",
    RECEIVED_QUANTITY: "Received Qty.",
    SUPPLIED_QTY: "Supplied Quantity",
    UNIT_PRICE: "Unit Price",
    DATE_REQUESTED: "Date Requested",
    // LAST_ORDER: "Last Order",
    // EST_DELIVERY_DATE: "Est. Delivery Date",
    VENDOR_NAME: "Vendor Name",
    SUBSTITUTE_NAME: "Substitute Name",
    STATUS: "Status",
    ACTION: "Action",
}
const dataKey = "data"
const totalKey = "total"
const NOT_RECEIVED_QTY = 0

const validateBackOrderAction = data => {
    if (data?.quantityReceived != NOT_RECEIVED_QTY) {
        return ""
    } else {
        return "disable-event"
    }
}

const validateCancelItemAction = (data, po, currentUser) => {
    if (
        currentUser == UserRoles.Finance &&
        data?.quantityReceived == NOT_RECEIVED_QTY &&
        PurchaseOrderStatus.CANCELLED != data?.status &&
        po.requestStatus != OrderManagementStatus.COMPLETED
    ) {
        return ""
    } else {
        return "disable-event"
    }
}

const PlacedOrderDetailView = ({ selectedPO, title, modalCloseHandler }) => {
    const userType = useSelector(state => state?.userAuth?.user?.type)

    const [itemCancellationConfirmModal, setItemCancellationConfirmModal] =
        useState(false)
    const [statusFilters, setStatusFilters] = useState(null)
    const [modalToggle, setModalToggle] = useState(false)
    const [selectedPOItem, setSelectedPOItem] = useState({})
    const [isLoading, setIsLoading] = useState(false)

    const {
        data,
        page,
        pageSize,
        isFetching,
        searchText,
        total,
        handleSearchChange,
        handlePageClick,
        onChangePageSize,
        request,
    } = useLocalPaginatedRequest({
        requestFn: GetPurchaseOrdersItems,
        params: {
            ...(statusFilters?.status && {
                status: statusFilters?.status,
            }),
        },
        deps: [statusFilters],
        dataKey: dataKey,
        totalKey: totalKey,
        id: selectedPO.id,
    })

    const handleBackOrderView = obj => {
        setModalToggle(true)
        setSelectedPOItem(obj)
    }

    const handleModalClose = () => {
        setModalToggle(false)
        setItemCancellationConfirmModal(false)
        setSelectedPOItem({})
    }

    const handleInitItemCancellation = data => {
        setItemCancellationConfirmModal(true)
        setSelectedPOItem(data)
    }

    const handleItemCancellation = async () => {
        try {
            setIsLoading(true)
            let res = await CancelPOItem(selectedPOItem.id, _)
            toast.success("Request item has been cancelled !")
            handleModalClose()
            request()
        } catch (err) {
            // toast.error(err.message)
        } finally {
            setIsLoading(false)
        }
    }
    const handleSelectOptionChange = val => {
        setStatusFilters({ status: val.value })
    }

    return (
        <>
            <h3 className="text-left fm-req-popup-heading mb-0 px-5 mb-5">
                {title}
            </h3>
            <div className="pt-3 px-5">
                <Row className="mb-2">
                    <Col sm="12" xs="12" md="12" lg="12" className="mb-3">
                        <div>
                            <p className="place-order-top-date my-auto mb-2">
                                Order No. : <span>{selectedPO?.id}</span>
                            </p>
                            <p className="place-order-top-date my-auto mb-2">
                                PO No. : <span>{selectedPO?.poNo}</span>
                            </p>
                        </div>

                        <p className="place-order-top-date my-auto">
                            Date of Order Placed:{" "}
                            <span>
                                {convertDateTime({
                                    date: selectedPO.createdAt,
                                    customFormat:
                                        dateTimeFormat.casitaCustomDateFormat,
                                    dateOnly: true,
                                })}
                            </span>
                        </p>
                    </Col>

                    <Row>
                        <Col>
                            <div className="d-flex gap-2 flex-wrap justify-content-end">
                                <div className="status-search-con">
                                    <AutoComplete
                                        isSearchable={false}
                                        placeholder="Select Status"
                                        customStyles={customStyles.WarehouseIM}
                                        classNamePrefix="fm-status-header-search"
                                        options={convertToSelectOptions(
                                            PurchaseOrderStatus
                                        )}
                                        onChange={handleSelectOptionChange}
                                    />
                                </div>
                                <div className="fm-search-input finance-byorders-search-ip">
                                    <SearchBox
                                        searchText={searchText}
                                        onChange={handleSearchChange}
                                        inputClasses="table-main-search-input fm-table-input"
                                        labelClass="d-none"
                                        placeholder="Search"
                                        searchIcon={true}
                                        searchIconClass="fm-table-search-icon"
                                        conClass="fm-table-search-con"
                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Col
                        sm="12"
                        xs="12"
                        md="12"
                        lg="12"
                        className="px-0 mb-5 mt-3"
                    >
                        <DataTable
                            data={data}
                            loading={isFetching}
                            className=" mt-3 fm-place-order-table place-order-data-table finance-vertical-scroll-om po-data-table-finance"
                            tableClasses="finance-place-order-modal-table"
                            config={[
                                {
                                    title: COLUMNS.SKU,
                                    className: "table-column-bold",
                                    render: data => data?.skuNo,
                                },
                                {
                                    title: COLUMNS.ITEM_NAME,
                                    render: data => data?.itemName,
                                },
                                {
                                    title: COLUMNS.SUBSTITUTE_NAME,
                                    render: data =>
                                        data?.substitute?.itemName || "-",
                                },
                                {
                                    title: COLUMNS.ORDERED_QUANTITY,
                                    render: data =>
                                        data?.warehouseRequestItemQuantity ||
                                        "-",
                                },
                                {
                                    title: COLUMNS.SUPPLIED_QTY,

                                    render: data => data?.quantity,
                                },
                                {
                                    title: COLUMNS.RECEIVED_QUANTITY,
                                    render: data => (
                                        <span>
                                            {Math.abs(data.quantityReceived)}
                                        </span>
                                    ),
                                },
                                {
                                    title: COLUMNS.REMAINING_QTY,

                                    render: data =>
                                        data?.quantityRemaining || "-",
                                },

                                {
                                    title: COLUMNS.UNIT_PRICE,
                                    className: "table-column-bold",
                                    render: data => {
                                        return (
                                            <div>
                                                $
                                                {Number(data?.price)?.toFixed(
                                                    2
                                                )}
                                            </div>
                                        )
                                    },
                                },

                                {
                                    title: COLUMNS.DATE_REQUESTED,
                                    render: data => {
                                        return convertDateTime({
                                            date: data.createdAt,
                                            customFormat:
                                                dateTimeFormat.casitaCustomDateFormat,
                                            dateOnly: true,
                                        })
                                    },
                                },

                                {
                                    title: COLUMNS.STATUS,
                                    render: data => {
                                        return (
                                            <div
                                                className={`place-order-status ${PurchaseOrderStatus.getLabelClass(
                                                    data?.status
                                                )}`}
                                            >
                                                {PurchaseOrderStatus.getDisplayTextKey(
                                                    data?.status
                                                )}
                                            </div>
                                        )
                                    },
                                },
                                {
                                    title: COLUMNS.ACTION,
                                    render: data => (
                                        <div className="d-flex justify-content-center align-items-center pointer">
                                            <img
                                                className={`p-0 m-0 ${validateBackOrderAction(
                                                    data
                                                )}`}
                                                src={backorderIcon}
                                                onClick={() =>
                                                    handleBackOrderView(data)
                                                }
                                            />

                                            <i
                                                onClick={() =>
                                                    handleInitItemCancellation(
                                                        data
                                                    )
                                                }
                                                className={`bx bx-sm bx-no-entry p-0 m-0 ${validateCancelItemAction(
                                                    data,
                                                    selectedPO,
                                                    userType
                                                )}`}
                                                style={{
                                                    color: "#950000",
                                                }}
                                            ></i>
                                        </div>
                                    ),
                                },
                            ]}
                        />
                    </Col>
                </Row>
            </div>

            <div className="text-center my-4 d-flex justify-content-center align-items-center">
                <Button
                    size="lg"
                    className="gt-btn-grad-primary btn-height me-2 d-flex justify-content-center align-items-center place-order-btn"
                    title={"Done"}
                    onClick={modalCloseHandler}
                />
            </div>

            <Modal
                isOpen={modalToggle}
                handleModalToggling={handleModalClose}
                bodyClassName="p-0"
                customButton={true}
                hideModalHeaderSeparator={true}
                headerClasses="pb-0"
                className="inv-req-popup-viewdetails"
                size="lg"
            >
                <POItemsBackOrder
                    selectedPO={selectedPO}
                    requestItemId={selectedPOItem?.warehouseRequestItemId}
                    modalCloseHandler={handleModalClose}
                    footerBtnType={"Done"}
                />
            </Modal>
            <Modal
                isOpen={itemCancellationConfirmModal}
                handleModalToggling={handleModalClose}
                bodyClassName="p-0"
                customButton={true}
                hideModalHeaderSeparator={true}
                headerClasses="pb-0"
                className="inv-req-popup-viewdetails"
                size="4"
            >
                <CancelPOItemRequest
                    loading={isLoading}
                    onCancelClick={handleModalClose}
                    onYesClick={handleItemCancellation}
                />
            </Modal>
        </>
    )
}

export default PlacedOrderDetailView
