import { injectBaseConstantMethods } from "./BaseConstants"

const ConfiguratorPackagesStatus = {
    Active: "ACTIVE",
    Inactive: "INACTIVE",
}

const displayTextKeys = {
    [ConfiguratorPackagesStatus.Active]: "Active",
    [ConfiguratorPackagesStatus.Inactive]: "Inactive",
}

const labelClass = {}

export default injectBaseConstantMethods(
    ConfiguratorPackagesStatus,
    displayTextKeys,
    labelClass
)
