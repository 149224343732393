import React, { useEffect, useState } from "react"
import {
    Card as ReactStrapCard,
    CardBody,
    Label,
    FormGroup,
    Col,
    Form,
    Row,
} from "reactstrap"
import { useSelector } from "react-redux"
import { toast } from "react-hot-toast"

import { ToggleBtn } from "components/ToggleBtn"
import { TextInput } from "components/TextInput"
import { Button } from "components/Button"
import { GetMe, NotificationSetting, passwordSetting } from "api/api.service"
import { Loader } from "components/Loader"
import { useFormik } from "formik"
import { initialValues, generalSettings_validationSchema } from "./FormConfig"
import TimerConfigSettings from "./TimerConfigSettings"
import { UserRoles } from "../../constants"

const Settings = ({}) => {
    const userType = useSelector(state => state?.userAuth?.user?.type)
    const [notificationState, setNoticationState] = useState()
    const [loading, setLoading] = useState(false)
    const [buttonloading, setButtonLoading] = useState(false)
    const [notificationLoading, setNotificationLoading] = useState(false)

    const isSuperAdmin = userType == UserRoles.SuperAdmin

    const handleSetting = e => {}

    let getNoticationSetting = async () => {
        try {
            setLoading(true)
            let res = await GetMe()
            setNoticationState(res.notificationSetting)
        } catch (err) {
        } finally {
            setLoading(false)
        }
    }
    useEffect(() => {
        getNoticationSetting()
    }, [])

    let handleNotificationSetting = async () => {
        try {
            let body = {
                notificationSetting: !notificationState,
            }
            setNotificationLoading(true)
            let res = await NotificationSetting(body)
            setNoticationState(prev => !prev)
            toast.success("Notfication Setting has been changed !")
        } catch (err) {
            // toast.error(err.message)
        } finally {
            setNotificationLoading(false)
        }
    }

    let handlePasswordSetting = async data => {
        try {
            let payload = {
                CurrentPassword: data?.currentPassword,
                NewPassword: data?.newPassword,
            }

            setButtonLoading(true)
            let res = await passwordSetting(payload)
            updatedFieldValue()
            toast.success("Password changed Successfully !")
        } catch (err) {
            // toast.error(err.message)
        } finally {
            setButtonLoading(false)
        }
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: initialValues.GeneralSettings,
        validationSchema: generalSettings_validationSchema,
        onSubmit: values => {
            handlePasswordSetting(values)
        },
    })

    const updatedFieldValue = () => {
        formik.setFieldValue("CurrentPassword", "")
        formik.setFieldValue("NewPassword", "")
        formik.setFieldValue("confirmNewPassword", "")
    }

    return (
        <>
            <Row md={12}>
                <Col>
                    <div className="setting-header">
                        <h1>Settings</h1>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col sm={12} xs={12} md={12} lg={12} className="form-container">
                    <Form onSubmit={formik.handleSubmit}>
                        {/* {loading ? (
                        <div className="loader-handle">
                            <Loader />
                        </div>
                    ) : (
                        <> */}
                        {/*
                            
                            Notification Settings:

                            <FormGroup row>
                                <Label
                                    for="notifications"
                                    xs={6}
                                    sm={6}
                                    md={5}
                                    lg={4}
                                    className="label-Container pe-0 pe-md-3"
                                >
                                    Notifications :
                                </Label>
                                <Col className="ps-0">
                                    {notificationLoading ? (
                                        <Col
                                            xs={6}
                                            sm={6}
                                            md={1}
                                            className="mb-2"
                                        >
                                            <Loader />
                                        </Col>
                                    ) : (
                                        <Col
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            className="ps-0 ps-md-3"
                                        >
                                            <ToggleBtn
                                                checked={notificationState}
                                                onClick={
                                                    handleNotificationSetting
                                                }
                                                className="toggle-ip"
                                            />
                                        </Col>
                                    )}
                                </Col>
                            </FormGroup> */}
                        <div>
                            <FormGroup className=" user-validation-handler" row>
                                <Label
                                    for="old Password"
                                    xs={3}
                                    sm={6}
                                    md={5}
                                    lg={4}
                                    className="label-Container user-validation-handler pe-0 pe-md-3"
                                >
                                    Old Password :
                                </Label>
                                <Col
                                    sm={6}
                                    md={5}
                                    xs={9}
                                    className="ps-0 ps-md-3"
                                >
                                    <TextInput
                                        className="text-input"
                                        type="current Password"
                                        name="currentPassword"
                                        id="current Password"
                                        placeholder="Type Password"
                                        value={formik.values.currentPassword}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                    <div className="input-validation-container">
                                        {formik.touched.currentPassword &&
                                        formik.errors.currentPassword ? (
                                            <span className="error fs-10">
                                                {formik.errors.currentPassword}
                                            </span>
                                        ) : null}
                                    </div>
                                </Col>
                            </FormGroup>

                            <FormGroup className=" user-validation-handler" row>
                                <Label
                                    for="new password"
                                    xs={6}
                                    sm={6}
                                    md={5}
                                    lg={4}
                                    className="label-Container pe-0 pe-md-3"
                                >
                                    New Password :
                                </Label>
                                <Col
                                    xs={6}
                                    sm={6}
                                    md={5}
                                    className="ps-0 ps-md-3"
                                >
                                    <TextInput
                                        className="text-input"
                                        type="new password"
                                        name="newPassword"
                                        id="new password"
                                        placeholder="Type Password"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.newPassword}
                                    />
                                    <div className="input-validation-container">
                                        {formik.touched.newPassword &&
                                        formik.errors.newPassword ? (
                                            <span className="error fs-10">
                                                {formik.errors.NewPassword}
                                            </span>
                                        ) : null}
                                    </div>
                                </Col>
                            </FormGroup>

                            <FormGroup
                                className=" user-validation-handler "
                                row
                            >
                                <Label
                                    for="confirm new password"
                                    xs={6}
                                    sm={6}
                                    md={5}
                                    lg={4}
                                    className="label-Container pe-0 pe-md-3"
                                >
                                    Confirm New Password :
                                </Label>
                                <Col
                                    xs={6}
                                    sm={6}
                                    md={5}
                                    className="ps-0 ps-md-3"
                                >
                                    <TextInput
                                        className="text-input"
                                        type="confirm new password"
                                        name="confirmNewPassword"
                                        id="confirm new password"
                                        placeholder="Type Password"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.confirmNewPassword}
                                    />
                                    {formik.touched.confirmNewPassword &&
                                    formik.errors.confirmNewPassword ? (
                                        <span className="error fs-10">
                                            {formik.errors.confirmNewPassword}
                                        </span>
                                    ) : null}
                                </Col>
                            </FormGroup>
                        </div>
                        <div className="finance-setting-bottom-button-container">
                            <Button
                                isLoading={buttonloading}
                                type="submit"
                                size="lg"
                                className="gt-btn-grad-primary table-bottom-btn setting-finance-btn"
                                title={<div>Save Changes</div>}
                            />
                        </div>
                        {/* </> */}
                        {/* )} */}
                    </Form>
                </Col>
            </Row>
            {isSuperAdmin && <TimerConfigSettings />}
        </>
    )
}

export default Settings
