// import { GetProductSizes } from "api/api.service"

export const SET_SIZES = "SET_SIZES"

export const getSizes = () => (dispatch, getState) => {
    return null

    GetProductSizes()
        .then(res => {
            dispatch({ type: SET_SIZES, payload: res })
            return Promise.resolve(res)
        })
        .catch(err => {
            return Promise.reject(err)
        })
}
