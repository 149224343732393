import React, { useEffect } from "react"
import { Switch, Route, Redirect } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"

import { Loader } from "../../components/Loader"
import { Page500 } from "../../components/Utility"
import { getMe } from "../../store/actions"
import { APP_ROUTES } from "helpers/routeHelpers"
import { protectedRoutes } from "../../routes"

const DefaultLayout = props => {
    const { Layout } = props
    const dispatch = useDispatch()
    const { userAuth, root } = useSelector(state => state)

    const routes = protectedRoutes.filter(obj =>
        obj.permissions.includes(userAuth?.user?.type)
    )

    useEffect(() => {
        if (userAuth.isAuthenticated) {
            if (!root?.tokenVerified) {
                dispatch(getMe()).catch(() => {})
            }
        }
    }, [dispatch])

    if (!userAuth.isAuthenticated) {
        return <Redirect to={APP_ROUTES.LOGIN} />
    }

    return root?.tokenVerified ? (
        <Switch>
            {routes.map((route, index) => {
                return (
                    <Route
                        key={index}
                        path={route.path}
                        exact={route.exact}
                        render={props => (
                            <Layout>
                                <route.component {...props} />
                            </Layout>
                        )}
                    />
                )
            })}
            <Redirect to={APP_ROUTES.DASHBOARD} />
        </Switch>
    ) : !root?.tokenVerified && root?.isLoading ? (
        <Loader classes="vh-100" />
    ) : (
        <Page500 />
    )
}

export default DefaultLayout
