import React from "react"
import {
   Button,
   Modal as ReactStrapModal,
   ModalHeader,
   ModalBody,
   ModalFooter,
} from "reactstrap"
import modalCloseIcon from "../../assets/images/inventory-request/modal-close-icon.png"
const Modal = props => {
   const {
      isOpen,
      className = "",
      sizeClasses = "",
      headerClasses = "",
      children,
      centered = true,
      size = "lg",
      bodyClassName = "",
      modalTitle = "",
      hideModalHeaderSeparator = true,
      handleModalToggling,
      customButton = false,
      styles = {},
      footerContent = null,
      isFullScreen = false,
      backdrop = "static",
      scrollable = false,
   } = props
   const handleModalClose = e => {
      handleModalToggling(!isOpen)
   }

   const closeBtn = (
      <button
         // className="custom-btn-close-popup px-0"
         className="custom-close-btn-popup px-0 position-absolute top-0 end-0 m-2"
         style={{ backgroundColor: "white" }}
         onClick={handleModalClose}
         type="button"
      >
         <img src={modalCloseIcon} alt="Close" />
      </button>
   )

   return (
      <div>
         <ReactStrapModal
            style={{ ...styles }}
            scrollable={scrollable}
            backdrop={backdrop}
            fullscreen={isFullScreen}
            fade
            size={size}
            isOpen={isOpen}
            // className={`${sizeClasses}`}
            className={`gt-modal gt-modal-size-${sizeClasses} ${className}`}
            centered={centered}
            toggle={handleModalClose}
            // modalTransition={{ timeout: 0 }}
            // backdropTransition={{ timeout: 0 }}
         >
            <ModalHeader
               toggle={handleModalClose}
               className={headerClasses}
               close={customButton ? closeBtn : null}
               style={{
                  ...(hideModalHeaderSeparator && {
                     borderBottom: "none",
                  }),
               }}
            >
               {modalTitle}
            </ModalHeader>
            <ModalBody className={`${bodyClassName}`}>{children}</ModalBody>
            {footerContent && <ModalFooter>{footerContent}</ModalFooter>}
         </ReactStrapModal>
      </div>
   )
}
export default Modal
