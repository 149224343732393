const AddNewItemSelectStyling = {
    container: provided => ({
        display: "inline-block",
        position: "relative",
        width: "100%",
        background: "#FFFFFF",
        border: "1px solid #CED4DA",
        borderRadius: "4px",
        ...provided,
    }),
    control: (provided, state) => ({
        ...provided,
        minHeight: "45px",
        borderColor: "#9d9d9d",
        border: state.isFocused ? 0 : 0,
        boxShadow: state.isFocused ? 0 : 0,
        "&:hover": {
            borderColor: "#9d9d9d",
        },
    }),
    option: styles => {
        return {
            ...styles,
        }
    },
    menuPortal: base => ({ ...base, zIndex: 9999 }),
}
export default AddNewItemSelectStyling
