import React, { useState } from "react"
import { Card as ReactStrapCard, Col, Row } from "reactstrap"
import { toast } from "react-hot-toast"

import { DataTable } from "components/DataTable"
import { useLocalPaginatedRequest } from "hooks/useLocalPaginatedRequest"
import VendorHeader from "./VendorHeader"
import VendorMangEditIcon from "../../assets/images/icons/edit-icon.svg"
import { Pagination } from "components/Pagination"
import { Modal } from "components/Modal"
import DeleteVendor from "./modals/DeleteVendor"
import AddEditVendor from "./modals/AddEditVendor"
import {
    CreateVendor,
    EditVendor,
    VendorListing,
    DeleteVendor as DeleteVendorService,
} from "api/api.service"
import ActionEyeIcon from "assets/images/icons/feather-eye-icon.png"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import { APP_ROUTES, getRoute } from "helpers/routeHelpers"
import DeleteIcon from "assets/images/icons/finance-vendor-mang-del-icon.svg"

const COLUMNS = {
    S_NO: "S.No",
    VENDOR_NAME: "Vendor Name",
    CONTACT_NUMBER: "Contact Number",
    ASSOCIATED_ITEM: "Associated Item",
    ACTIONS: "Actions",
}

const DATA_KEY = "data"
const TOTAL_KEY = "total"

const MODAL_ACTIONS = {
    ADD: "1",
    EDIT: "2",
    DELETE: "3",
}

const VendorManagementListing = ({}) => {
    const history = useHistory()
    const [filters, setFilters] = useState("")
    const [loading, setLoading] = useState(false)
    const [editState, setEditState] = useState("")
    const {
        data,
        isFetching,
        page,
        searchText,
        pageSize,
        total,
        handleSearchChange,
        request,
        handlePageClick,
        onChangePageSize,
    } = useLocalPaginatedRequest({
        requestFn: VendorListing,
        params: {
            ...(filters?.Status && { Status: filters?.Status }),
        },
        deps: [filters],
        dataKey: DATA_KEY,
        totalKey: TOTAL_KEY,
    })

    const [currentActionKey, setCurrentActionKey] = useState(null)
    const [actionStates, setActionStates] = useState({
        isAddVendor: null,
        isEditVendor: null,
        isDeleteVendor: null,
    })

    const handleAddVendor = () => {
        setCurrentActionKey(MODAL_ACTIONS.ADD)
        setActionStates(prev => ({ ...prev, isAddVendor: true }))
    }

    const handleEditAddVendor = data => {
        setCurrentActionKey(MODAL_ACTIONS.EDIT)
        setActionStates(prev => ({ ...prev, isEditVendor: true }))
    }

    const handleDeleteVendor = data => {
        setCurrentActionKey(MODAL_ACTIONS.DELETE)
        setActionStates(prev => ({ ...prev, isDeleteVendor: true }))
    }

    const handleCloseModal = () => {
        setActionStates({
            isAddVendor: null,
            isEditVendor: null,
            isDeleteVendor: null,
        })
        setCurrentActionKey(null)
    }

    let currentAction = {
        [MODAL_ACTIONS.ADD]: {
            title: "Add Vendor",
            btnText: "Add Vendor",
        },
        [MODAL_ACTIONS.EDIT]: {
            title: "Edit Vendor",
            btnText: "Edit Vendor",
        },
        [MODAL_ACTIONS.DELETE]: {
            title: "Delete Vendor",
            btnText: "Delete Vendor",
        },
    }

    let handleSubmit = data => {
        let payload = {
            vendorName: data?.fullName,
            contactNo: data?.contactNumber,
        }
        if (actionStates.isAddVendor) {
            handleAdd(payload)
        } else if (actionStates.isEditVendor) {
            handleEdit(payload)
        } else if (actionStates.isDeleteVendor) {
            handleDelete()
        }
    }

    let handleAdd = async (payload = {}) => {
        try {
            setLoading(true)
            let res = await CreateVendor(payload)
            toast.success("Vendor has been added !")
            request()
            handleCloseModal()
            setCurrentActionKey(null)
        } catch (err) {
            // toast.error(err.message)
        } finally {
            setLoading(false)
        }
    }

    let handleEdit = async (payload = {}) => {
        try {
            setLoading(true)
            let res = await EditVendor(payload, editState?.id)
            toast.success("Vendor has been Edited !")
            request()
            handleCloseModal()
            setCurrentActionKey(null)
        } catch (err) {
            // toast.error(err.message)
        } finally {
            setLoading(false)
        }
    }

    let handleDelete = async (payload = {}) => {
        try {
            setLoading(true)
            let res = await DeleteVendorService(editState.id)
            toast.success("Vendor has been deleted !")
            request()
            handleCloseModal()
            setCurrentActionKey(null)
        } catch (err) {
            // toast.error(err.message)
        } finally {
            setLoading(false)
        }
    }

    const vendorEditHandler = data => {
        setEditState(data)
        handleEditAddVendor()
    }

    const handleNavigateToVendorDetails = vendorObj =>
        history.push(
            getRoute(APP_ROUTES.VENDOR_MANAGEMENT_DETAILS, {
                vendorId: vendorObj.id,
            })
        )

    const vendorDeleteHandler = data => {
        setEditState(data)
        handleDeleteVendor()
    }

    return (
        <>
            <Row>
                <Col className="col-12">
                    <VendorHeader
                        headerTitle="Vendor Management"
                        onClick={handleAddVendor}
                        btnTitle="Add Vendor"
                        headerButtonResponsive="headerButtonResponsive"
                        headerSearchResponsiveness="header-search-responsiveness"
                        onChangeHandler={handleSearchChange}
                        searchText={searchText}
                        adFinanceSearchBoxContanier="ad-finance-search-box-contanier"
                        adFinanceVendorContanier="ad-finance-vendor-contanier"
                        VendorbuttonSearchResponsiveness="vendor-button-search-responsiveness"
                    />
                </Col>

                <Col md={12}>
                    <DataTable
                        data={data}
                        tableClasses="vendor-management-table"
                        loading={isFetching}
                        className="vendor-data-container mt-3"
                        config={[
                            {
                                title: COLUMNS.VENDOR_NAME,
                                className: "",
                                render: data => data?.vendorName || "-",
                            },
                            {
                                title: COLUMNS.CONTACT_NUMBER,
                                className: "",
                                render: data => data?.contactNo || "-",
                            },

                            {
                                title: COLUMNS.ASSOCIATED_ITEM,
                                className: "",
                                render: data => data?.itemsCount,
                            },
                            {
                                title: COLUMNS.ACTIONS,

                                render: data => (
                                    <div className="d-flex gap-2 pointer">
                                        <img
                                            src={ActionEyeIcon}
                                            onClick={() =>
                                                handleNavigateToVendorDetails(
                                                    data
                                                )
                                            }
                                        />

                                        <img
                                            src={VendorMangEditIcon}
                                            onClick={() =>
                                                vendorEditHandler(data)
                                            }
                                        />

                                        <img
                                            src={DeleteIcon}
                                            onClick={() =>
                                                vendorDeleteHandler(data)
                                            }
                                        />
                                    </div>
                                ),
                            },
                        ]}
                    />
                </Col>
                <Pagination
                    currentPage={page}
                    totalData={total}
                    onPageClick={handlePageClick}
                    // className="pagination-padding"
                />
            </Row>

            <Modal
                isOpen={!!currentAction[currentActionKey]}
                handleModalToggling={handleCloseModal}
                bodyClassName="p-0"
                customButton={true}
                hideModalHeaderSeparator={true}
                headerClasses="header-container"
                size="md"
                backdrop="static"
                modalTitle={currentAction[currentActionKey]?.title}
            >
                {(actionStates.isAddVendor || actionStates.isEditVendor) && (
                    <AddEditVendor
                        isAddVendor={actionStates.isAddVendor}
                        isEditVendor={actionStates.isEditVendor}
                        vendorName={editState?.vendorName}
                        contactNumber={editState?.contactNo}
                        btnText={currentAction[currentActionKey]?.btnText}
                        onSubmitForm={handleSubmit}
                        loading={loading}
                    />
                )}
                {actionStates.isDeleteVendor && (
                    <DeleteVendor
                        onDeleteClick={handleSubmit}
                        onCancelClick={handleCloseModal}
                        isLoading={loading}
                    />
                )}
            </Modal>
        </>
    )
}

export default VendorManagementListing
