import React from "react"
import ManageTrailerManagement from "./ManageTrailerManagement"
import { useParams } from "react-router-dom/cjs/react-router-dom.min"

const TrailerManagementDetails = () => {
   const { trailerId } = useParams()

   return <ManageTrailerManagement isDetailView id={trailerId} />
}

export default TrailerManagementDetails
