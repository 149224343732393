import React, { useState } from "react"
import { Form } from "reactstrap"
import { toast } from "react-hot-toast"
//redux
import { useSelector, useDispatch } from "react-redux"

import { withRouter, Link, useHistory, Redirect } from "react-router-dom"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"
import { TextInput } from "../../components/TextInput"
import { Button } from "../../components/Button"
import { Alert } from "../../components/Alert"
import { APP_ROUTES, getRoute } from "../../helpers/routeHelpers"

// import images
import profile from "../../assets/images/profile-img.png"
import logo from "../../assets/images/logo-circle.png"
import { withAuthentication } from "hoc"
import { ForgotPassword } from "api/api.service"

const ForgetPassword = props => {
    const history = useHistory()
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            email: "",
        },
        validationSchema: Yup.object({
            email: Yup.string()
                .trim()
                .email("Please enter a correct email address")
                .min(
                    6,
                    "Please enter an email address between 6 and 256 characters"
                )
                .max(
                    256,
                    "Please enter an email address between 6 and 256 characters"
                )
                .required("Please enter your email address"),
        }),
        onSubmit: async values => {
            const { email } = values
            try {
                setLoading(true)
                const res = await ForgotPassword({ email: email })
                toast.success(
                    "Password has been sent to your email !  Kindly check your email."
                )
                setTimeout(() => history.push(APP_ROUTES.LOGIN), 1000)
                // res?.TokenUUID &&
                //   history.push({
                //     pathname: getRoute(APP_ROUTES.FORGET_VERIFY_CODE),
                //     search: new URLSearchParams({ token: res.TokenUUID }).toString(),
                //     state: { email },
                //   })
            } catch (err) {
                // toast.error(err.message)
            } finally {
                setLoading(false)
            }
        },
    })

    return (
        <>
            <div className="wrap-login100 p-md-4 p-sm-2">
                <Form
                    className="form-classed"
                    onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                    }}
                >
                    <h1 className="mb-4">Forgot your password?</h1>
                    <div className="mb-3 right-side-container">
                        <TextInput
                            labelClass="label"
                            inputClass="signin-input"
                            label="Email"
                            name="email"
                            placeholder="Enter email"
                            type="email"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email || ""}
                            invalid={
                                validation.touched.email &&
                                !!validation.errors.email
                            }
                            valid={
                                validation.touched.email &&
                                !validation.errors.email
                            }
                        />
                        {validation.touched.email &&
                            !!validation.errors.email && (
                                <div className="form-input-error">
                                    {validation.errors.email}
                                </div>
                            )}
                    </div>

                    <div className="mt-3 sign-in-btn">
                        <Button
                            size="sm"
                            type="submit"
                            title="Send"
                            className="gt-btn-grad-primary"
                            isLoading={loading}
                        />
                    </div>

                    <div className="mt-3 admin-fp-typo clr-gray-dark fs-20">
                        Remember It? {""}
                        <Link to={APP_ROUTES.LOGIN} className="clr-gray-dark">
                            Sign In here.
                        </Link>
                    </div>
                </Form>
            </div>
        </>
    )
}

export default withAuthentication(withRouter(ForgetPassword))
