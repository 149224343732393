import React from "react"

import { Button } from "components/Button"
import { SearchBox } from "components/SearchBox"

const VendorHeader = ({
    headerTitle,
    subTitle,
    onClick = {},
    btnTitle = "ADD",
    headerButtonResponsive = "",
    headerSearchResponsiveness = "",
    searchText = "",
    adFinanceSearchBoxContanier = "",
    adFinanceVendorContanier = "",
    VendorbuttonSearchResponsiveness = "",
    onChangeHandler = () => {},
}) => {
    return (
        <>
            <div className="d-flex flex-column flex-lg-row justify-content-between align-items-lg-center align-items-start header-margin-container">
                <div>
                    <h1 className="header-title mb-0">{headerTitle}</h1>
                    <span>{subTitle}</span>
                </div>
                <div className="mt-2 mt-lg-0 vm-topheader-right">
                    {typeof onClick == "function" && (
                        <Button
                            title={btnTitle}
                            className={`header-button ${headerButtonResponsive}`}
                            onClick={onClick}
                        />
                    )}
                    <div
                        className={`search-box-container ${adFinanceSearchBoxContanier}`}
                    >
                        <SearchBox
                            inputClasses="vendor-search-input input-search vendor-search-input-only"
                            labelClass="d-none"
                            placeholder="Search"
                            searchIcon={true}
                            searchIconClass="vendor-table-search-icon"
                            conClass={`vendor-table-search-inner-class ${headerSearchResponsiveness}`}
                            searchText={searchText}
                            onChange={onChangeHandler}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default VendorHeader
