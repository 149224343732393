import React from "react"
import { Table } from "reactstrap"
import { TextInput } from "components/TextInput"
import { Loader } from "components/Loader"
import TableHead from "./TableHead"

const DataTable = props => {
    const {
        config,
        data,
        onRowClick,
        loading = false,
        className = "",
        tableClasses = "",
        onScrollHandler = () => {},
        handleResponsiveForSelectAll = "",
        tdClass = "",
        thClass = "",
        selectAll = false,
        selectAllClickHandler = () => {},
        isSelectAll = false,
        currentSortOrder,
        currentSortColumn,
        onSortClick,
    } = props

    const isRowClickable = () => {
        return onRowClick && typeof onRowClick === "function"
    }

    const handleRowClick = (e, dataObj) => {
        if (isRowClickable()) {
            onRowClick(e, dataObj)
        }
    }

    return (
        <div
            className={`table-responsive gt-data-table ${className}`}
            onScroll={onScrollHandler}
        >
            <Table className={`mb-0 ${tableClasses}`}>
                <thead className="fm-bg-table-header">
                    <tr>
                        {config.map((item, index) => {
                            return (
                                <th
                                    className={`${item?.className} ${
                                        item?.thClass ? item.thClass : ""
                                    }`}
                                    key={index}
                                >
                                    {item && item.selectAll && (
                                        <div
                                            className={`form-check d-inline-block fm-table-selectall me-2 ${handleResponsiveForSelectAll}`}
                                        >
                                            <TextInput
                                                onClick={
                                                    item.selectAllClickHandler
                                                }
                                                labelClass="mb-0"
                                                type="checkbox"
                                                inputClass="form-check-input-custom fm-table-checkboxes"
                                                checked={item.isSelectAll}
                                                disabled={item.isDisabled}
                                            />
                                        </div>
                                    )}
                                    <TableHead key={index} options={item} />
                                </th>
                            )
                        })}
                    </tr>
                </thead>
                <tbody className="fm-bg-table-footer">
                    {data &&
                        !!data.length &&
                        data.map((dataObj, dataIndex) => {
                            return (
                                <tr
                                    key={dataIndex}
                                    onClick={e => handleRowClick(e, dataObj)}
                                    className={`${
                                        isRowClickable() ? "pointer" : ""
                                    }`}
                                >
                                    {config.map((item, index) => {
                                        return (
                                            <td
                                                className={`${
                                                    item?.className
                                                }  ${
                                                    item?.tdClass
                                                        ? item.tdClass
                                                        : ""
                                                }`}
                                                key={index}
                                            >
                                                {item?.render(
                                                    dataObj,
                                                    dataIndex
                                                )}
                                            </td>
                                        )
                                    })}
                                </tr>
                            )
                        })}
                </tbody>
            </Table>
            {data && !!data.length && loading && <Loader />}
            {(!data || !data.length) && (
                <div className="d-flex justify-content-center align-items-center py-5">
                    {loading ? <Loader /> : <p>{"No Data Available"}</p>}
                </div>
            )}
        </div>
    )
}

export default DataTable
