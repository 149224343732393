import React, { useState } from "react"
import { CardBody, Card } from "reactstrap"
import { toast } from "react-hot-toast"

import { DataTable } from "components/DataTable"
import PencilIcon from "assets/images/warehouse/inventory-management/pencil-icon.png"
import DeleteIcon from "assets/images/inventory/delete-icon.png"
import { SearchBox } from "components/SearchBox"
import { Button } from "components/Button"
import { TableHeaderButtons } from "components/TableHeaderButtons"
import { PageLimit } from "components/PageLimit"
import { Pagination } from "components/Pagination"
import { Modal } from "components/Modal"
import { useLocalPaginatedRequest } from "hooks/useLocalPaginatedRequest"
import {
    AddItemCategory,
    ItemCategoriesListing,
    UpdateItemCategory,
} from "api/api.service"
import AddCategoryForm from "./AddCategoryForm"

const DATA_KEY = "data"
const TOTAL_KEY = "total"

const COLUMNS = {
    SERIAL_NO: "S. No.",
    CATEGORY: "Category",
    ACTION: "Actions",
}

const ItemCategoryListing = ({}) => {
    const [id, setId] = useState("")
    const [itemCategory, setItemCategory] = useState("")
    const [modalToggle, setModalToggle] = useState(false)
    const [isUpdate, setIsUpdate] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const {
        data,
        total,
        page,
        searchText,
        pageSize,
        isFetching,
        handleSearchChange,
        handlePageClick,
        onChangePageSize,
        request,
    } = useLocalPaginatedRequest({
        requestFn: ItemCategoriesListing,
        params: {},
        deps: [],
        dataKey: DATA_KEY,
        totalKey: TOTAL_KEY,
    })

    const headerButtonsData = [
        {
            title: "Add New Category",
            onClick: () => setModalToggle(!modalToggle),
            classesButton: "ms-0 ",
        },
    ]

    const handleCloseModal = () => {
        setModalToggle(!modalToggle)
        setItemCategory("")
        setId("")
        setIsUpdate(false)
    }

    const handleSetItemsCategory = evt => {
        let value = evt.target.value
        setItemCategory(value)
    }

    const handleEditItemCategory = data => {
        setItemCategory(data.name)
        setId(data.id)
        setModalToggle(!modalToggle)
        setIsUpdate(!isUpdate)
    }

    const handleSubmit = async () => {
        let payload = {
            name: itemCategory,
        }

        if (isUpdate) {
            await handleUpdateItemCategory(payload)
        } else {
            await handleAddItemCategory(payload)
        }
        request()
    }

    const handleAddItemCategory = async payload => {
        try {
            setIsLoading(true)
            let res = await AddItemCategory(payload)
            setModalToggle(!modalToggle)
            setItemCategory("")
            toast.success("Item category has been added !")
        } catch (err) {
            // toast.error(err.message)
        } finally {
            setIsLoading(false)
        }
    }

    const handleUpdateItemCategory = async payload => {
        try {
            setIsLoading(true)
            let res = await UpdateItemCategory(id, payload)
            setModalToggle(!modalToggle)
            setIsUpdate(!isUpdate)
            setItemCategory("")
            setId("")
            toast.success("Item category has been updated !")
        } catch (err) {
            // toast.error(err.message)
        } finally {
            setIsLoading(false)
        }
    }

    const handleOnClick = e => {}

    return (
        <>
            <Card className="mb-5">
                <CardBody className="p-2 p-md-4 wh-im-wh-gi-con">
                    <div className="mb-2 mb-md-3">
                        <div className="">
                            <div className="gi-finance-inner-header finance-item-listing-header">
                                <Button
                                    size="lg"
                                    title="Add Category"
                                    className="gt-btn-grad-primary add-inv-btn me-0 me-md-2 wh-im-dpgi-status ft-table-header"
                                    onClick={e => setModalToggle(!modalToggle)}
                                />
                                <div className="fm-location-search-box">
                                    <SearchBox
                                        inputClasses="vendor-search-input input-search gi-im-search-ip"
                                        labelClass="d-none"
                                        placeholder="Search"
                                        searchIcon={true}
                                        searchIconClass="vendor-table-search-icon"
                                        conClass="vendor-table-search-inner-class"
                                        searchText={searchText}
                                        onChange={handleSearchChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <DataTable
                        data={data}
                        loading={isFetching}
                        config={[
                            {
                                title: COLUMNS.SERIAL_NO,
                                render: data => {
                                    return <>{data?.id}</>
                                },
                            },
                            {
                                title: COLUMNS.CATEGORY,
                                render: data => {
                                    return data?.name
                                },
                            },

                            {
                                title: COLUMNS.ACTION,
                                render: data => (
                                    <>
                                        <span
                                            className="text-success pointer mx-1 mx-md-3"
                                            onClick={() =>
                                                handleEditItemCategory(data)
                                            }
                                        >
                                            <img src={PencilIcon} />
                                        </span>
                                        {/* 
                    <span className="text-success pointer">
                      <img src={DeleteIcon} />
                    </span> 
                    */}
                                    </>
                                ),
                            },
                        ]}
                    />
                    <Pagination
                        currentPage={page}
                        totalData={total}
                        onPageClick={handlePageClick}
                        rowLimit={pageSize}
                    />
                </CardBody>
            </Card>

            <Modal
                modalTitle={isUpdate ? "Edit Category" : "Add Category"}
                isOpen={modalToggle}
                handleModalToggling={handleCloseModal}
                bodyClassName="p-0"
                customButton={true}
                hideModalHeaderSeparator={true}
                headerClasses="pb-0"
                className="inv-req-popup-viewdetails"
                size="md"
            >
                <AddCategoryForm
                    onSubmit={handleSubmit}
                    onChange={handleSetItemsCategory}
                    value={itemCategory}
                    isLoading={isLoading}
                    isUpdate={isUpdate}
                />
            </Modal>
        </>
    )
}

export default ItemCategoryListing
