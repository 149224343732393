import { injectBaseConstantMethods } from "./BaseConstants"

const ConfiguratorBaseFeaturesCategoryStatus = {
    Active: "ACTIVE",
    Inactive: "INACTIVE",
}

const displayTextKeys = {
    [ConfiguratorBaseFeaturesCategoryStatus.Active]: "Active",
    [ConfiguratorBaseFeaturesCategoryStatus.Inactive]: "Inactive",
}

const labelClass = {}

export default injectBaseConstantMethods(
    ConfiguratorBaseFeaturesCategoryStatus,
    displayTextKeys,
    labelClass
)
