import { injectBaseConstantMethods } from "./BaseConstants"

const ConfiguratorCustomizationItemSelectionType = {
   Single: "SINGLE",
   Multiple: "MULTIPLE",
}

const displayTextKeys = {
   [ConfiguratorCustomizationItemSelectionType.Single]: "Single Select",
   [ConfiguratorCustomizationItemSelectionType.Multiple]: "Multi Select",
}

const labelClass = {}

export default injectBaseConstantMethods(
   ConfiguratorCustomizationItemSelectionType,
   displayTextKeys,
   labelClass
)
