import React from "react"

import "./DataTable.scss"
import { SortOrder } from "../../constants"
import FilterCell from "./FilterCell"

const TableHead = props => {
    const { options, currentSortOrder, currentSortColumn, onSortClick } = props

    const handleTableHeadClick = () => {
        if (
            options.isSortable &&
            onSortClick &&
            typeof onSortClick === "function"
        ) {
            onSortClick(options)
        }
    }

    const renderSortIcon = () => {
        if (!currentSortColumn || currentSortColumn !== options.sortColumn) {
            return <i className="bx bx-sort"></i>
        }
        return currentSortOrder === SortOrder.ASC ? (
            <i className="bx bx-sort-down"></i>
        ) : (
            <i className="bx bx-sort-up"></i>
        )
    }

    return (
        <>
            {!options.isFilterable ? (
                <div
                    className={` ${options.isSortable ? "" : ""}`}
                    onClick={handleTableHeadClick}
                >
                    <span className="">{options.title}</span>
                    <span className="">
                        {options.isSortable && renderSortIcon()}
                    </span>
                </div>
            ) : (
                <FilterCell options={options} />
            )}
        </>
    )
}

export default TableHead
