import { injectBaseConstantMethods } from "./BaseConstants"

const PlaceOrderStatus = {
    OPEN: "order placed",
    IN_PROGRESS: "order substitute",
    COMPLETED: "partially ordered",
    PENDING: "Pending", 
}

const displayTextKeys = {
  [PlaceOrderStatus.OPEN]: "All Ordered",
  [PlaceOrderStatus.IN_PROGRESS]: "Order Substitute",
  [PlaceOrderStatus.COMPLETED]: "Partially Ordered",
  [PlaceOrderStatus.PENDING]: "Pending",
}

const labelClass = {
  [PlaceOrderStatus.OPEN]: "fm-bg-light-green",
  [PlaceOrderStatus.IN_PROGRESS]: "fm-bg-light-blue",
  [PlaceOrderStatus.COMPLETED]: "fm-bg-light-yellow",
  [PlaceOrderStatus.PENDING]: "fm-bg-light-red",
}

export default injectBaseConstantMethods(
  PlaceOrderStatus,
  displayTextKeys,
  labelClass
)