import React, { useState } from "react"
import { Col, Row } from "reactstrap"
import { useHistory, useParams } from "react-router-dom"
import toast from "react-hot-toast"

import settingsIcon from "../../../assets/images/icons/settings-icon.svg"
import editIcon from "../../../assets/images/icons/edit-icon.svg"
import customStyles from "../../Common/AutoCompleteStyles"
import ActiveInactiveSwitch from "../CommonUI/ActiveInactiveSwitch/ActiveInactiveSwitch"
import { DataTable } from "../../../components/DataTable"
import { useLocalPaginatedRequest } from "../../../hooks/useLocalPaginatedRequest"
import { Pagination } from "../../../components/Pagination"
import {
    GetEquipmentCategoryDetails,
    GetEquipmentsCategoryListing,
    UpdateEquipmentCategory,
    CreateEquipmentCategory,
} from "../../../api/api.service"
import { APP_ROUTES, getRoute } from "../../../helpers/routeHelpers"
import { SearchBox } from "../../../components/SearchBox"
import { Button } from "../../../components/Button"
import { AutoComplete } from "../../../components/AutoComplete"
import { EquipmentCategoriesStatus } from "../../../constants"
import { convertToSelectOptions } from "../../../utils/commonUtils"
import { convertDateTime, dateTimeFormat } from "utils/dateFormatUtils"
import ManageSettingsCategory from "../CommonUI/ManageSettingsCategory/ManageSettingsCategory"

const COLUMNS = {
    CATEGORY: "Category",
    DATE_EDITED: "Date edited",
    NO_OF_ITEMS: "No. of items",
    STATUS: "Status",
    ACTIONS: "Actions",
}

const DATA_KEY = "data"
const TOTAL_KEY = "total"

const TITLES = {
    MODAL_ADD_TITLE: "Add category",
    MODAL_UPDATE_TITLE: "Update category",
    MODAL_DETAILS_TITLE: "Category details",
    ADD_BTN_TEXT: "Add category",
    UPDATE_BTN_TEXT: "Update category",
    DETAILS_BTN_TEXT: "Done",
}

const Equipment = ({}) => {
    const history = useHistory()
    const { trailerId } = useParams()
    const [statusFilters, setStatusFilters] = useState(null)
    const [selection, setSelection] = useState({})

    const {
        data,
        isFetching,
        page,
        searchText,
        pageSize,
        total,
        handleSearchChange,
        request,
        handlePageClick,
        onChangePageSize,
        setTableDataChangeLoading,
        tableDataChangeLoading,
        setData,
    } = useLocalPaginatedRequest({
        requestFn: GetEquipmentsCategoryListing,
        params: {
            trailerId: trailerId,
            ...(statusFilters?.value && { status: statusFilters?.value }),
        },
        deps: [statusFilters],
        dataKey: DATA_KEY,
        totalKey: TOTAL_KEY,
    })

    const [eventsType, setEventsType] = useState({
        isManage: null,
        isUpdate: null,
        isDetails: null,
    })

    const handleInitManage = obj => {
        setEventsType(prev => ({ ...prev, isManage: true }))
        setSelection(obj)
    }

    const handleNavigateToCategoryItems = obj => {
        setSelection(obj)

        return history.push(
            getRoute(APP_ROUTES.CONFIGURATOR_TRAILER_SETTINGS_EQUIPMENT_ITEMS, {
                trailerId: trailerId,
                categoryId: obj.id,
            })
        )
    }
    const handleSelectStatusOption = option => {
        setStatusFilters(option)
    }

    const handleStatusChange = async (currStatus, id) => {
        let status =
            currStatus === EquipmentCategoriesStatus.Active
                ? EquipmentCategoriesStatus.Inactive
                : EquipmentCategoriesStatus.Active
        let dataClone = [...data]
        dataClone.map(el => {
            if (el?.id == id) el.status = status
        })
        setData(dataClone)
        let payload = {
            status: status,
        }
        try {
            setTableDataChangeLoading(true)
            let res = await UpdateEquipmentCategory(id, payload)
            toast.success("Category status has been updated !")
        } catch (err) {
            toast.error(err.message)
            let dataClone = [...data]
            dataClone.map(el => {
                if (el?.id == id) el.status = currStatus
            })
            setData(dataClone)
        } finally {
            setTableDataChangeLoading(false)
        }
    }

    return (
        <>
            <div className="mb-3 trailer-container">
                <div className="d-flex align-items-center justify-content-between admin-inventory-management-general-inventory-responsiveness trailer-btn-container">
                    <div />

                    <Button
                        className={`header-button headerButtonResponsive`}
                        title="Add Category"
                        onClick={() => handleInitManage({})}
                    />
                </div>
            </div>
            {/* <Row className="py-2">
                <Col sm="12" md="6" lg="4"></Col>
                <Col sm="12" md="6" lg="4"></Col>

                <Col sm="12" md="6" lg="4" align="right">
                    <Button
                        className={`header-button`}
                        title="Add Category"
                        onClick={() => handleInitManage({})}
                    />
                </Col>
            </Row> */}
            {/* <Row className="py-2">
                <Col sm="12" md="6" lg="4">
                    <div className="min-250-w margin-top-handle">
                        <AutoComplete
                            value={statusFilters}
                            isClearable
                            onChange={val => handleSelectStatusOption(val)}
                            isSearchable={false}
                            placeholder="Select status"
                            customStyles={customStyles.AdminAppSelectStyles}
                            classNamePrefix="status-header-search-admin"
                            options={convertToSelectOptions(
                                EquipmentCategoriesStatus
                            )}
                        />
                    </div>
                </Col>
                <Col sm="12" md="6" lg="4"></Col>

                <Col sm="12" md="6" lg="4">
                    <SearchBox
                        inputClasses="custom-input-search custom-input-search-height"
                        labelClass="d-none"
                        placeholder="Search"
                        searchIcon={true}
                        searchIconClass="custom-input-search-icon"
                        conClass={`search-input-container-class`}
                        searchText={searchText}
                        onChange={handleSearchChange}
                    />
                </Col>
            </Row> */}

            <div className="mb-3">
                <div className="d-flex align-items-center justify-content-between admin-inventory-management-general-inventory-responsiveness ">
                    <div className="status-search-con wh-im-dpgi-status  me-2 ">
                        <AutoComplete
                            value={statusFilters}
                            isClearable
                            onChange={val => handleSelectStatusOption(val)}
                            isSearchable={false}
                            placeholder="Select status"
                            customStyles={customStyles.AdminAppSelectStyles}
                            classNamePrefix="status-header-search-admin"
                            options={convertToSelectOptions(
                                EquipmentCategoriesStatus
                            )}
                        />
                    </div>
                    <div className="ad-location-search-box">
                        <SearchBox
                            inputClasses="vendor-search-input input-search"
                            labelClass="d-none"
                            placeholder="Search"
                            searchIcon={true}
                            searchIconClass="custom-input-search-icon"
                            conClass={`search-input-container-class`}
                            searchText={searchText}
                            onChange={handleSearchChange}
                        />
                    </div>
                </div>
            </div>
            <Row>
                <Col md={12}>
                    <DataTable
                        data={data}
                        tableClasses="equipment-table"
                        loading={isFetching}
                        // className="vendor-data-container mt-3"
                        config={[
                            {
                                title: COLUMNS.CATEGORY,
                                className: "",
                                render: data => data.name || "-",
                            },
                            {
                                title: COLUMNS.DATE_EDITED,
                                className: "",
                                render: data => {
                                    return convertDateTime({
                                        date: data.updatedAt,
                                        customFormat:
                                            dateTimeFormat.casitaCustomDateFormat,
                                        dateOnly: true,
                                    })
                                },
                            },

                            {
                                title: COLUMNS.NO_OF_ITEMS,
                                className: "",
                                render: data => data?.totalItems,
                            },

                            {
                                title: COLUMNS.STATUS,
                                className: "",
                                render: data => (
                                    <ActiveInactiveSwitch
                                        isDisable={tableDataChangeLoading}
                                        id={data.id}
                                        name={`${data.id}`}
                                        onChange={e =>
                                            handleStatusChange(
                                                data?.status,
                                                data?.id
                                            )
                                        }
                                        isChecked={
                                            data?.status ==
                                            EquipmentCategoriesStatus.Active
                                        }
                                    />
                                ),
                            },

                            {
                                title: COLUMNS.ACTIONS,

                                render: data => (
                                    <div className="d-flex gap-2 pointer">
                                        <img
                                            src={editIcon}
                                            onClick={() =>
                                                handleInitManage(data)
                                            }
                                        />

                                        <img
                                            src={settingsIcon}
                                            onClick={() =>
                                                handleNavigateToCategoryItems(
                                                    data
                                                )
                                            }
                                        />
                                    </div>
                                ),
                            },
                        ]}
                    />
                </Col>
                <Pagination
                    currentPage={page}
                    totalData={total}
                    onPageClick={handlePageClick}
                />
            </Row>

            <ManageSettingsCategory
                TITLES={TITLES}
                subTitle="Equipment"
                addApiFunc={CreateEquipmentCategory}
                entityApiKey="name"
                entityName="Equipment Category"
                fetchApiFunc={GetEquipmentCategoryDetails}
                statusConstObj={EquipmentCategoriesStatus}
                title="Category"
                textFieldPlaceholder="Enter name"
                updateApiFun={UpdateEquipmentCategory}
                textFieldLabel="Name"
                isOpen={eventsType.isManage}
                id={selection?.id}
                onSuccess={request}
                payloadData={{
                    configuratorTrailerId: Number(trailerId),
                    ...(selection.id && {
                        configuratorBaseFeaturesId: selection.id,
                    }),
                }}
                onToggleModal={() => {
                    setSelection({})
                    setEventsType(prev => ({
                        ...prev,
                        isManage: false,
                    }))
                }}
            />
        </>
    )
}

export default Equipment
