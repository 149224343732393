import { injectBaseConstantMethods } from "./BaseConstants"

const EquipmentCategoriesStatus = {
    Active: "ACTIVE",
    Inactive: "INACTIVE",
}

const displayTextKeys = {
    [EquipmentCategoriesStatus.Active]: "Active",
    [EquipmentCategoriesStatus.Inactive]: "Inactive",
}

const labelClass = {}

export default injectBaseConstantMethods(
    EquipmentCategoriesStatus,
    displayTextKeys,
    labelClass
)
