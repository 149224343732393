import React, { useState } from "react"

import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap"
import Packages from "./Packages"

const SalesAndMarketing = () => {
   const [activeTab, setActiveTab] = useState("1")

   const toggle = index => {
      if (activeTab !== index) setActiveTab(index)
   }

   return (
      <>
         <Nav className="custom-tabs">
            <NavItem>
               <NavLink
                  className={activeTab === "1" ? "active" : ""}
                  onClick={() => {
                     toggle("1")
                  }}
               >
                  Packages
               </NavLink>
            </NavItem>
         </Nav>
         <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
               <Packages />
            </TabPane>
         </TabContent>
      </>
   )
}

export default SalesAndMarketing
