import React, { useEffect, useState } from "react"
import { CardBody, Col, Form, FormGroup, Label, Row } from "reactstrap"
import { useFormik } from "formik"

import { Button } from "components/Button"
import { TextInput } from "components/TextInput"
import { initialValues, validationSchema } from "./FormConfig"
import { AutoComplete } from "components/AutoComplete"
import { AutoCompleteStyling } from "pages/Common/AutoCompleteStyling"
import { Modal } from "components/Modal"
import { UserRoles } from "../../../../constants"

const USER_ROLE_OPTIONS = [
    {
        label: "Executive",
        value: UserRoles.Executive,
    },
    // {
    //     label: "Production Head",
    //     value: UserRoles.Production,
    // },
    {
        label: "Station Manager",
        value: UserRoles.Department,
    },
    {
        label: "Finance Manager",
        value: UserRoles.Finance,
    },
    {
        label: "Warehouse Manager",
        value: UserRoles.Warehouse,
    },
]

const AddEditUser = ({
    attachedUsers = [],
    firstName = "",
    lastName = "",
    emailAddress = "",
    role = "",
    btnText = "",
    isAddUser = false,
    isEditUser = false,
    loading = false,
    onSubmitForm = () => {},
}) => {
    console.log("🚀 ~ attachedUsers:", attachedUsers)
    const [selectedRole, setSelectedRole] = useState("")
    const [selectedRoleTemp, setSelectedRoleTemp] = useState("")
    const [validationModalToggle, setValidationModalToggle] = useState(false)

    useEffect(() => {
        let obj = USER_ROLE_OPTIONS.find(item => item.value == role)
        if (isEditUser) {
            formik.setFieldValue("firstName", firstName)
            formik.setFieldValue("lastName", lastName)
            formik.setFieldValue("emailAddress", emailAddress)
            formik.setFieldValue("role", role)
            setSelectedRole({
                ...obj,
            })
        }

        return () => {
            formik.setFieldValue("firstName", "")
            formik.setFieldValue("lastName", "")
            formik.setFieldValue("emailAddress", "")
            formik.setFieldValue("role", "")
        }
    }, [])

    const formik = useFormik({
        enableReinitialize: true,
        initialValues,
        validationSchema,
        onSubmit: values => {
            handleAddEditUserSubmit(values)
        },
    })

    const handleSelectRoleInit = option => {
        setSelectedRoleTemp(option)
        if (attachedUsers.length && option.value != role) {
            setValidationModalToggle(true)
        } else {
            formik.setFieldValue("role", option.value)
            setSelectedRole(option)
        }
    }

    const handleSelectRole = () => {
        formik.setFieldValue("role", selectedRoleTemp.value)
        setSelectedRole(selectedRoleTemp)
    }

    const handleContinueChangeRole = () => {
        handleSelectRole()
        setValidationModalToggle(false)
    }

    const handleAddEditUserSubmit = values => {
        onSubmitForm(values)
    }

    return (
        <>
            <Form onSubmit={formik.handleSubmit}>
                <CardBody className="user-add-edit-card-container shadow-none">
                    <FormGroup className="d-flex user-validation-handler">
                        <Label
                            for="first name"
                            className="user-mang-modal-label "
                            sm={4}
                        >
                            First Name
                        </Label>
                        <Col sm={7} className="input-text-container ">
                            <TextInput
                                className="user-mang-modal-input "
                                type="first name"
                                name="firstName"
                                id="first name"
                                placeholder="Type first name"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.firstName}
                            />
                        </Col>
                    </FormGroup>

                    <Row className="mb-3">
                        <Col sm={4}></Col>
                        <Col sm={7}>
                            {formik.touched.firstName &&
                            formik.errors.firstName ? (
                                <span className="error fs-10">
                                    {formik.errors.firstName}
                                </span>
                            ) : null}
                        </Col>
                    </Row>

                    <FormGroup className="d-flex user-validation-handler">
                        <Label
                            for="last name"
                            className="user-mang-modal-label "
                            sm={4}
                        >
                            Last Name
                        </Label>
                        <Col sm={7} className="input-text-container ">
                            <TextInput
                                className="user-mang-modal-input "
                                type="last name"
                                name="lastName"
                                id="last name"
                                placeholder="Type Last Name"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.lastName}
                            />
                        </Col>
                    </FormGroup>

                    <Row className="mb-3">
                        <Col sm={4}></Col>
                        <Col sm={7}>
                            {formik.touched.lastName &&
                            formik.errors.lastName ? (
                                <span className="error fs-10">
                                    {formik.errors.lastName}
                                </span>
                            ) : null}
                        </Col>
                    </Row>
                    <FormGroup className="d-flex user-validation-handler">
                        <Label
                            for="email address"
                            className="user-mang-modal-label"
                            sm={4}
                        >
                            Email Address
                        </Label>
                        <Col sm={7} className="input-text-container ">
                            <TextInput
                                className="user-mang-modal-input "
                                type="email address"
                                name="emailAddress"
                                id="email address"
                                placeholder="Type Email Address"
                                invalid={
                                    formik.touched.confirmPassword &&
                                    !!formik.errors.confirmPassword
                                }
                                valid={
                                    formik.touched.confirmPassword &&
                                    !formik.errors.confirmPassword
                                }
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.emailAddress}
                                disabled={isEditUser}
                            />
                        </Col>
                    </FormGroup>

                    <Row className="mb-3">
                        <Col sm={4}></Col>
                        <Col sm={7}>
                            {formik.touched.emailAddress &&
                            formik.errors.emailAddress ? (
                                <span className="error fs-10">
                                    {formik.errors.emailAddress}
                                </span>
                            ) : null}
                        </Col>
                    </Row>

                    <FormGroup className="d-flex user-validation-handler">
                        <Label
                            for="role"
                            className="user-mang-modal-label"
                            sm={4}
                        >
                            Role
                        </Label>
                        <Col sm={7} className="input-text-container ">
                            <AutoComplete
                                placeholder="Select Role"
                                classNamePrefix="add-new-req"
                                customStyles={AutoCompleteStyling}
                                options={USER_ROLE_OPTIONS}
                                onChange={handleSelectRoleInit}
                                value={selectedRole}
                                isSearchable={false}
                                // isDisabled={isEditUser}
                            />
                        </Col>
                    </FormGroup>

                    <Row className="mb-3">
                        <Col sm={4}></Col>
                        <Col sm={7}>
                            {formik.touched.role && formik.errors.role ? (
                                <span className="error fs-10">
                                    {formik.errors.role}
                                </span>
                            ) : null}
                        </Col>
                    </Row>

                    <Button
                        type="submit"
                        title={btnText}
                        className="gt-btn-grad-primary mt-5 button-width-container"
                        isLoading={loading}
                    />
                </CardBody>
            </Form>

            <Modal
                isOpen={validationModalToggle}
                handleModalToggling={() =>
                    setValidationModalToggle(prev => !prev)
                }
                bodyClassName="p-0"
                customButton={true}
                hideModalHeaderSeparator={true}
                headerClasses="modal-header-container"
                sizeClasses={"2"}
                modalTitle={"Change Role"}
            >
                <div className="mb-4 mt-4 d-flex flex-column align-items-center">
                    <h4 className="text-center font-bold">
                        Are you sure you wants to change this user's role ?
                    </h4>
                    <p className="text-center">
                        {`This user is a ${UserRoles.getDisplayTextKey(role)}
                            of the following`}
                    </p>
                    <div>
                        <ol className="m-0 p-0 pt-2">
                            {attachedUsers.map((item, index) => (
                                <li key={index}>
                                    {role == UserRoles.Department
                                        ? item.department.departmentName
                                        : item.warehouse.warehouseName}
                                </li>
                            ))}
                        </ol>
                    </div>

                    <div className="d-flex justify-content-center mt-5">
                        <Button
                            type=""
                            title="Cancel"
                            className="location-modal-cancel-btn"
                            onClick={() =>
                                setValidationModalToggle(prev => !prev)
                            }
                        />

                        <Button
                            type=""
                            title="Continue"
                            className="gt-btn-grad-primary location-modal-del-btn"
                            onClick={handleContinueChangeRole}
                        />
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default AddEditUser
