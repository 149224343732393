import React from "react"
import { CardBody } from "reactstrap"

import { Button } from "components/Button"

const DeleteUser = ({}) => {
  return (
    <div className="d-flex flex-column align-items-center  mb-4 mt-4">
      <CardBody>
        <h5 className="user-mang-modal-label">
          Are you want to delete this Employee?
        </h5>
      </CardBody>
      <div className="d-flex justify-content-between mt-5">
        <Button type="" title="Cancel" className="user-mang-modal-cancel-btn" />

        <Button
          type=""
          title="Delete"
          className="gt-btn-grad-primary user-mang-modal-del-btn"
        />
      </div>
    </div>
  )
}

export default DeleteUser
