import React, { useEffect, useState } from "react"
import {
   Route,
   Switch,
   useHistory,
   useParams,
   Redirect,
} from "react-router-dom"
import "./style.scss"
import { Nav, NavItem, NavLink } from "reactstrap"
import { APP_ROUTES, getRoute } from "helpers/routeHelpers"
import { PriceSettings } from "./BaseSettings"
import { Build } from "./Build"
import { SalesAndMarketing } from "./SalesAndMarketing"

const TABS_KEYS = {
   BaseSettings: "baseSettingsTab",
   Build: "buildTab",
   SalesAndMarketing: "salesAndMarketingTabs",
}

const TABS_NAME = {
   BaseSettings: "Base Price Settings",
   Build: "Build",
   SalesAndMarketing: "Sales And Marketing",
}

const TABS_STATUS = {
   [TABS_KEYS.BaseSettings]: false,
   [TABS_KEYS.Build]: false,
   [TABS_KEYS.SalesAndMarketing]: false,
}

const Summary = () => {
   const history = useHistory()
   const { trailerId } = useParams()
   const trailerIdParamObj = { trailerId: trailerId }

   const ROUTES_BASED_KEYS = {
      [getRoute(
         APP_ROUTES.CONFIGURATOR_TRAILER_SETTINGS_SUMMARY_BASE_SETTINGS,
         trailerIdParamObj
      )]: TABS_KEYS.BaseSettings,
      [getRoute(
         APP_ROUTES.CONFIGURATOR_TRAILER_SETTINGS_SUMMARY_BUILD,
         trailerIdParamObj
      )]: TABS_KEYS.Build,
      [getRoute(
         APP_ROUTES.CONFIGURATOR_TRAILER_SETTINGS_SUMMARY_SALES_MARKETING,
         trailerIdParamObj
      )]: TABS_KEYS.SalesAndMarketing,
   }

   const [isActive, setIsActive] = useState({
      ...TABS_STATUS,
      [ROUTES_BASED_KEYS[
         getRoute(history.location.pathname, trailerIdParamObj)
      ]]: true,
   })

   const handleTabClick = (tabKey, route) => {
      setIsActive({
         ...TABS_STATUS,
         [tabKey]: true,
      })
      history.push(route)
   }
   useEffect(() => {
      setIsActive({
         ...TABS_STATUS,
         [ROUTES_BASED_KEYS[
            getRoute(history.location.pathname, trailerIdParamObj)
         ]]: true,
      })
   }, [])

   return (
      <>
         <div className="">
            <Nav pills>
               <NavItem>
                  <NavLink
                     className={`custom-navlink ${
                        isActive[TABS_KEYS.BaseSettings] ? "active" : ""
                     }`}
                     onClick={() =>
                        handleTabClick(
                           TABS_KEYS.BaseSettings,
                           getRoute(
                              APP_ROUTES.CONFIGURATOR_TRAILER_SETTINGS_SUMMARY_BASE_SETTINGS,
                              trailerIdParamObj
                           )
                        )
                     }
                  >
                     {TABS_NAME.BaseSettings}
                  </NavLink>
               </NavItem>
               <NavItem>
                  <NavLink
                     className={`custom-navlink ${
                        isActive[TABS_KEYS.SalesAndMarketing] ? "active" : ""
                     }`}
                     onClick={() =>
                        handleTabClick(
                           TABS_KEYS.SalesAndMarketing,
                           getRoute(
                              APP_ROUTES.CONFIGURATOR_TRAILER_SETTINGS_SUMMARY_SALES_MARKETING,
                              trailerIdParamObj
                           )
                        )
                     }
                  >
                     {TABS_NAME.SalesAndMarketing}
                  </NavLink>
               </NavItem>

               <NavItem>
                  <NavLink
                     className={`custom-navlink ${
                        isActive[TABS_KEYS.Build] ? "active" : ""
                     }`}
                     onClick={() =>
                        handleTabClick(
                           TABS_KEYS.Build,
                           getRoute(
                              APP_ROUTES.CONFIGURATOR_TRAILER_SETTINGS_SUMMARY_BUILD,
                              trailerIdParamObj
                           )
                        )
                     }
                  >
                     {TABS_NAME.Build}
                  </NavLink>
               </NavItem>
            </Nav>
         </div>

         <Switch>
            <Route
               exact
               path={
                  APP_ROUTES.CONFIGURATOR_TRAILER_SETTINGS_SUMMARY_BASE_SETTINGS
               }
               component={PriceSettings}
            />
            <Route
               exact
               path={APP_ROUTES.CONFIGURATOR_TRAILER_SETTINGS_SUMMARY_BUILD}
               component={Build}
            />
            <Route
               exact
               path={
                  APP_ROUTES.CONFIGURATOR_TRAILER_SETTINGS_SUMMARY_SALES_MARKETING
               }
               component={SalesAndMarketing}
            />
            <Redirect
               to={
                  APP_ROUTES.CONFIGURATOR_TRAILER_SETTINGS_SUMMARY_BASE_SETTINGS
               }
            />
         </Switch>
      </>
   )
}

export default Summary
