import { injectBaseConstantMethods } from "./BaseConstants"

const InventoryReqStatus = {
  OPEN: "Open",
  IN_PROGRESS: "in_progress",
  COMPLETED: "Completed",
  INPROGRESS: "InProgress",
}

const displayTextKeys = {
  [InventoryReqStatus.OPEN]: "Open",
  [InventoryReqStatus.IN_PROGRESS]: "In Progress",
  [InventoryReqStatus.INPROGRESS]: "In Progress",
  [InventoryReqStatus.COMPLETED]: "Completed",
}

const labelClass = {
  [InventoryReqStatus.OPEN]: "bg-red-req",
  [InventoryReqStatus.IN_PROGRESS]: "bg-orange-req",
  [InventoryReqStatus.INPROGRESS]: "bg-yellow-req",
  [InventoryReqStatus.COMPLETED]: "bg-green-req",
}

export default injectBaseConstantMethods(
  InventoryReqStatus,
  displayTextKeys,
  labelClass
)
