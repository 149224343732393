import { injectBaseConstantMethods } from "./BaseConstants"

const TrailerType = {
    Spirit: "Spirit",
    Freedom: "Freedom",
    Independence: "Independence",
}

const displayTextKeys = {
    [TrailerType.Spirit]: "Spirit",
    [TrailerType.Freedom]: "Freedom",
    [TrailerType.Independence]: "Independence",
}

const labelClass = {}

export default injectBaseConstantMethods(
    TrailerType,
    displayTextKeys,
    labelClass
)
