import React from "react"
import { Card as ReactStrapCard, CardBody, Col, Row } from "reactstrap"
import IconComponent from "./IconComponent"
import { Button } from "components/Button"
import { Loader } from "components/Loader"

const AnalyticsBox = ({
    color = "",
    className = "",
    config,
    title = "",
    data = 0,
    categoryTitle = "",
    iconImage,
    categoryDescription = "",
    buttonTitle,
    loading = false,
    handleSubmit = () => {},
}) => {
    return (
        <>
            <ReactStrapCard className="analytics-box-container">
                <CardBody className={className}>
                    <div>
                        {title && (
                            <p className="analytics-box-title">{title}</p>
                        )}
                    </div>

                    {/* config */}
                    {config ? (
                        // <div className="d-flex justify-content-around">
                        <Row>
                            {config.map((item, index) => {
                                return (
                                    <>
                                        <Col
                                            lg={4}
                                            md={index === 2 ? "12" : "6"}
                                            sm={12}
                                        >
                                            <div
                                                className={className}
                                                key={index}
                                            >
                                                <div className="analytics-box-data-handler ">
                                                    {loading ? (
                                                        <Loader />
                                                    ) : (
                                                        <h1
                                                            className={`analytics-box-data ${item.color}`}
                                                        >
                                                            {item?.data || 0}
                                                        </h1>
                                                    )}
                                                    {item.categoryTitle && (
                                                        <p className="analytics-box-category-title">
                                                            {item.categoryTitle}
                                                        </p>
                                                    )}
                                                    {item.categoryDescription && (
                                                        <p className="analytics-box-category-description">
                                                            {
                                                                item.categoryDescription
                                                            }
                                                        </p>
                                                    )}
                                                    {item.buttonTitle && (
                                                        <Button
                                                            size="lg"
                                                            className="gt-btn-grad-primary analytics-box-bottom-btn"
                                                            title={
                                                                item.buttonTitle
                                                            }
                                                            onClick={
                                                                item.handleClick
                                                            }
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        </Col>
                                    </>
                                )
                            })}
                            {/* </div> */}
                        </Row>
                    ) : (
                        <>
                            <div className="d-flex align-items-center justify-content-between">
                                {iconImage && (
                                    <div className="analytics-box-icon">
                                        <IconComponent
                                            iconImage={iconImage}
                                            iconClass="analytics-box-icon-img"
                                        />
                                    </div>
                                )}
                            </div>
                            <div className="analytics-box-data-handler mt-3 pb-3">
                                {!!String(data) && (
                                    <>
                                        {loading ? (
                                            <Loader />
                                        ) : (
                                            <h1 className="analytics-box-data mt-2 mb-0">
                                                {" "}
                                                {data || 0}
                                            </h1>
                                        )}
                                    </>
                                )}
                                {categoryTitle && (
                                    <p className="analytics-box-category-title">
                                        {categoryTitle}
                                    </p>
                                )}
                                {categoryDescription && (
                                    <p className="analytics-box-category-description">
                                        {categoryDescription}
                                    </p>
                                )}
                                {buttonTitle && (
                                    <Button
                                        size="lg"
                                        className="gt-btn-grad-primary analytics-box-bottom-btn"
                                        title={buttonTitle}
                                        onClick={history.goBack}
                                    />
                                )}
                            </div>
                        </>
                    )}

                    {/* config */}
                </CardBody>
            </ReactStrapCard>
        </>
    )
}

export default AnalyticsBox
