import { SET_NEW_BOX_PRODUCTS } from "../actions"

const initialState = {
  newBoxData: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_NEW_BOX_PRODUCTS:
      return {
        ...state,
        newBoxData: [...action.payload],
      }
    default:
      return state
  }
}
