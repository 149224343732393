import { injectBaseConstantMethods } from "./BaseConstants"

const UserStatus = {
    ACTIVE: "ACTIVE",
    INACTIVE: "INACTIVE",
}

const displayTextKeys = {
    [UserStatus.ACTIVE]: "Active",
    [UserStatus.INACTIVE]: "In-Active",
}

const labelClass = {
    [UserStatus.USER]: "active-user",
    [UserStatus.INACTIVE]: "inactive-user",
}

export default injectBaseConstantMethods(
    UserStatus,
    displayTextKeys,
    labelClass
)
