import { injectBaseConstantMethods } from "./BaseConstants"

const TrailerPublishStatus = {
   Published: "PUBLISHED",
   UnPublished: "UNPUBLISHED",
}

const displayTextKeys = {
   [TrailerPublishStatus.Published]: "Published",
   [TrailerPublishStatus.UnPublished]: "Unpublished",
}

const labelClass = {}

export default injectBaseConstantMethods(
   TrailerPublishStatus,
   displayTextKeys,
   labelClass
)
