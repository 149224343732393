import { injectBaseConstantMethods } from "./BaseConstants"

const TrailerSize = {
   S15: "S15",
   S17: "S17",
}

const displayTextKeys = {
   [TrailerSize.S15]: "15'",
   [TrailerSize.S17]: "17'",
}

const labelClass = {}

export default injectBaseConstantMethods(
   TrailerSize,
   displayTextKeys,
   labelClass
)
