import { injectBaseConstantMethods } from "./BaseConstants"

const PlacedOrderStatusOM = {
    PENDING: "Pending",
    ALL_ORDERED: "OrderPlaced",
    PARTIALLY_ORDRED: "ParitalOrdered",
    ORDEREDSUBSTITUTE: "OrderedSubstitute",
    All_ORDERED: "AllOrdered",
    ALL_DELIVERED: "AllDelivered",
    PARTIALLY_DELIVERED: "PartialDelivered",
    SUBSTITUTES: "Substitutes",
}

const displayTextKeys = {
  [PlacedOrderStatusOM.PENDING]: "Pending",
  [PlacedOrderStatusOM.ALL_ORDERED]: "All Ordered",
  [PlacedOrderStatusOM.PARTIALLY_ORDRED]: "Partially Ordered",
  [PlacedOrderStatusOM.ORDEREDSUBSTITUTE]: "Substitute Ordered",
  [PlacedOrderStatusOM.All_ORDERED]: "All Ordered",
  [PlacedOrderStatusOM.ALL_DELIVERED]: "All Delivered",
  [PlacedOrderStatusOM.PARTIALLY_DELIVERED]: "Partially Delivered",
  [PlacedOrderStatusOM.SUBSTITUTES]: "Substitutes",



}

const labelClass = {
  [PlacedOrderStatusOM.PENDING]: "fm-bg-light-red",
  [PlacedOrderStatusOM.ALL_ORDERED]: "fm-bg-light-green",
  [PlacedOrderStatusOM.PARTIALLY_ORDRED]: "fm-bg-light-yellow",
  [PlacedOrderStatusOM.ORDEREDSUBSTITUTE]: "fm-bg-light-blue",
  [PlacedOrderStatusOM.All_ORDERED]: "fm-bg-light-green",
  [PlacedOrderStatusOM.ALL_DELIVERED]: "fm-bg-light-green",
  [PlacedOrderStatusOM.PARTIALLY_DELIVERED]: "fm-bg-light-yellow",
  [PlacedOrderStatusOM.SUBSTITUTES]: "fm-bg-light-blue",





}

export default injectBaseConstantMethods(
  PlacedOrderStatusOM,
  displayTextKeys,
  labelClass
)