import React from "react"
import Slider from "react-slick"

import { getMediaPath } from "utils/mediaUtils"

const ImagePreview = ({
    images,
    settings,
    containerClasses = "",
    imageContainer = "",
    sliderImage = "",
}) => {
    return (
        <div className={`${containerClasses}`}>
            <Slider {...settings}>
                {images?.map((image, index) => (
                    <div key={index} className={`${imageContainer} `}>
                        <img
                            src={getMediaPath(image)}
                            alt={`Image ${index + 1}`}
                            className={` ${sliderImage}`}
                            height={"100%"}
                            width={"100%"} // Adjust the image styling here
                        />
                    </div>
                ))}
            </Slider>
        </div>
    )
}

// const ImagePreview = ({
//     images,
//     settings,
//     containerClasses,
//     imageContainer,
//     sliderImage,
//     loading = false,
// }) => {
//     const adjustedSettings = {
//         ...settings,
//         infinite: images?.length > 1,
//         dots: images?.length > 1,
//     }

//     return (
//         <div className={`${containerClasses}`}>
//             <Slider {...settings}>
//                 {images?.map((image, index) => (
//                     <div key={index} className={`${imageContainer} `}>
//                         <img
//                             src={getMediaPath(image)}
//                             alt={`Image ${index + 1}`}
//                             className={`${sliderImage}`}
//                         />
//                     </div>
//                 ))}
//             </Slider>
//         </div>
//     )
// }

export default ImagePreview
