import { injectBaseConstantMethods } from "./BaseConstants"

const InventorySettings = {
    Timer: "Timer",
    DownpaymentPercentage: "DownpaymentPercentage",
}

const displayTextKeys = {
    [InventorySettings.Timer]: "Timer",
    [InventorySettings.DownpaymentPercentage]: "Downpayment Percentage",
}

const labelClass = {
    [InventorySettings.Timer]: "Timer",
    [InventorySettings.DownpaymentPercentage]: "Downpayment Percentage",
}

export default injectBaseConstantMethods(
    InventorySettings,
    displayTextKeys,
    labelClass
)
