import React, { useState, useRef } from "react"
import toast from "react-hot-toast"

import { Button } from "components/Button"
import { FileTypes } from "../../../constants"
import modalCloseIcon from "../../../assets/images/inventory-request/modal-close-icon.png"

const MAX_IMG_SIZE = 5120

const UploadInvFileModal = ({
    title,
    fileSubmitHandler,
    isFileSubmitLoading,
}) => {
    const [fileUploaded, setFileUploaded] = useState("")
    const [isFileUploaded, setIsFileUploaded] = useState(false)
    const [fileName, setFileName] = useState(null)
    const InvFileUpload = useRef(null)

    const handleFileUploadClick = () => {
        InvFileUpload.current.click()
    }

    const handleFileChange = e => {
        let file = e.target.files[0]
        if (file) {
            let uploadedFileType = file?.name?.split(".")
            uploadedFileType = uploadedFileType[uploadedFileType.length - 1]
            let fileSize = file && file?.size
            fileSize = fileSize / MAX_IMG_SIZE
            let fileName = file?.name
            if (file && FileTypes.includes(uploadedFileType)) {
                setFileName(fileName)
                setFileUploaded(file)

                setIsFileUploaded(true)
            } else {
                toast.error(
                    "File type is invalid! kindly upload a csv file type."
                )
                setIsFileUploaded(false)
                setFileUploaded("")
            }
        }
    }

    const removeImageHandler = () => {
        setFileName(null)
        setFileUploaded("")
        setIsFileUploaded(false)
        InvFileUpload.current.value = null
    }

    const submitFileHandler = () => {
        if (!fileUploaded) {
            toast.error("File is required !")
            return
        }
        fileSubmitHandler(fileUploaded)
    }
    return (
        <div className="image-upload-container border-0 mb-4">
            <h3 className="text-center inv-req-popup-heading mb-0">{title}</h3>
            <div
                role="button"
                onClick={handleFileUploadClick}
                className="text-center bg-white inv-file-upload-con px-2 py-1"
            >
                <p className="mb-0">Browse File</p>

                <input
                    id="profilePicUpload"
                    ref={InvFileUpload}
                    type="file"
                    hidden
                    onChange={handleFileChange}
                    accept=".csv"
                />
            </div>
            {isFileUploaded && (
                <>
                    <div className="text-center inv-file-uploaded-con d-flex justify-content-between align-items-center px-2 py-1 mb-0">
                        <div className="">
                            <p
                                className="mb-0 csv-upload-type
             "
                            >
                                {fileName}
                            </p>
                        </div>
                        <button
                            className="custom-btn-close-popup pe-0"
                            onClick={() => removeImageHandler()}
                            type="button"
                        >
                            <img src={modalCloseIcon} />
                        </button>
                    </div>
                    <div className="text-center mt-2 d-flex justify-content-center align-items-center">
                        <Button
                            isLoading={isFileSubmitLoading}
                            size="lg"
                            onClick={submitFileHandler}
                            className="gt-btn-grad-primary btn-height btn-alignment upload-csv-btn"
                            title="Upload File"
                        />
                    </div>
                </>
            )}
        </div>
    )
}

export default UploadInvFileModal
