import React from "react"
import { CardBody } from "reactstrap"

import { Button } from "components/Button"

const DeleteDepartment = ({ value, handleModalClose, handleDeleteRecord }) => {
  return (
    <div className="d-flex flex-column align-items-center  mb-4 mt-4">
      <CardBody>
        <h5 className="location-modal-label">
          Are you want to delete this {value}?
        </h5>
      </CardBody>
      <div className="d-flex justify-content-between mt-5">
        <Button
          type=""
          title="Cancel"
          className="location-modal-cancel-btn"
          onClick={handleModalClose}

          // isLoading={null}
        />

        <Button
          type=""
          title="Delete"
          className="gt-btn-grad-primary location-modal-del-btn"
          onClick={handleDeleteRecord}
          // isLoading={null}
        />
      </div>
    </div>
  )
}

export default DeleteDepartment
