import React, { useState, useEffect } from "react"
import { CardBody, Card } from "reactstrap"

import { SearchBox } from "components/SearchBox"
import ActionEyeIcon from "assets/images/icons/feather-eye-icon.png"
import { Pagination } from "components/Pagination"
import { DataTable } from "components/DataTable"
import { AutoComplete } from "components/AutoComplete"
import PullbackRequestItems from "./PullbackRequestItems"
import { Modal } from "components/Modal"
import { useLocalPaginatedRequest } from "hooks/useLocalPaginatedRequest"
import { InventoryReqStatus } from "constants"
import { dateTimeFormat, convertDateTime } from "utils/dateFormatUtils"
import customStyles from "./HeaderStatusStyling"
import {
    GetPullbackRequestListing,
    GetDepartmentsListing,
} from "api/api.service"
import Config from "Config"
import { Refresh } from "components/Refresh"

const COLUMNS = {
    REQUEST_NO: "Request No.",
    DATE_OF_REQUEST: "Requested Date",
    DEPARTMENT: "Station",
    STATUS: "Status",
    ACTION: "Actions",
}
const DATA_KEY = "data"
const TOTAL_KEY = "total"

const DepartmentRequestListing = () => {
    let controller = new AbortController()
    let signal = controller.signal
    const [selectedData, setSelectedData] = useState({})
    const [statusFilters, setStatusFilters] = useState(null)
    const [departmentsListing, setDepartmentsListing] = useState({
        data: [],
        fetching: true,
        page: 1,
        isDataAvailable: true,
    })
    const [modalToggle, setModalToggle] = useState(false)
    const [departmentsFilter, setDepartmentsFilter] = useState(null)

    const {
        data,
        page,
        pageSize,
        total,
        isFetching,
        searchText,
        request,
        handleSearchChange,
        handlePageClick,
        onChangePageSize,
    } = useLocalPaginatedRequest({
        requestFn: GetPullbackRequestListing,
        params: {
            ...(statusFilters?.value && {
                requestStatus: statusFilters?.value,
            }),
            ...(departmentsFilter?.length && {
                departmentId: departmentsFilter.map(item => item.value).join(),
            }),
        },
        deps: [statusFilters, departmentsFilter],
        dataKey: DATA_KEY,
        totalKey: TOTAL_KEY,
    })

    useEffect(() => {
        handleDepartmentsListing(departmentsListing.page)

        return () => {
            controller.abort()
        }
    }, [departmentsListing.Q])

    const handleSelectDepartments = option => {
        if (typeof option != "object" || !option) return
        setDepartmentsFilter(option)
    }
    const handleDepartmentSearch = (value = "", callback) => {
        if (!value) return

        callback(prev => {
            return {
                ...prev,
                data: [],
                page: 1,
                Q: value,
            }
        })
    }

    const handleDepartmentsListing = page => {
        setDepartmentsListing(prev => {
            return {
                ...prev,
                fetching: true,
            }
        })

        GetDepartmentsListing(
            {
                Limit: Config.LIMIT,
                ...(departmentsListing.Q && { Q: departmentsListing.Q }),
                Page: page,
            },
            signal
        )
            .then(res => {
                setDepartmentsListing(prev => ({
                    ...prev,
                    fetching: false,
                    page: page + 1,
                    isDataAvailable: res?.data?.length == Config.LIMIT,
                    data: (() => {
                        let test = res?.data?.map(item => ({
                            ...item,
                            value: item.id,
                            label: item.departmentName,
                        }))

                        return [...test]
                    })(),
                }))
            })
            .finally(() => {
                setDepartmentsListing(prev => {
                    return {
                        ...prev,
                        fetching: false,
                    }
                })
            })
    }

    const statusSelectOptions = [
        {
            label: "Open",
            value: "Open",
        },
        {
            label: "In Progress",
            value: "InProgress",
        },
        {
            label: "Completed",
            value: "Completed",
        },
    ]

    const handleModalToggle = data => {
        setSelectedData(data || {})
        setModalToggle(prev => !prev)
    }
    const handleSelectStatusOption = option => {
        setStatusFilters(option)
    }

    return (
        <>
            <Card className="mb-5">
                <Refresh onClick={request} />
                <CardBody className="p-2 p-md-4">
                    <div className="mb-3">
                        <div className="d-flex flex-column flex-lg-row align-items-center justify-content-between  finance-om-sr-header">
                            <div className="d-flex justify-content-lg-start justify-content-between align-items-center finance-om-scrap-return">
                                <div className="min-250-w me-2 autocomplete-first">
                                    <AutoComplete
                                        isMulti
                                        placeholder="Stations"
                                        value={departmentsFilter}
                                        customStyles={customStyles.WarehouseIM}
                                        classNamePrefix="status-header-search-admin"
                                        handleBlur={() =>
                                            setDepartmentsListing(prev => {
                                                return {
                                                    ...prev,
                                                    page: 1,
                                                    Q: "",
                                                }
                                            })
                                        }
                                        onInputChange={val =>
                                            handleDepartmentSearch(
                                                val,
                                                setDepartmentsListing
                                            )
                                        }
                                        options={departmentsListing.data}
                                        onChange={val =>
                                            handleSelectDepartments(val)
                                        }
                                        closeMenuOnSelect={true}
                                        isLoading={departmentsListing?.fetching}
                                    />
                                </div>
                                <div className="min-250-w ">
                                    <AutoComplete
                                        value={statusFilters}
                                        isClearable
                                        onChange={val =>
                                            handleSelectStatusOption(val)
                                        }
                                        isSearchable={false}
                                        placeholder="Select status"
                                        customStyles={customStyles.WarehouseIM}
                                        classNamePrefix="status-header-search-admin"
                                        options={statusSelectOptions}
                                    />
                                </div>
                            </div>
                            <div className="location-search-box finance-location-search">
                                <SearchBox
                                    searchText={searchText}
                                    onChange={handleSearchChange}
                                    inputClasses="vendor-search-input input-search"
                                    labelClass="d-none"
                                    placeholder="Search"
                                    searchIcon={true}
                                    searchIconClass="vendor-table-search-icon"
                                    conClass="vendor-table-search-inner-class"
                                />
                            </div>
                        </div>
                    </div>
                    <DataTable
                        data={data}
                        loading={isFetching}
                        tableClasses="finance-om-pullback-table"
                        config={[
                            {
                                title: COLUMNS.REQUEST_NO,
                                render: data => data.id,
                            },
                            {
                                title: COLUMNS.DATE_OF_REQUEST,
                                render: data =>
                                    convertDateTime({
                                        date: data.createdAt,
                                        customFormat:
                                            dateTimeFormat.casitaCustomDateFormat,
                                        dateOnly: true,
                                    }),
                            },
                            {
                                title: COLUMNS.DEPARTMENT,
                                render: data => data?.departmentName,
                            },
                            {
                                title: COLUMNS.STATUS,
                                render: data => {
                                    return (
                                        <div
                                            className={`inv-req-popup-status ${InventoryReqStatus.getLabelClass(
                                                data.status
                                            )}`}
                                        >
                                            {InventoryReqStatus.getDisplayTextKey(
                                                data.status
                                            )}
                                        </div>
                                    )
                                },
                            },
                            {
                                className: "text-center",
                                title: COLUMNS.ACTION,
                                render: data => {
                                    return (
                                        <span
                                            className="text-success pointer"
                                            onClick={() =>
                                                handleModalToggle(data)
                                            }
                                        >
                                            <img src={ActionEyeIcon} />
                                        </span>
                                    )
                                },
                            },
                        ]}
                    />
                    <Pagination
                        currentPage={page}
                        totalData={total}
                        onPageClick={handlePageClick}
                        rowLimit={pageSize}
                    />
                </CardBody>
            </Card>
            <Modal
                // styles={{ minHeight: "60vh" }}
                isOpen={modalToggle}
                handleModalToggling={handleModalToggle}
                bodyClassName="p-0"
                customButton={true}
                hideModalHeaderSeparator={true}
                headerClasses="pb-0"
                className="inv-req-popup-viewdetails"
                sizeClasses="10"
            >
                <PullbackRequestItems
                    modalCloseHandler={handleModalToggle}
                    data={selectedData}
                />
            </Modal>
        </>
    )
}

export default DepartmentRequestListing
