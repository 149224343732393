import React, { useEffect, useState } from "react"
import { Col, Row } from "reactstrap"
import { Switch, Route, useHistory, Redirect } from "react-router-dom"
import { Link } from "react-router-dom"

import { APP_ROUTES } from "helpers/routeHelpers"
import { NavTabsRoutingBasedButtons as NavButtons } from "../../AdminApp/Common/NavTabsRoutingBased"
import WarehouseIconActive from "../../../assets/images/icons/warehouse.png"
import WarehouseIcon from "../../../assets/images/icons/warehouse-deactive.png"
import DepartmentIconActive from "../../../assets/images/icons/storeIcon.png"
import DepartmentIcon from "../../../assets/images/icons/storeIcon-deactive.png"
import WarehouseListing from "./warehouse/WarehouseListing"
import DepartmentListing from "./department/DepartmentListing"
import DepartmentItemCatalogListing from "./department/ItemCatalog/DepartmentItemCatalogListing"

let ITEM_CATALOG_URL = "item-catalog"

const LocationsListing = () => {
    const history = useHistory()

    const pathURL = history.location.pathname

    const [isActive, setIsActive] = useState({
        departmentLocation: null,
        warehouseLocation: null,
    })

    useEffect(() => {
        const isDepartment =
            pathURL === "/locations"
                ? false
                : pathURL === "/locations/station"
                ? true
                : false
        const isWarehouse =
            pathURL === "/locations"
                ? true
                : pathURL === "/locations/warehouse"
                ? true
                : false
        setIsActive({
            departmentLocation: isDepartment,
            warehouseLocation: isWarehouse,
        })
    }, [pathURL])

    const handleDepartmentLocationClick = () => {
        setIsActive({
            ...isActive,
            departmentLocation: true,
            warehouseLocation: false,
        })
        history.push(APP_ROUTES.ADMIN_LOCATION_DEPARTMENT)
    }

    const handleWarehouseLocationClick = () => {
        setIsActive({
            ...isActive,
            departmentLocation: false,
            warehouseLocation: true,
        })
        history.push(APP_ROUTES.ADMIN_LOCATION_WAREHOUSE)
    }
    const mainBtnsConfig = [
        {
            title: "Warehouse(s)",
            onClick: handleWarehouseLocationClick,
            classesButton: "al-nav-link",
            identifier: "warehouseLocation",
            icon: WarehouseIcon,
            activeIcon: WarehouseIconActive,
        },
        {
            title: "Stations",
            onClick: handleDepartmentLocationClick,
            classesButton: "al-nav-link",
            identifier: "departmentLocation",
            icon: DepartmentIcon,
            activeIcon: DepartmentIconActive,
        },
    ]

    const routesConfig = [
        {
            name: "Station Location",
            isExact: true,
            component: DepartmentListing,
            path: APP_ROUTES.ADMIN_LOCATION_DEPARTMENT,
        },
        {
            name: "Items Catalog",
            isExact: true,
            component: DepartmentItemCatalogListing,
            path: APP_ROUTES.ADMIN_LOCATION_DEPARTMENT_ITEM_CATALOG,
        },
        {
            name: "Warehouse Location",
            isExact: true,
            component: WarehouseListing,
            path: APP_ROUTES.ADMIN_LOCATION_WAREHOUSE,
        },
    ]

    return (
        <>
            <Row>
                <Col className="col-12">
                    <div className="d-flex align-items-baseline">
                        <Link
                            to={
                                pathURL.includes(ITEM_CATALOG_URL) &&
                                APP_ROUTES.ADMIN_LOCATION_DEPARTMENT
                            }
                        >
                            <h1>{"Locations "}</h1>
                        </Link>

                        {pathURL.includes(ITEM_CATALOG_URL) && (
                            <Link>
                                <h1>{" > Item Catalog"}</h1>
                            </Link>
                        )}
                    </div>

                    {!pathURL.includes(ITEM_CATALOG_URL) && (
                        <div className="admin-location mb-3">
                            <div className="al-navtabs-main">
                                <div className="al-nav-tabs">
                                    <div className="al-nav-item d-flex">
                                        <NavButtons
                                            data={mainBtnsConfig}
                                            classes={"ps-1 d-inline-block"}
                                            isActive={isActive}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    <Switch>
                        {routesConfig.map(
                            ({ name, isExact, path, component }, idx) => (
                                <Route
                                    key={idx}
                                    name={name}
                                    exact={isExact}
                                    path={path}
                                    component={component}
                                />
                            )
                        )}
                        <Redirect to={APP_ROUTES.ADMIN_LOCATION_WAREHOUSE} />
                    </Switch>
                </Col>
            </Row>
        </>
    )
}

export default LocationsListing
