import { injectBaseConstantMethods } from "./BaseConstants"

const ConfiguratorEquipmentItemSelectionType = {
    Single: "SINGLE",
    Multiple: "MULTIPLE",
}

const displayTextKeys = {
    [ConfiguratorEquipmentItemSelectionType.Single]: "Single Select",
    [ConfiguratorEquipmentItemSelectionType.Multiple]: "Multi Select",
}

const labelClass = {}

export default injectBaseConstantMethods(
    ConfiguratorEquipmentItemSelectionType,
    displayTextKeys,
    labelClass
)
