import React, { Component } from "react"
import Select from "react-select"
import Animated from "react-select/animated"

let styles = {
   container: provided => ({
      display: "inline-block",
      position: "relative",
      width: "100%",
      background: "#FFFFFF",
      border: "1px solid #CED4DA",
      borderRadius: "4px",
      width: "130px",
      fontSize: "13px",
      ...provided,
   }),
   control: (provided, state) => ({
      ...provided,
      // borderRadius: "5px",
      minHeight: "45px",
      borderColor: "#9d9d9d",
      // backgroundColor: "#eef9ff",
      border: state.isFocused ? 0 : 0,
      // This line disable the blue border
      boxShadow: state.isFocused ? 0 : 0,
      "&:hover": {
         borderColor: "#9d9d9d",
      },
   }),
}

class AutoComplete extends Component {
   render() {
      let {
         className = "",
         classNamePrefix = "",
         options,
         onInputChange = () => {},
         onChange,
         handleBlur = () => {},
         customStyles = undefined,
         maxMenuHeight,
         isClearable = false,
         placeholder = "",
         isLoading = false,
         value,
         isMulti = false,
         handleScroll = undefined,
         handleOnFocus = undefined,
         selectRef,
         isOptionDisabled = undefined,
         isDisabled,
         closeMenuOnSelect = true,
         ...rest
      } = this.props
      styles = customStyles ? customStyles : styles

      return (
         <Select
            isDisabled={isDisabled}
            onFocus={handleOnFocus}
            onBlur={handleBlur}
            ref={selectRef}
            onMenuScrollToBottom={handleScroll}
            className={className}
            classNamePrefix={classNamePrefix}
            options={options}
            onInputChange={onInputChange}
            onChange={onChange}
            styles={styles}
            maxMenuHeight={maxMenuHeight}
            isClearable={isClearable}
            placeholder={placeholder}
            closeMenuOnSelect={closeMenuOnSelect}
            isLoading={isLoading}
            value={value}
            isOptionDisabled={isOptionDisabled}
            isMulti={isMulti}
            components={Animated()}
            {...rest}
         />
      )
   }
}

export default AutoComplete
