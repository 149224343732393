import { injectBaseConstantMethods } from "./BaseConstants"

const TrailerStatus = {
    Active: "ACTIVE",
    Inactive: "INACTIVE",
}

const displayTextKeys = {
    [TrailerStatus.Active]: "Active",
    [TrailerStatus.Inactive]: "Inactive",
}

const labelClass = {}

export default injectBaseConstantMethods(
    TrailerStatus,
    displayTextKeys,
    labelClass
)
