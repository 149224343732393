import React, { useState } from "react"

import { downloadPdf } from "utils/downloadUtils"
import { GetPdfReport } from "api/api.service"

const useGetPdfReport = () => {
    let timeZone = -(new Date().getTimezoneOffset() / 60)

    const [fetchingReport, setFetchingReport] = useState(false)
    const [reportData, setReportData] = useState("")

    const handleGetPdfReport = async (reqId = "", requestType = "") => {
        if (!reqId || !requestType) return

        let paramsBody = {
            requests: requestType,
            requestId: reqId,
            offset: timeZone,
        }

        try {
            setFetchingReport(true)
            let res = await GetPdfReport(paramsBody)

            await downloadPdf(res)
            setReportData(res)
        } catch (err) {
            // toast.error(err.message)
        } finally {
            setFetchingReport(false)
        }
    }

    return { reportData, fetchingReport, handleGetPdfReport }
}

export default useGetPdfReport
