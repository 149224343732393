import React, { useState } from "react"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import { withTranslation } from "react-i18next"

import { showRightSidebarAction, toggleLeftmenu } from "../../store/actions"
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu"
import logo from "assets/images/casita-logo.svg"

const Header = props => {
    return (
        <React.Fragment>
            <header id="page-topbar">
                <div className="navbar-header">
                    <div className="d-flex">
                        <div className="navbar-brand-box">
                            <Link to="/" className="logo logo-dark">
                                {/* Logos will change */}
                                <span className="logo-sm">
                                    <img src={logo} alt="" />
                                </span>
                                <span className="logo-lg">
                                    <img src={logo} alt="" />
                                </span>
                            </Link>

                            <Link to="/" className="logo logo-light">
                                <span className="logo-sm">
                                    <img src={logo} alt="" />
                                </span>
                                <span className="logo-lg">
                                    <img src={logo} alt="" />
                                </span>
                            </Link>
                        </div>

                        <button
                            type="button"
                            className="btn btn-sm px-3 font-size-16 d-none header-item"
                            data-toggle="collapse"
                            onClick={() => {
                                props.toggleLeftmenu(!props.leftMenu)
                            }}
                            data-target="#topnav-menu-content"
                        >
                            <i className="fa fa-fw fa-bars" />
                        </button>
                    </div>

                    <div className="d-flex">
                        {/* <NotificationDropdown /> */}

                        <ProfileMenu />
                    </div>
                </div>
            </header>
        </React.Fragment>
    )
}

const mapStatetoProps = state => {
    const { layoutType, showRightSidebar, leftMenu } = state.Layout
    return { layoutType, showRightSidebar, leftMenu }
}

export default connect(mapStatetoProps, {
    showRightSidebarAction,
    toggleLeftmenu,
})(withTranslation()(Header))
