import { injectBaseConstantMethods } from "./BaseConstants"

const WarehouseOrderStatus = {
    PENDING: "Pending",
    ALL_DELIVERED: "all delivered",
    PARTIALLY_DELIVERED: "partially delivered",
    // COMPLETED: "order placed",
}

const displayTextKeys = {
  [WarehouseOrderStatus.PENDING]: "Pending",
  [WarehouseOrderStatus.ALL_DELIVERED]: "All Delivered",
  [WarehouseOrderStatus.PARTIALLY_DELIVERED]: "Partially Delivered",
}

const labelClass = {
  [WarehouseOrderStatus.PENDING]: "fm-bg-light-red",
  [WarehouseOrderStatus.ALL_DELIVERED]: "fm-bg-light-green",
  [WarehouseOrderStatus.PARTIALLY_DELIVERED]: "fm-bg-light-blue",
}

export default injectBaseConstantMethods(
  WarehouseOrderStatus,
  displayTextKeys,
  labelClass
)