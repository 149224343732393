import React from "react"
import { Container, Row, Col, FormGroup, Label, Input } from "reactstrap"
import "./Style.scss"

const FormSkeleton = () => (
    <Row>
        <Col md={{ size: 12 }}>
            <div className="d-flex align-items-center flex-column">
                <div className="skeleton-input"></div>
                <div className="skeleton-input"></div>
                <div className="skeleton-input"></div>
                <div className="skeleton-input"></div>

                <div className="skeleton-button"></div>
            </div>
        </Col>
    </Row>
)

export default FormSkeleton
