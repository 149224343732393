import React from "react";
import { Spinner } from "reactstrap";

import "./Loader.scss";

const OverlayLoader = (props) => {
    return (
        <div className="overlay-loading-container">
            <Spinner />
        </div>
    );
};

export default OverlayLoader;
