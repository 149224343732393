import React, { useState } from "react"

import { Button } from "components/Button"
import { DataTable } from "components/DataTable"
import { Row, Col } from "reactstrap"
import { PlacedOrderStatusOM } from "../../../../constants"
import {
    dateTimeFormat,
    convertDateTime,
} from "../../../../utils/dateFormatUtils"
import { GetPurchaseRequestsItems } from "api/api.service"
import { useLocalPaginatedRequest } from "hooks/useLocalPaginatedRequest"
import eyeIcon from "assets/images/icons/feather-eye-icon.png"
import { Modal } from "components/Modal"
import RequestItemsReceivingListing from "./RequestItemsReceivingListing"
import { InventoryReqItemsStatus } from "constants"
import { AutoComplete } from "components/AutoComplete"
import { SearchBox } from "components/SearchBox"
import customStyles from "../HeaderStatusStyling"
import { convertToSelectOptions } from "utils/commonUtils"

const COLUMNS = {
    PO_NO: "PO No.",
    SKU: "SKU No.",
    VENDOR_NAME: "Vendor",
    ITEM_NAME: "Item Name",
    REQUESTED_QUANTITY: "Requested Quantity",
    RECEIVED_QUANTITY: "Received Quantity",
    UNIT_PRICE: "Unit Price",
    TOTAL_PRICE: "Total Price",
    STATUS: "Status",
    QUANTITY_SUPPLIED: "Supplied Quantity",
    DATE_OF_RECEIVED_ITEMS: "Date of Received Item",
    SUBSITUTE_ITEM: "Subsitute Name",
    ACTION: "Action",
}
const dataKey = "data"
const totalKey = "total"

const OMViewDetailModal = ({
    title,
    footerBtnType,
    modalCloseHandler,
    requestId,
}) => {
    const [statusFilters, setStatusFilters] = useState(null)
    const [modal, setModal] = useState(false)
    const [selectedItem, setSelectedItem] = useState(null)

    const {
        data,
        page,
        pageSize,
        isFetching,
        searchText,
        total,
        setData,
        handleSearchChange,
        handlePageClick,
        onChangePageSize,
        request,
    } = useLocalPaginatedRequest({
        requestFn: GetPurchaseRequestsItems,
        params: {
            ...(statusFilters?.status && {
                status: statusFilters?.status,
            }),
        },
        deps: [statusFilters],
        dataKey: dataKey,
        totalKey: totalKey,
        id: requestId,
    })

    const handleSelectOptionChange = val => {
        setStatusFilters({ status: val.value })
    }

    return (
        <>
            <h3 className="text-left fm-req-popup-heading mb-0 px-5 mb-5">
                {title}
            </h3>
            <div className="pt-3 px-5">
                <Row className="mb-2">
                    <Col sm="6" xs="12" md="12" lg="12">
                        <p className="place-order-top-date my-auto mb-2">
                            Request No: <span>{requestId}</span>
                        </p>
                        <p className="place-order-top-date my-auto">
                            Date of Request:{" "}
                            <span>
                                {convertDateTime({
                                    date: data?.[0]?.createdAt,
                                    customFormat:
                                        dateTimeFormat.casitaCustomDateFormat,
                                    dateOnly: true,
                                })}
                            </span>
                        </p>
                    </Col>

                    <Row>
                        <Col>
                            <div className="d-flex gap-2 flex-wrap justify-content-end">
                                <div className="status-search-con">
                                    <AutoComplete
                                        isSearchable={false}
                                        placeholder="Select Status"
                                        customStyles={customStyles.WarehouseIM}
                                        classNamePrefix="fm-status-header-search"
                                        options={convertToSelectOptions(
                                            InventoryReqItemsStatus
                                        )}
                                        onChange={handleSelectOptionChange}
                                    />
                                </div>
                                <div className="fm-search-input finance-byorders-search-ip">
                                    <SearchBox
                                        searchText={searchText}
                                        onChange={handleSearchChange}
                                        inputClasses="table-main-search-input fm-table-input"
                                        labelClass="d-none"
                                        placeholder="Search"
                                        searchIcon={true}
                                        searchIconClass="fm-table-search-icon"
                                        conClass="fm-table-search-con"
                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Col
                        sm="12"
                        xs="12"
                        md="12"
                        lg="12"
                        className="px-0 mb-4 mt-3"
                    >
                        <DataTable
                            loading={isFetching}
                            data={data}
                            className=" mt-3 fm-place-order-table place-order-data-table finance-vertical-scroll-om po-data-table-finance"
                            tableClasses="finance-order-management-order-by-order-modal-table"
                            config={[
                                {
                                    title: COLUMNS.SKU,
                                    className: "table-column-bold",
                                    render: data => data?.skuNo || "-",
                                },
                                {
                                    title: COLUMNS.ITEM_NAME,
                                    render: data => data?.itemName || "-",
                                },
                                {
                                    title: COLUMNS.REQUESTED_QUANTITY,
                                    className: "table-column-bold",
                                    render: data => data?.quantity || "-",
                                },
                                {
                                    title: COLUMNS.RECEIVED_QUANTITY,
                                    render: data => (
                                        <span>{data.receivedQuantity}</span>
                                    ),
                                },

                                {
                                    title: COLUMNS.SUBSITUTE_ITEM,
                                    render: data =>
                                        data?.substitute?.itemName || "-",
                                },

                                {
                                    title: COLUMNS.QUANTITY_SUPPLIED,
                                    render: data => {
                                        return (
                                            Math.abs(data.quantitySupplied) ||
                                            "-"
                                        )
                                    },
                                },
                                {
                                    title: COLUMNS.DATE_OF_RECEIVED_ITEMS,
                                    render: data => {
                                        return convertDateTime(
                                            {
                                                date: data.createdAt,
                                                customFormat:
                                                    dateTimeFormat.casitaCustomDateFormat,
                                                dateOnly: true,
                                            } || "-"
                                        )
                                    },
                                },
                                {
                                    title: COLUMNS.STATUS,
                                    render: data => {
                                        return (
                                            <div
                                                className={`place-order-status ${InventoryReqItemsStatus.getLabelClass(
                                                    data?.status
                                                )}`}
                                            >
                                                {InventoryReqItemsStatus.getDisplayTextKey(
                                                    data?.status
                                                ) || "-"}
                                            </div>
                                        )
                                    },
                                },
                                {
                                    className: "text-center",
                                    title: COLUMNS.ACTION,
                                    render: data => {
                                        return data.status !=
                                            InventoryReqItemsStatus.Pending ? (
                                            <span
                                                className="text-success pointer"
                                                onClick={() => {
                                                    setModal(prev => !prev)
                                                    setSelectedItem(data)
                                                }}
                                            >
                                                <img src={eyeIcon} />
                                            </span>
                                        ) : (
                                            "-"
                                        )
                                    },
                                },
                            ]}
                        />
                    </Col>
                </Row>
            </div>

            <div className="text-center my-4 d-flex justify-content-center align-items-center">
                <Button
                    size="lg"
                    className="gt-btn-grad-primary btn-height me-2 d-flex justify-content-center align-items-center place-order-btn"
                    title={<div>Done</div>}
                    onClick={modalCloseHandler}
                />
            </div>

            <Modal
                isOpen={modal}
                handleModalToggling={setModal}
                bodyClassName="p-0"
                customButton={true}
                hideModalHeaderSeparator={true}
                headerClasses="pb-0"
                className="inv-req-popup-viewdetails"
                sizeClasses="10"
            >
                <RequestItemsReceivingListing
                    modalCloseHandler={setModal}
                    title={`Request # ${requestId}`}
                    requestItemId={selectedItem?.id}
                    footerBtnType=""
                />
            </Modal>
        </>
    )
}

export default OMViewDetailModal
