import React, { useEffect, useState } from "react"
import { Button, ButtonGroup } from "reactstrap"

// Example Prop
// const btnConfig = [
//   {
//     color: "primary",
//     isOutlined: true,
//     onClick: () => {},
//     activeId: 1,
//     classes: "btn-common",
//     btnTitle: "Title",
//   },
// ]

const GroupedButtons = ({
  handleSetActive = () => {},
  btnConfig = [],
  defaultActive = "",
  btnContainerStyles = {},
  btnContainerClasses = "",
  isReadOnly = false,
}) => {
  const [active, setActive] = useState()

  useEffect(() => {
    setActive(defaultActive)
    // handleSetActive(defaultActive)
  }, [defaultActive])

  const handleBtnClick = (id, cb) => {
    setActive(id)
    handleSetActive(id)
    cb()
  }

  return (
    <>
      <ButtonGroup
        style={{ ...btnContainerStyles }}
        className={btnContainerClasses}
      >
        {btnConfig?.map(
          (
            { color, onClick, activeId, classes, btnTitle, isOutlined },
            ind
          ) => {
            return (
              <Button
                disabled={isReadOnly && activeId != active}
                key={ind}
                color={color}
                outline={isOutlined}
                onClick={() => handleBtnClick(activeId, onClick)}
                active={active == activeId}
                className={classes}
              >
                {btnTitle}
              </Button>
            )
          }
        ) || null}
      </ButtonGroup>
    </>
  )
}

export default GroupedButtons

// import React, { useState } from "react"
// import { Button, ButtonGroup } from "reactstrap"

// const GroupedButtons = ({
//   btnConfig = [],
//   btnContainerStyles = {},
//   btnContainerClasses = "",
//   btnCommonClasses = "",
//   defaultActive
// }) => {
//   const [rSelected, setRSelected] = useState(null)
//   console.log('da', defaultActive)
//   return (
//     <>
//       <ButtonGroup
//         style={{ ...btnContainerStyles }}
//         className={btnContainerClasses}
//       >
//         <Button
//           color="primary"
//           outline
//           onClick={() => setRSelected(1)}
//           active={rSelected === 1}
//           className={btnCommonClasses}
//         >
//           Scrap
//         </Button>

//         <Button
//           color="primary"
//           outline
//           onClick={() => setRSelected(2)}
//           active={rSelected === 2}
//           className={btnCommonClasses}
//         >
//           Return
//         </Button>
//       </ButtonGroup>
//     </>
//   )
// }

// export default GroupedButtons
