import React, { useEffect, useRef } from "react"
import SimpleBar from "simplebar-react"
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"
import { withTranslation } from "react-i18next"
import { useSelector } from "react-redux"

import { sideBarContent } from "./SidebarConfig"
import { UserRoles } from "../../constants"
import { APP_ROUTES } from "helpers/routeHelpers"

const SidebarContent = props => {
    const userType = useSelector(state => state?.userAuth?.user?.type)

    const ref = useRef()
    useEffect(() => {
        const pathName = props.location.pathname

        const initMenu = () => {
            new MetisMenu("#side-menu")
            let matchingMenuItem = null
            const ul = document.getElementById("side-menu")
            const items = ul.getElementsByTagName("a")

            for (let i = 0; i < items.length; ++i) {
                // if (pathName === items[i].pathname) {
                if (pathName.includes(items[i].pathname)) {
                    matchingMenuItem = items[i]
                    break
                }
                // reducing the complexity associated with managing deeply nested routes individually.
                else if (pathName.includes(APP_ROUTES.CONFIGURATOR)) {
                    matchingMenuItem = items[7]
                    break
                }
            }

            if (matchingMenuItem) {
                activateParentDropdown(matchingMenuItem)
            }
        }
        initMenu()
    }, [props.location.pathname])

    useEffect(() => {
        ref.current.recalculate()
    })

    function scrollElement(item) {
        if (item) {
            const currentPosition = item.offsetTop
            if (currentPosition > window.innerHeight) {
                ref.current.getScrollElement().scrollTop = currentPosition - 300
            }
        }
    }

    function activateParentDropdown(item) {
        item.classList.add("active")
        const parent = item.parentElement
        const parent2El = parent.childNodes[1]
        if (parent2El && parent2El.id !== "side-menu") {
            parent2El.classList.add("mm-show")
        }

        if (parent) {
            parent.classList.add("mm-active")
            const parent2 = parent.parentElement

            if (parent2) {
                parent2.classList.add("mm-show") // ul tag

                const parent3 = parent2.parentElement // li tag

                if (parent3) {
                    parent3.classList.add("mm-active") // li
                    parent3.childNodes[0].classList.add("mm-active") //a
                    const parent4 = parent3.parentElement // ul
                    if (parent4) {
                        parent4.classList.add("mm-show") // ul
                        const parent5 = parent4.parentElement
                        if (parent5) {
                            parent5.classList.add("mm-show") // li
                            parent5.childNodes[0].classList.add("mm-active") // a tag
                        }
                    }
                }
            }
            scrollElement(item)
            return false
        }
        scrollElement(item)
        return false
    }

    const sidebarContentToRender = sideBarContent.filter(obj =>
        obj.permissions.includes(userType)
    )

    return (
        <>
            <SimpleBar className="h-100" ref={ref}>
                <div id="sidebar-menu" className="gt-side-menu">
                    <ul className="metismenu list-unstyled" id="side-menu">
                        {sidebarContentToRender.map((item, idx) => (
                            <li
                                className={`menu-container text-center ${item.liClasses}`}
                                key={idx}
                            >
                                <Link
                                    to={item.routeName}
                                    className={`${item.routeClasses}`}
                                >
                                    <img
                                        src={item.image}
                                        className={`img-fluid cs-menu-icon ${item.imageClasses}`}
                                    />
                                    <span
                                        className={`font-size-13 text-center ${item.titleClasses}`}
                                    >
                                        {item.title}
                                    </span>
                                </Link>
                            </li>
                        ))}
                    </ul>
                </div>
            </SimpleBar>
        </>
    )
}

export default withRouter(withTranslation()(SidebarContent))
