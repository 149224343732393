import * as Yup from "yup"

export const initialValues = {
    GeneralSettings: {
        currentPassword: "",
        newPassword: "",
        confirmNewPassword: "",
    },
    TimerConfigSettings: {
        timeInDays: 0,
    },
}

export const generalSettings_validationSchema = Yup.object().shape({
    currentPassword: Yup.string().required("Enter Old Password"),
    newPassword: Yup.string().required("Enter New Password"),
    confirmNewPassword: Yup.string().oneOf(
        [Yup.ref("NewPassword"), null],
        'Must match "password" field value'
    ),
})

export const TimerConfigSettings_validationSchema = Yup.object().shape({
    timeInDays: Yup.number()
        .required("Enter no. of days")
        .min(1, "Can not be less than 1 day"),
})
