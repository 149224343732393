import React, { useState } from "react"
import { Pagination } from "components/Pagination"
import { DataTable } from "components/DataTable"
import { useLocalPaginatedRequest } from "hooks/useLocalPaginatedRequest"

import { AutoComplete } from "components/AutoComplete"
import { SearchBox } from "components/SearchBox"
import { Modal } from "components/Modal"
import OMViewDetailModal from "./OMDetailsViewModal"

import {
    dateTimeFormat,
    convertDateTime,
} from "../../../../utils/dateFormatUtils"
import { OrderRequestByOrderListing } from "api/api.service"

import customStyles from "../HeaderStatusStyling"
import { OrderManagementStatus } from "../../../../constants"
import ActionEyeIcon from "assets/images/icons/feather-eye-icon.png"
import { Refresh } from "components/Refresh"

const COLUMNS = {
    REQUEST_NO: "Request No.",
    NO_OF_ITEMS: "No. of Items",
    STATUS: "Status",
    DATE_REQUESTED: "Date Requested",
    MANIFEST_UPLOADED: "Manifest Uploaded",
    DATE_COMPLETED: "Date Completed",
    ACTIONS: "Action",
}

const dataKey = "data"
const totalKey = "total"

const OrderRequestByOrder = ({ sortBy }) => {
    const [modalState, setModalState] = useState(false)
    const [poDataList, setPODataList] = useState([])
    const [dataRefetch, setDataRefetch] = useState(false)
    const [filters, setFilters] = useState(null)
    const {
        data,
        isFetching,
        searchText,
        handleSearchChange,
        page,
        handlePageClick,
        pageSize,
        onChangePageSize,
        total,
        request,
    } = useLocalPaginatedRequest({
        requestFn: OrderRequestByOrderListing,
        params: {
            ...(filters?.Status && { requestStatus: filters?.Status }),
        },
        deps: [dataRefetch, filters],
        dataKey: dataKey,
        totalKey: totalKey,
    })

    const statusOptions =
        OrderManagementStatus &&
        Object.keys(OrderManagementStatus).map(el => {
            return {
                label: OrderManagementStatus.getDisplayTextKey(
                    OrderManagementStatus[el]
                ),
                value: OrderManagementStatus[el],
            }
        })
    const handleSelectOptionChange = val => {
        setFilters({ Status: val.value })
    }

    return (
        <>
            <div
            // className={`${
            //     sortBy ? "byorder-toggle-con" : ""
            // } fm-itemsorders-toggle-right-con`}
            >
                <div className="finance-orderbyorders-autocomplete">
                    <Refresh onClick={request} />
                    <div className="d-flex gap-2 flex-wrap">
                        <div className="status-search-con">
                            <AutoComplete
                                isSearchable={false}
                                placeholder="Select Status"
                                customStyles={customStyles.WarehouseIM}
                                classNamePrefix="fm-status-header-search"
                                options={statusOptions}
                                onChange={handleSelectOptionChange}
                            />
                        </div>
                        <div className="fm-search-input finance-byorders-search-ip">
                            <SearchBox
                                searchText={searchText}
                                onChange={handleSearchChange}
                                inputClasses="table-main-search-input fm-table-input"
                                labelClass="d-none"
                                placeholder="Search"
                                searchIcon={true}
                                searchIconClass="fm-table-search-icon"
                                conClass="fm-table-search-con"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <DataTable
                data={data}
                className="fm-table-con mt-3 "
                tableClasses="finance-om-byorder-table"
                loading={isFetching}
                config={[
                    {
                        title: COLUMNS.REQUEST_NO,
                        className: "black-color fm-ftwg-600",
                        render: data => data?.warehouseRequestId || "-",
                    },
                    {
                        title: COLUMNS.DATE_REQUESTED,
                        render: data => {
                            return convertDateTime({
                                date: data.createdAt,
                                customFormat:
                                    dateTimeFormat.casitaCustomDateFormat,
                                dateOnly: true,
                            })
                        },
                    },

                    {
                        title: COLUMNS.MANIFEST_UPLOADED,
                        render: data => (
                            <span
                            // className="clickable-link-text"
                            >
                                {data.manifestCount}
                            </span>
                        ),
                    },
                    {
                        title: COLUMNS.NO_OF_ITEMS,
                        render: data => data.orders.length,
                    },

                    {
                        title: COLUMNS.DATE_COMPLETED,
                        render: data => {
                            return data.requestStatus ==
                                OrderManagementStatus.COMPLETED
                                ? convertDateTime({
                                      date: data.updatedAt,
                                      customFormat:
                                          dateTimeFormat.casitaCustomDateFormat,
                                      dateOnly: true,
                                  })
                                : "-"
                        },
                    },
                    {
                        title: COLUMNS.STATUS,
                        render: data => {
                            return (
                                <div
                                    className={`fm-om-status ${OrderManagementStatus.getLabelClass(
                                        data?.requestStatus
                                    )}`}
                                >
                                    {OrderManagementStatus.getDisplayTextKey(
                                        data?.requestStatus
                                    )}
                                </div>
                            )
                        },
                    },
                    {
                        className: "text-center",
                        title: COLUMNS.ACTIONS,
                        render: data => {
                            return (
                                <span
                                    className="text-success pointer"
                                    onClick={() => {
                                        setPODataList(data)
                                        setModalState(true)
                                    }}
                                >
                                    <img src={ActionEyeIcon} />
                                </span>
                            )
                        },
                    },
                ]}
            />
            <Pagination
                currentPage={page}
                totalData={total}
                onPageClick={handlePageClick}
            />
            <Modal
                isOpen={modalState}
                handleModalToggling={setModalState}
                bodyClassName="p-0"
                customButton={true}
                hideModalHeaderSeparator={true}
                headerClasses="pb-0"
                className="inv-req-popup-viewdetails"
                sizeClasses="10"
            >
                <OMViewDetailModal
                    modalCloseHandler={() => setModalState(prev => !prev)}
                    data={poDataList}
                    title="View Request"
                    requestId={poDataList?.warehouseRequestId}
                    footerBtnType=""
                />
            </Modal>
        </>
    )
}

export default OrderRequestByOrder
