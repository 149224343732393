import React from "react"

import { DataTable } from "components/DataTable"

const COLUMNS = {
    SKU_NO: "SKU.No",
    ITEM_NAME: "Item Name",
    QUANTITY: "Quantity / Global Quantity",
}

const ItemListing = ({
    productStockData = {},
    isFetchingProductStock = false,
}) => {
    return (
        <>
            <div className="mb-3">
                <DataTable
                    loading={isFetchingProductStock}
                    data={productStockData.data}
                    className="bg-header-color mt-3 height-scroll-table w-100"
                    config={[
                        {
                            title: COLUMNS.SKU_NO,
                            render: data => data?.skuNo || "-",
                        },
                        {
                            title: COLUMNS.ITEM_NAME,
                            render: data => data?.itemName || "-",
                        },
                        {
                            title: COLUMNS.QUANTITY,
                            render: obj =>
                                (
                                    <div
                                        className={`wh-mang-global-quantity  ${productStockData.color}`}
                                    >
                                        {obj?.warehousequantity || "0"} /{" "}
                                        {obj?.globalquantity || "0"}
                                    </div>
                                ) || "-",
                        },
                    ]}
                />
            </div>
        </>
    )
}

export default ItemListing
