import { TrailerPublishStatus } from "../../../constants"
import * as Yup from "yup"

export const FIELDS_NAME = {
   // TYPE: "type",
   SIZE: "size",
   PUBLISH_DATE: "publishingDate",
   PUBLISH_STATUS: "publishStatus",
   // OFF_SET: "offset",
   PRICE: "price",
   //    NAME: "name",
   MODEL: "model",
   STATUS: "status",
   TRAILER_TYPE: "trailerType",
   YEAR: "year",
}

export const initialValues = {
   [FIELDS_NAME.MODEL]: [],
   //    [FIELDS_NAME.NAME]: "",
   [FIELDS_NAME.PRICE]: "",
   [FIELDS_NAME.SIZE]: [],
   [FIELDS_NAME.PUBLISH_DATE]: new Date(),
   [FIELDS_NAME.PUBLISH_STATUS]: TrailerPublishStatus.UnPublished,
   // [FIELDS_NAME.OFF_SET]: -(new Date().getTimezoneOffset() / 60),
   [FIELDS_NAME.TYPE]: "",
   // [FIELDS_NAME.TRAILER_TYPE]: null,
   [FIELDS_NAME.YEAR]: new Date(),
   [FIELDS_NAME.STATUS]: "ACTIVE",
}

export const validationSchema = Yup.object({
   [FIELDS_NAME.PRICE]: Yup.number("Enter numbers only")
      .required("Enter valid amount")
      .test("", "Price must be between 1 and 9 digits", item => {
         let num = item?.toString()?.length
         return num <= 9 && num >= 1
      }),
   //    [FIELDS_NAME.NAME]: Yup.string()
   //       .required("Enter trailer name")
   //       .min(6, "Enter trailer  name between 6 to 250 characters")
   //       .max(250, "Enter trailer name between 6 to 250 characters"),

   [FIELDS_NAME.MODEL]: Yup.array()
      .min(1, "At least one model must be selected")
      .required("Select model"),
   [FIELDS_NAME.STATUS]: Yup.string().required("Select status"),
   // [FIELDS_NAME.TYPE]: Yup.string().required("Select type"),
   [FIELDS_NAME.PUBLISH_DATE]: Yup.date("Select publish date").required(
      "Select publish date"
   ),
   [FIELDS_NAME.PUBLISH_STATUS]: Yup.string("Select publish status").required(
      "Select publish status"
   ),
   [FIELDS_NAME.YEAR]: Yup.date("Select year").required("Select year"),
   // [FIELDS_NAME.OFF_SET]: Yup.string().optional(),
   [FIELDS_NAME.SIZE]: Yup.array()
      .min(1, "At least one size must be selected")
      .required("Select size"),
   [FIELDS_NAME.TRAILER_TYPE]: Yup.object()
      .required("Select trailer type")
      .nullable("Select trailer type"),
})
