import React, { useState, useEffect, useRef } from "react"
import { Col, Row, Form, Input } from "reactstrap"
import { Field, useFormik } from "formik"
import toast from "react-hot-toast"
import moment from "moment"
import ReactDatePicker from "react-datepicker"

import { Modal } from "../../../components/Modal"
import { TextInput } from "../../../components/TextInput"
import { Button } from "../../../components/Button"
import FormikErrorText from "../../../components/FormikErrorText/FormikErrorText"
import ImageGallery from "../CommonUI/ImageGallery/ImageGallery"
import {
   GetTrailersDetails,
   CreateTrailer,
   UpdateTrailer,
   GeTrailersTypesListing,
} from "../../../api/api.service"
import {
   initializeMediaUtil,
   uploadOnS3,
   finalizeMediaUtil,
} from "../../../utils/mediaUtils"
import { FIELDS_NAME, initialValues, validationSchema } from "./FormConfig"
import ActiveInactiveSwitch from "../CommonUI/ActiveInactiveSwitch/ActiveInactiveSwitch"
import { convertToSelectOptions } from "utils/commonUtils"
import {
   TrailerPublishStatus,
   TrailerSize,
   TrailerStatus,
   TrailerType,
} from "../../../constants"
import TrailerModels from "constants/TrailerModels"
import FormSkeleton from "components/FormSkeleton/FormSkeleton"
import "react-datepicker/dist/react-datepicker.css"
import { AutoComplete } from "components/AutoComplete"
import useAsyncSelect from "hooks/useAsyncSelect"
import { AutoCompleteStyling } from "../../Common/AutoCompleteStyling"

const TITLES = {
   MODAL_ADD_TITLE: "Add new trailer",
   MODAL_UPDATE_TITLE: "Update trailer",
   MODAL_DETAILS_TITLE: "Trailer details",
   ADD_BTN_TEXT: "Add Trailer",
   UPDATE_BTN_TEXT: "Update Trailer",
   DETAILS_BTN_TEXT: "Done",
}

const handleStatusConversion = status => {
   return status == TrailerStatus.Active ? true : false
}

const handleUpdateExecution = async (id, data) => {
   let res = await UpdateTrailer(id, data)
   toast.success("Trailer has been updated successfully !")
}

const getPublishStatus = selectedDate => {
   if (moment(selectedDate).isSame(moment().format("YYYY-MM-DD"), "day")) {
      return TrailerPublishStatus.Published
   } else {
      return TrailerPublishStatus.UnPublished
   }
}

const ManageTrailer = ({
   isDetailView = false,
   isOpen = false,
   onToggleModal = () => {},
   onSuccess = () => {},
   id = "",
}) => {
   const imagesRef = useRef(null)

   const [isLoading, setIsLoading] = useState(false)
   const [status, setStatus] = useState(false)
   const [previousImages, setPreviousImages] = useState([])
   const [isFetching, setIsFetching] = useState(false)

   const formik = useFormik({
      enableReinitialze: true,
      initialValues: {
         ...initialValues,
      },
      validationSchema: validationSchema,
      onSubmit: values => {
         handleSubmit(values)
      },
   })

   const {
      setSelectedOption: setSelectedTrailerType,
      selectEntity: trailerTypeOptions,
      setSelectEntity: setTrailerTypeOptions,
      handleSearchOnOptions: handleSearchTrailerType,
      selectedOption: selectedTrailerType,
      handleSelectAOption: handleSelectATrailerType,
   } = useAsyncSelect({
      apiFunc: GeTrailersTypesListing,
      labelKey: "trailerType",
      valueKey: "id",
      params: {},
      onOptionSelect: option => {
         formik.setFieldValue([FIELDS_NAME.TRAILER_TYPE], option)
      },
   })

   useEffect(() => {
      handleFetchDetails()
      return () => {
         setIsLoading(false)
      }
   }, [id])

   const handleSubmit = values => {
      const pubDate = moment(values[FIELDS_NAME.PUBLISH_DATE])
      const bodyData = {
         //  name: values[FIELDS_NAME.NAME],
         model: values[FIELDS_NAME.MODEL],
         price: values[FIELDS_NAME.PRICE].toString(),
         //  configuratorTrailerType: values[FIELDS_NAME.TYPE],
         configuratorTrailerSizeType: values[FIELDS_NAME.SIZE],
         // offset: values[FIELDS_NAME.OFF_SET],
         publishingDate: pubDate,
         publishingStatus: getPublishStatus(pubDate),
         trailerYear: parseInt(moment(values[FIELDS_NAME.YEAR]).format("YYYY")),
         trailerTypeId: values[FIELDS_NAME.TRAILER_TYPE].value,
         status: values[FIELDS_NAME.STATUS],
      }

      if (id) {
         handleUpdate(bodyData)
      } else {
         handleAdd(bodyData)
      }
   }

   const uploadedMediaIDs = async images => {
      if (images) {
         try {
            let res = await handleUploadImages(images)
            return res?.length && res.map(item => item.id)
         } finally {
         }
      }
   }

   const handleUpdate = async data => {
      const payload = {
         ...data,
      }

      let userUploadedImages = imagesRef.current.getAllImages()

      if (!userUploadedImages.length && !previousImages.length) {
         return toast.error("Upload trailer images !")
      }

      try {
         setIsLoading(true)

         if (userUploadedImages.length) {
            uploadedMediaIDs(userUploadedImages).then(async res => {
               const apiFormatForImages = res.map(imageId => ({
                  mediaId: imageId,
               }))
               payload.images = [
                  ...(apiFormatForImages?.length && apiFormatForImages),
                  ...(previousImages?.length
                     ? previousImages.map(image => ({
                          mediaId: image.media.id,
                       }))
                     : []),
               ]

               await handleUpdateExecution(id, payload)
               handleModalClose()
               onSuccess()
            })
         } else {
            payload.images = [
               ...(previousImages?.length &&
                  previousImages.map(image => ({
                     mediaId: image.mediaId,
                  }))),
            ]

            await handleUpdateExecution(id, payload)
            handleModalClose()
            onSuccess()
         }
      } finally {
         setIsLoading(true)
      }
   }
   const handleAdd = async data => {
      let userUploadedImages = imagesRef.current.getAllImages()

      if (!userUploadedImages.length) {
         toast.error("Upload trailer images !")
         return
      }

      try {
         setIsLoading(true)
         let ids = await uploadedMediaIDs(userUploadedImages)
         let apiFormatForImages = ids.map(imageId => ({
            mediaId: imageId,
         }))
         data.images = [...(apiFormatForImages.length && apiFormatForImages)]

         let res = await CreateTrailer(data)
         toast.success("Trailer has been added successfully !")
         onSuccess()
         handleModalClose()
      } catch (error) {
      } finally {
         setIsLoading(false)
      }
   }

   const handleFetchDetails = async () => {
      if (!id) return

      try {
         setIsFetching(true)
         let res = await GetTrailersDetails(id)

         setSelectedTrailerType({
            label: res?.trailerType?.trailerType,
            value: res?.trailerType?.id,
         })

         formik.setValues({
            [FIELDS_NAME.MODEL]: res?.model,
            [FIELDS_NAME.PRICE]: res?.price,
            [FIELDS_NAME.PUBLISH_DATE]: moment(res?.publishingDate).format(
               "YYYY-MM-DD"
            ),
            [FIELDS_NAME.PUBLISH_STATUS]: res?.publishingStatus,
            [FIELDS_NAME.SIZE]: res?.configuratorTrailerSizeType,
            [FIELDS_NAME.STATUS]: res?.status,
            [FIELDS_NAME.TRAILER_TYPE]: {
               label: res?.trailerTypeId,
               value: res?.trailerTypeId,
            },
            [FIELDS_NAME.YEAR]: moment(res?.trailerYear, "YYYY").toISOString(),
         })
         setStatus(handleStatusConversion(res?.status))
         setPreviousImages(res?.images)
      } finally {
         setIsFetching(false)
      }
   }

   const handleUploadImages = async images => {
      let apiCallIns
      let uploadedIds = []

      apiCallIns = images.map(item => {
         return handleMediaUpload(item)
      })

      try {
         setIsLoading(true)
         uploadedIds = (await Promise.allSettled(apiCallIns))
            .filter(item => item.status === "fulfilled")
            .map(id => id.value)
      } finally {
         setIsLoading(false)
      }

      return uploadedIds
   }

   const handleMediaUpload = async file => {
      return initializeMediaUtil(file).then(async res => {
         const credentials = res
         await uploadOnS3(file, credentials, handleImageUploadProgress)
         return await finalizeMediaUtil(credentials?.mediaId)
      })
   }

   const handleImageUploadProgress = progress => {}

   const getOptions = (optionsEntity = {}) => {
      return convertToSelectOptions(optionsEntity).map((item, ind) => {
         return (
            <option value={item.value} key={ind}>
               {item.label}
            </option>
         )
      })
   }

   const handleModalClose = () => {
      // Use a short timeout to allow the reset to complete before toggling the modal
      setTimeout(() => {
         formik.resetForm() // Reset form first
         setSelectedTrailerType(null)
         setPreviousImages([]) // Clear any state data related to the form
         onToggleModal() // Close modal after reset completes
      }, 20)
   }

   const handleSetStatus = evt => {
      let { name } = evt.target
      let alteredStatus = !status
      setStatus(alteredStatus)
      formik.setFieldValue(
         name,
         alteredStatus ? TrailerStatus.Active : TrailerStatus.Inactive
      )
   }

   const handleCheckboxChange = (e, fieldName) => {
      const { value } = e.target
      formik.setFieldValue(fieldName, [value])
   }

   const { price, publishingDate, year } = formik.values

   // Handle year formatting consistently
   let formattedYear
   if (year instanceof Date) {
      // If year is already a Date object
      formattedYear = year.getFullYear() // Get the full year (YYYY)
   } else if (typeof year === "string") {
      // If year is an ISO string or another string format
      const parsedDate = new Date(year)
      // Check if parsed date is valid
      formattedYear = isNaN(parsedDate)
         ? "Invalid date"
         : parsedDate.getFullYear() // Extract the year
   } else {
      formattedYear = "Invalid date"
   }
   return (
      <Modal
         isOpen={isOpen}
         handleModalToggling={handleModalClose}
         bodyClassName=""
         customButton={true}
         hideModalHeaderSeparator={true}
         headerClasses="header-container"
         sizeClasses="8"
         backdrop="static"
         modalTitle={
            isDetailView
               ? TITLES.MODAL_DETAILS_TITLE
               : id
               ? TITLES.MODAL_UPDATE_TITLE
               : TITLES.MODAL_ADD_TITLE
         }
         scrollable
      >
         {isFetching ? (
            <FormSkeleton />
         ) : (
            <div style={{ pointerEvents: isLoading ? "none" : "auto" }}>
               <Row>
                  <Col xs={12}>
                     <Form onSubmit={formik.handleSubmit}>
                        <Row className="mb-3 align-items-center">
                           <label className=" col-md-3 col-form-label clr-theme-primary">
                              Trailer Type
                           </label>

                           <div className="col-md-9">
                              {isDetailView ? (
                                 <small>{selectedTrailerType?.label}</small>
                              ) : (
                                 <>
                                    <AutoComplete
                                       handleBlur={() =>
                                          setTrailerTypeOptions(prev => {
                                             return {
                                                ...prev,
                                                page: 1,
                                                Q: "",
                                             }
                                          })
                                       }
                                       onChange={handleSelectATrailerType}
                                       onInputChange={handleSearchTrailerType}
                                       placeholder="Search trailer types"
                                       classNamePrefix="add-new-req"
                                       customStyles={AutoCompleteStyling}
                                       options={trailerTypeOptions?.data}
                                       value={selectedTrailerType}
                                       isLoading={trailerTypeOptions?.fetching}
                                       name={FIELDS_NAME.TRAILER_TYPE}
                                    />
                                    <FormikErrorText
                                       formikInstance={formik}
                                       fieldName={FIELDS_NAME.TRAILER_TYPE}
                                    />
                                 </>
                              )}
                           </div>
                        </Row>

                        <Row className="mt-4 mb-3 align-items-center">
                           <label className=" col-md-3 col-form-label clr-theme-primary">
                              Price ($)
                           </label>
                           <div className="col-md-9">
                              {isDetailView ? (
                                 <small>{price}</small>
                              ) : (
                                 <>
                                    <TextInput
                                       type="number"
                                       min={0}
                                       step="0.000001"
                                       inputClass={
                                          isDetailView && "input-readonly"
                                       }
                                       placeholder="Enter trailer price"
                                       name={FIELDS_NAME.PRICE}
                                       {...formik.getFieldProps(
                                          FIELDS_NAME.PRICE
                                       )}
                                    />

                                    <FormikErrorText
                                       formikInstance={formik}
                                       fieldName={FIELDS_NAME.PRICE}
                                    />
                                 </>
                              )}
                           </div>
                        </Row>

                        <Row className="mt-4 mb-3 align-items-center">
                           <label className=" col-md-3 col-form-label clr-theme-primary">
                              Publish Date
                           </label>
                           <div className="col-md-9">
                              {isDetailView ? (
                                 <small>
                                    {/* Format the publishingDate to a readable format */}
                                    {publishingDate instanceof Date
                                       ? publishingDate.toLocaleDateString()
                                       : publishingDate}
                                 </small>
                              ) : (
                                 <>
                                    <ReactDatePicker
                                       withPortal
                                       dateFormat={"yyyy-MM-dd"}
                                       showTimeInput={false}
                                       selected={
                                          new Date(
                                             formik.values[
                                                FIELDS_NAME.PUBLISH_DATE
                                             ]
                                          )
                                       }
                                       placeholderText="Select publish date"
                                       minDate={new Date()}
                                       name={FIELDS_NAME.PUBLISH_DATE}
                                       onChange={date =>
                                          formik.setFieldValue(
                                             FIELDS_NAME.PUBLISH_DATE,
                                             date
                                          )
                                       }
                                       className={`custom-date-picker ${
                                          isDetailView && "input-readonly"
                                       }`}
                                    />

                                    <FormikErrorText
                                       formikInstance={formik}
                                       fieldName={FIELDS_NAME.PUBLISH_DATE}
                                    />
                                 </>
                              )}
                           </div>
                        </Row>

                        <Row className="mt-4 mb-3 align-items-center">
                           <label className=" col-md-3 col-form-label clr-theme-primary">
                              Year
                           </label>
                           <div className="col-md-9">
                              {isDetailView ? (
                                 <small>{formattedYear}</small>
                              ) : (
                                 <>
                                    <ReactDatePicker
                                       showYearPicker
                                       withPortal
                                       dateFormat={"yyyy"}
                                       selected={
                                          new Date(
                                             formik.values[FIELDS_NAME.YEAR]
                                          )
                                       }
                                       placeholderText="Select year"
                                       name={FIELDS_NAME.YEAR}
                                       onChange={year => {
                                          formik.setFieldValue(
                                             FIELDS_NAME.YEAR,
                                             year
                                          )
                                       }}
                                       className={`custom-date-picker ${
                                          isDetailView && "input-readonly"
                                       }`}
                                       showTimeInput={false}
                                    />

                                    <FormikErrorText
                                       formikInstance={formik}
                                       fieldName={FIELDS_NAME.YEAR}
                                    />
                                 </>
                              )}
                           </div>
                        </Row>

                        {/* <Row className="mt-4 mb-3 align-items-center">
                           <label className=" col-md-3 col-form-label clr-theme-primary">
                              Model
                           </label>

                           <div className="col-md-9">
                              <div className="d-flex">
                                 <Input
                                    type="checkbox"
                                    name={FIELDS_NAME.MODEL}
                                    value={TrailerModels.Standard}
                                    checked={formik.values[
                                       FIELDS_NAME.MODEL
                                    ].includes(TrailerModels.Standard)}
                                    onClick={e =>
                                       handleCheckboxChange(
                                          e,
                                          FIELDS_NAME.MODEL
                                       )
                                    }
                                    className={
                                       isDetailView &&
                                       "input-readonly-checkboxes"
                                    }
                                 />

                                 <span className="mx-2">
                                    {TrailerModels.getDisplayTextKey(
                                       TrailerModels.Standard
                                    )}
                                 </span>

                                 <Input
                                    type="checkbox"
                                    name={FIELDS_NAME.MODEL}
                                    value={TrailerModels.Deluxe}
                                    checked={formik.values[
                                       FIELDS_NAME.MODEL
                                    ].includes(TrailerModels.Deluxe)}
                                    onClick={e =>
                                       handleCheckboxChange(
                                          e,
                                          FIELDS_NAME.MODEL
                                       )
                                    }
                                    className={
                                       isDetailView &&
                                       "input-readonly-checkboxes"
                                    }
                                 />

                                 <span className="mx-2">
                                    {TrailerModels.getDisplayTextKey(
                                       TrailerModels.Deluxe
                                    )}
                                 </span>
                              </div>

                              <FormikErrorText
                                 formikInstance={formik}
                                 fieldName={FIELDS_NAME.MODEL}
                              />
                           </div>
                        </Row> */}

                        <Row className="mt-4 mb-3 align-items-center">
                           <label className="col-md-3 col-form-label clr-theme-primary">
                              Model
                           </label>

                           <div className="col-md-9">
                              <div className="d-flex">
                                 <Input
                                    type="checkbox"
                                    name={FIELDS_NAME.MODEL}
                                    value={TrailerModels.Standard}
                                    checked={
                                       formik.values[FIELDS_NAME.MODEL][0] ===
                                       TrailerModels.Standard
                                    }
                                    onClick={e =>
                                       handleCheckboxChange(
                                          e,
                                          FIELDS_NAME.MODEL
                                       )
                                    }
                                    className={
                                       isDetailView &&
                                       "input-readonly-checkboxes"
                                    }
                                 />
                                 <span className="mx-2">
                                    {TrailerModels.getDisplayTextKey(
                                       TrailerModels.Standard
                                    )}
                                 </span>

                                 <Input
                                    type="checkbox"
                                    name={FIELDS_NAME.MODEL}
                                    value={TrailerModels.Deluxe}
                                    checked={
                                       formik.values[FIELDS_NAME.MODEL][0] ==
                                       TrailerModels.Deluxe
                                    }
                                    onClick={e =>
                                       handleCheckboxChange(
                                          e,
                                          FIELDS_NAME.MODEL
                                       )
                                    }
                                    className={
                                       isDetailView &&
                                       "input-readonly-checkboxes"
                                    }
                                 />
                                 <span className="mx-2">
                                    {TrailerModels.getDisplayTextKey(
                                       TrailerModels.Deluxe
                                    )}
                                 </span>
                              </div>

                              <FormikErrorText
                                 formikInstance={formik}
                                 fieldName={FIELDS_NAME.MODEL}
                              />
                           </div>
                        </Row>

                        {/* <Row className="mt-4 mb-3 align-items-center">
                           <label className=" col-md-3 col-form-label clr-theme-primary">
                              Size
                           </label>
                           <div className="col-md-9">
                              <div className="d-flex">
                                 <Input
                                    type="checkbox"
                                    name={FIELDS_NAME.SIZE}
                                    value={TrailerSize.S15}
                                    checked={formik.values[
                                       FIELDS_NAME.SIZE
                                    ].includes(TrailerSize.S15)}
                                    onClick={e =>
                                       handleCheckboxChange(e, FIELDS_NAME.SIZE)
                                    }
                                    className={
                                       isDetailView &&
                                       "input-readonly-checkboxes"
                                    }
                                 />

                                 <span className="mx-2">
                                    {TrailerSize.getDisplayTextKey(
                                       TrailerSize.S15
                                    )}
                                 </span>

                                 <Input
                                    type="checkbox"
                                    name={FIELDS_NAME.SIZE}
                                    value={TrailerSize.S17}
                                    checked={formik.values[
                                       FIELDS_NAME.SIZE
                                    ].includes(TrailerSize.S17)}
                                    onClick={e =>
                                       handleCheckboxChange(e, FIELDS_NAME.SIZE)
                                    }
                                    className={
                                       isDetailView &&
                                       "input-readonly-checkboxes"
                                    }
                                 />

                                 <span className="mx-2">
                                    {TrailerSize.getDisplayTextKey(
                                       TrailerSize.S17
                                    )}
                                 </span>
                              </div>

                              <FormikErrorText
                                 formikInstance={formik}
                                 fieldName={FIELDS_NAME.SIZE}
                              />
                           </div>
                        </Row> */}

                        <Row className="mt-4 mb-3 align-items-center">
                           <label className="col-md-3 col-form-label clr-theme-primary">
                              Size
                           </label>
                           <div className="col-md-9">
                              <div className="d-flex">
                                 <Input
                                    type="checkbox"
                                    name={FIELDS_NAME.SIZE}
                                    value={TrailerSize.S15}
                                    checked={
                                       formik.values[FIELDS_NAME.SIZE][0] ===
                                       TrailerSize.S15
                                    }
                                    onClick={e =>
                                       handleCheckboxChange(e, FIELDS_NAME.SIZE)
                                    }
                                    className={
                                       isDetailView &&
                                       "input-readonly-checkboxes"
                                    }
                                 />
                                 <span className="mx-2">
                                    {TrailerSize.getDisplayTextKey(
                                       TrailerSize.S15
                                    )}
                                 </span>

                                 <Input
                                    type="checkbox"
                                    name={FIELDS_NAME.SIZE}
                                    value={TrailerSize.S17}
                                    checked={
                                       formik.values[FIELDS_NAME.SIZE][0] ===
                                       TrailerSize.S17
                                    }
                                    onClick={e =>
                                       handleCheckboxChange(e, FIELDS_NAME.SIZE)
                                    }
                                    className={
                                       isDetailView &&
                                       "input-readonly-checkboxes"
                                    }
                                 />
                                 <span className="mx-2">
                                    {TrailerSize.getDisplayTextKey(
                                       TrailerSize.S17
                                    )}
                                 </span>
                              </div>

                              <FormikErrorText
                                 formikInstance={formik}
                                 fieldName={FIELDS_NAME.SIZE}
                              />
                           </div>
                        </Row>

                        {/* <Row className="mt-4 mb-3 align-items-center">
                           <label className=" col-md-3 col-form-label clr-theme-primary">
                              Trailer Type
                           </label>
                           <div className="col-md-9">
                              <Input
                                 className={
                                    isDetailView && "input-readonly-select"
                                 }
                                 type="select"
                                 name={FIELDS_NAME.TYPE}
                                 {...formik.getFieldProps(FIELDS_NAME.TYPE)}
                              >
                                 {getOptions(TrailerType)}
                              </Input>

                              <FormikErrorText
                                 formikInstance={formik}
                                 fieldName={FIELDS_NAME.TYPE}
                              />
                           </div>
                        </Row> */}

                        <Row className="mt-4 mb-3 align-items-center">
                           <label className=" col-md-3 col-form-label clr-theme-primary">
                              Upload Images
                           </label>
                           <div className="col-md-9">
                              <ImageGallery
                                 ref={imagesRef}
                                 previousImages={previousImages}
                                 removePreviousImages={setPreviousImages}
                                 previewOnly={isDetailView}
                                 isDetailView={isDetailView}
                              />
                           </div>
                        </Row>

                        <Row className="mb-3 align-items-center">
                           <label className=" col-md-3 col-form-label clr-theme-primary">
                              Status
                           </label>
                           <div className="col-md-9">
                              <ActiveInactiveSwitch
                                 isChecked={status}
                                 isDisable={isDetailView}
                                 name={FIELDS_NAME.STATUS}
                                 onChange={handleSetStatus}
                              />
                              <FormikErrorText
                                 formikInstance={formik}
                                 fieldName={FIELDS_NAME.STATUS}
                              />
                           </div>
                        </Row>

                        <div className="d-flex justify-content-center mt-3 mt-2">
                           <Button
                              isLoading={isLoading}
                              type={isDetailView ? "button" : "submit"}
                              title={
                                 isDetailView
                                    ? TITLES.DETAILS_BTN_TEXT
                                    : id
                                    ? TITLES.UPDATE_BTN_TEXT
                                    : TITLES.ADD_BTN_TEXT
                              }
                              className="header-button"
                              onClick={isDetailView && handleModalClose}
                           />
                        </div>
                     </Form>
                  </Col>
               </Row>
            </div>
         )}
      </Modal>
   )
}

export default React.memo(ManageTrailer)
