import React, { useState } from "react"

//redux
import {  useDispatch } from "react-redux"

import logoImage from "../../assets/images/signin/Casita-WebLogo.png"

import { withRouter, Route, Switch } from "react-router-dom"

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"
import { APP_ROUTES } from "../../helpers/routeHelpers"

// import images
import { login } from "store/actions/authActions"
import { withAuthentication } from "hoc"
import Login from "./Login"
import ForgetPassword from "./ForgetPassword"
import ForgetVerifyCode from "./ForgetVerifyCode"
import ResetPassword from "./ResetPassword"

const Auth = props => {
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(false)

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .trim()
        .email("Please enter a correct email address")
        .required("Please enter your email address"),
      password: Yup.string().trim().required("Please enter your password"),
    }),
    onSubmit: async ({ email, password }) => {
      try {
        setLoading(true)
        const res = await dispatch(
          login({
            Email: email,
            Password: password,
            Type: "admin",
          })
        )
      } catch (err) {
      } finally {
        setLoading(false)
      }
    },
  })

  return (
    <div className="px-0 " >
      <div className="limiter">
        <div className="container-login100 ">
          <div className="left-img-container login100-more d-none d-sm-block">
            <div className="mx-auto p-5 left-img-inner-container">
              <div className="left-img">
                <img src={logoImage} />
              </div>
            </div> 
          </div>
          <Switch>
            <Route exact path={APP_ROUTES.LOGIN}>
              <Login />
            </Route>
            <Route exact path={APP_ROUTES.FORGET_PASSWORD}>
              <ForgetPassword />
            </Route>
            <Route exact path={APP_ROUTES.FORGET_VERIFY_CODE}>
              <ForgetVerifyCode />
            </Route>
            <Route exact path={APP_ROUTES.RESET_PASSWORD}>
              <ResetPassword />
            </Route>
          </Switch>
        </div>
      </div>
    </div>
  )
}

export default withAuthentication(withRouter(Auth))
