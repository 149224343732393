import * as Yup from "yup"

export const FIELDS_NAME = {
   ITEM: "item",
   NAME: "name",
   PRICE: "price",
   DESCRIPTION: "description",
   STATUS: "status",
   QUANTITY: "quantity",
   STATION_INVOLVED: "stationInvolved",
   ENHANCEMENT_TYPE: "enhancementType",
}

export const ENHANCEMENT_TYPES = {
   Addition: "Addition",
   Replacement: "Replacement",
}

export const VARIATIONS = {
   Yes: "Yes",
   No: "No",
}

export const ADD_NOTE = {
   Yes: "Yes",
   No: "No",
}

export const initialValues = {
   [FIELDS_NAME.DESCRIPTION]: "",
   [FIELDS_NAME.PRICE]: "",
   [FIELDS_NAME.NAME]: "",
   [FIELDS_NAME.STATUS]: "ACTIVE",
}

export const packageItemInitialValues = {
   [FIELDS_NAME.ITEM]: null,
   [FIELDS_NAME.PRICE]: "",
   [FIELDS_NAME.QUANTITY]: "",
   [FIELDS_NAME.STATUS]: "ACTIVE",
   [FIELDS_NAME.STATION_INVOLVED]: null,
   [FIELDS_NAME.ENHANCEMENT_TYPE]: ENHANCEMENT_TYPES.Addition,
}

export const validationSchema = Yup.object({
   [FIELDS_NAME.NAME]: Yup.string().required("Enter item name"),
   [FIELDS_NAME.DESCRIPTION]: Yup.string().required("Enter item description"),
   [FIELDS_NAME.STATUS]: Yup.string().required("Select status"),
   [FIELDS_NAME.PRICE]: Yup.number("Enter numbers only")
      .required("Enter valid price")
      .test("", "Price must be between 1 and 9 numbers", item => {
         let num = item?.toString()?.length
         return num <= 9 && num >= 1
      }),
})

export const packageItemValidationSchema = Yup.object({
   [FIELDS_NAME.ITEM]: Yup.object()
      .required("Select item")
      .nullable("Select valid item"),
   [FIELDS_NAME.STATION_INVOLVED]: Yup.object()
      .required("Select station")
      .nullable("Select valid station"),
   [FIELDS_NAME.QUANTITY]: Yup.number("Enter quantity").required(),
   [FIELDS_NAME.PRICE]: Yup.number("Enter numbers only")
      .required("Enter valid price")
      .test("", "Price must be between 1 and 9 numbers", item => {
         let num = item?.toString()?.length
         return num <= 9 && num >= 1
      }),
   [FIELDS_NAME.STATUS]: Yup.string().required("Select status"),
})
