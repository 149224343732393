import React from "react"
import { CardBody, Card, Row, Col } from "reactstrap"
import { useParams } from "react-router-dom"

import { DataTable } from "components/DataTable"
import { Pagination } from "components/Pagination"
import { useLocalPaginatedRequest } from "hooks/useLocalPaginatedRequest"
import { VendorItemsDetailsById } from "api/api.service"
import VendorHeader from "./VendorHeader"
import { BackButtonTableTop } from "components/BackButtonTableTop"

const DATA_KEY = "data"
const TOTAL_KEY = "total"

const COLUMNS = {
    S_NO: "SNo.",
    SKU_NO: "SKU No.",
    ITEM_NAME: "Item Name",
}

const VendorDetails = () => {
    const { vendorId } = useParams()

    const {
        page,
        handlePageClick,
        pageSize,
        onChangePageSize,
        data,
        total,
        isFetching,
        request,
        wholeResponse,
        searchText,
        handleSearchChange,
    } = useLocalPaginatedRequest({
        requestFn: VendorItemsDetailsById,
        id: vendorId,
        params: {},
        deps: [],
        dataKey: DATA_KEY,
        totalKey: TOTAL_KEY,
    })

    return (
        <>
            <div className="d-flex justify-content-start my-4">
                <BackButtonTableTop />
            </div>
            <Card className="mb-5">
                <CardBody className="p-2 p-md-4 wh-im-wh-gi-con">
                    <Row>
                        <Col className="col-12 my-2">
                            <VendorHeader
                                headerTitle={wholeResponse.vendorName}
                                subTitle={wholeResponse.contactNo}
                                btnTitle="Add Vendor"
                                headerButtonResponsive="headerButtonResponsive"
                                headerSearchResponsiveness="header-search-responsiveness"
                                onChangeHandler={handleSearchChange}
                                searchText={searchText}
                                adFinanceSearchBoxContanier="ad-finance-search-box-contanier"
                                adFinanceVendorContanier="ad-finance-vendor-contanier"
                                VendorbuttonSearchResponsiveness="vendor-button-search-responsiveness"
                            />
                        </Col>
                        <Col md={12}>
                            <DataTable
                                data={data}
                                tableClasses="finance-inventory-management-department-general-inventory-table"
                                loading={isFetching}
                                config={[
                                    {
                                        title: COLUMNS.S_NO,
                                        render: data => {
                                            return <>{data?.id}</>
                                        },
                                    },
                                    {
                                        title: COLUMNS.SKU_NO,
                                        render: data => {
                                            return <>{data?.skuNo}</>
                                        },
                                    },
                                    {
                                        title: COLUMNS.ITEM_NAME,
                                        render: data => {
                                            return data?.itemName
                                        },
                                    },
                                ]}
                            />
                            <Pagination
                                currentPage={page}
                                totalData={total}
                                onPageClick={handlePageClick}
                                rowLimit={pageSize}
                            />
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </>
    )
}

export default VendorDetails
