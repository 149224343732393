import * as Yup from "yup"
import { RegexPhoneNumber } from "utils/regexUtils"

export const initialValues = {
  fullName: "",
  contactNumber: "",
}

export const validationSchema = Yup.object({
  fullName: Yup.string()
    // .required()
    .required("Enter Full Name")
    .min(1, "Name should be minimum 1 characters long"),
  // .max(25, "Name should be between 6 to 25 characters"),
  contactNumber: Yup.string()
    .required("Enter Contact Number")
    .matches(RegexPhoneNumber, "Phone number is not valid")
    .min(6, "Too short")
    .max(20, "Too long"),
})
