import React, { useState } from "react"
import { Col, Row } from "reactstrap"

import { DataTable } from "components/DataTable"
import { useLocalPaginatedRequest } from "hooks/useLocalPaginatedRequest"
import { Pagination } from "components/Pagination"
import { GetOrderedTrailersListing } from "api/api.service"
import ActionEyeIcon from "assets/images/icons/feather-eye-icon.png"
import { Modal } from "components/Modal"
import TrailerOrderDetails from "./TrailerOrderDetails"
import { convertDateTime, dateTimeFormat } from "utils/dateFormatUtils"
import { SearchBox } from "components/SearchBox"

const COLUMNS = {
    TRAILER_NAME: "Trailer Name",
    TRAILER_SIZE_MODEL: "Size/Model",
    CUSTOMER_NAME: "Customer Name",
    CUSTOMER_PHONE: "Phone",
    CUSTOMER_EMAIL: "Email",
    ORDER_DATE: "Order Date",
    ACTIONS: "Actions",
}

const DATA_KEY = "data"
const TOTAL_KEY = "total"

const TrailerOrdersListing = ({}) => {
    const [isOpen, setIsOpen] = useState(false)
    const [selectedTrailerOrder, setSelectedTrailerOrder] = useState({})
    const [filters, setFilters] = useState("")
    const {
        data,
        isFetching,
        page,
        searchText,
        pageSize,
        total,
        handleSearchChange,
        request,
        handlePageClick,
        onChangePageSize,
    } = useLocalPaginatedRequest({
        requestFn: GetOrderedTrailersListing,
        params: {
            ...(filters?.Status && { Status: filters?.Status }),
        },
        deps: [filters],
        dataKey: DATA_KEY,
        totalKey: TOTAL_KEY,
    })

    const handleViewTrailerDetails = obj => {
        setSelectedTrailerOrder(obj)
        setIsOpen(prev => !prev)
    }

    return (
        <>
            <div className="mb-3">
                <div className="d-flex align-items-center justify-content-between admin-inventory-management-general-inventory-responsiveness ">
                    <div className="  me-2 ">
                        <h1 className="header-title mb-0 w-100">
                            Configurator Orders
                        </h1>
                    </div>
                    <div className="ad-location-search-box">
                        <SearchBox
                            inputClasses="vendor-search-input input-search"
                            labelClass="d-none"
                            placeholder="Search"
                            searchIcon={true}
                            searchIconClass="vendor-table-search-icon"
                            conClass="vendor-table-search-inner-class"
                            searchText={searchText}
                            onChange={handleSearchChange}
                        />
                    </div>
                </div>
            </div>
            <Row>
                <Col md={12}>
                    <DataTable
                        data={data}
                        tableClasses="vendor-management-table"
                        loading={isFetching}
                        className="vendor-data-container mt-3"
                        config={[
                            {
                                title: COLUMNS.CUSTOMER_NAME,
                                className: "",
                                render: data =>
                                    `${data?.customer?.firstName} ${data?.customer?.lastName}` ||
                                    "-",
                            },

                            {
                                title: COLUMNS.CUSTOMER_EMAIL,
                                className: "",
                                render: data => data?.customer.email || "-",
                            },

                            {
                                title: COLUMNS.CUSTOMER_PHONE,
                                className: "",
                                render: data => data?.customer?.phoneNo || "-",
                            },

                            {
                                title: COLUMNS.TRAILER_NAME,
                                className: "",
                                render: data =>
                                    data?.configuratorTrailer?.name || "-",
                            },

                            {
                                title: COLUMNS.TRAILER_SIZE_MODEL,
                                className: "",
                                render: data =>
                                    data?.note?.selectSize?.label || "-",
                            },

                            {
                                title: COLUMNS.ORDER_DATE,
                                className: "",
                                render: data => {
                                    return convertDateTime({
                                        date: data.createdAt,
                                        customFormat:
                                            dateTimeFormat.casitaCustomDateFormat,
                                        dateOnly: true,
                                    })
                                },
                            },

                            {
                                title: COLUMNS.ACTIONS,

                                render: data => (
                                    <div className="d-flex gap-2 pointer">
                                        <img
                                            src={ActionEyeIcon}
                                            onClick={() =>
                                                handleViewTrailerDetails(data)
                                            }
                                        />
                                    </div>
                                ),
                            },
                        ]}
                    />
                </Col>
                <Pagination
                    currentPage={page}
                    totalData={total}
                    onPageClick={handlePageClick}
                    // className="pagination-padding"
                />
            </Row>

            <Modal
                isOpen={isOpen}
                handleModalToggling={() => setIsOpen(prev => !prev)}
                bodyClassName="p-0"
                customButton={true}
                hideModalHeaderSeparator={true}
                headerClasses="header-container"
                size="lg"
                backdrop="static"
                modalTitle={"Order Details"}
            >
                <TrailerOrderDetails data={selectedTrailerOrder} />
            </Modal>
        </>
    )
}

export default TrailerOrdersListing
