import React, { useEffect } from "react"
import { Col } from "reactstrap"

import { Button } from "components/Button"
import { SearchBox } from "components/SearchBox"

const Header = ({
    headerTitle,
    onClick = () => {},
    btnTitle = "ADD",
    searchText = "",
    onChangeHandler = () => {},
    showButtonSearch = true,
    showButton = false,
    flexResponsive = "",
    searchResponsiveness = "",
    flexResponsiveInner = "",
    headerBtnResponsive = "",
    SearchBoxContainer = "",
}) => {
    useEffect(() => {}, [showButton])
    return (
        <>
            <div
                className={`d-flex justify-content-lg-between justify-content-between   ${flexResponsive}`}
            >
                <h1 className="header-title">{headerTitle}</h1>

                {showButtonSearch && (
                    <div
                        className={`d-flex  flex-md-row justify-content-between ${flexResponsiveInner}`}
                    >
                        {showButton && (
                            <Button
                                title={btnTitle}
                                className={`header-button me-0 me-md-2 ${headerBtnResponsive}`}
                                onClick={onClick}
                            />
                        )}
                        <div
                            className={`location-search-box ${SearchBoxContainer}`}
                        >
                            <SearchBox
                                inputClasses="vendor-search-input input-search"
                                labelClass="d-none"
                                placeholder="Search"
                                searchIcon={true}
                                searchIconClass="vendor-table-search-icon "
                                conClass={`vendor-table-search-inner-class ${searchResponsiveness} `}
                                searchText={searchText}
                                onChange={onChangeHandler}
                            />
                        </div>
                    </div>
                )}
            </div>
        </>
    )
}

export default Header
