import React from "react"

import crossIcon from "../../../../assets/images/cross-red.png"
import "./PreviewImage.scss"

const PreviewImage = props => {
    let {
        containerClasses,
        crossClasses,
        handleRemoveImage,
        imageToPreview,
        imageClasses,
        hideCross = false,
        onClick,
        isDetailView,
    } = props
    return (
        <div className={`preview-container ${containerClasses}`}>
            {/* Cross Button  */}
            {!hideCross && (
                <img
                    className={`cross-button ${crossClasses}`}
                    src={crossIcon}
                    onClick={handleRemoveImage}
                />
            )}
            {/* Image  */}
            <img
                src={imageToPreview}
                className={`${
                    isDetailView && "pointer"
                } preview-image rounded  ${imageClasses}`}
                onClick={() => onClick(imageToPreview)}
            />
        </div>
    )
}

export default PreviewImage
