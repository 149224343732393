import { injectBaseConstantMethods } from "./BaseConstants"

const ConfiguratorStatus = {
   Active: "ACTIVE",
   Inactive: "INACTIVE",
}

const displayTextKeys = {
   [ConfiguratorStatus.Active]: "Active",
   [ConfiguratorStatus.Inactive]: "Inactive",
}

const labelClass = {
   [ConfiguratorStatus.Active]: "configurator-active",
   [ConfiguratorStatus.Inactive]: "configurator-inactive",
}

export default injectBaseConstantMethods(
   ConfiguratorStatus,
   displayTextKeys,
   labelClass
)
