import React, { useEffect, useState } from "react"

import { DataTable } from "components/DataTable"
import delIcon from "assets/images/icons/finance-vendor-mang-del-icon.svg"
import cautionIcon from "assets/images/icons/caution-icon.svg"
import greenCheckIcon from "assets/images/icons/green-check-icon.svg"
import { Modal } from "components/Modal"
import BuildDepartmentItems from "./BuildDepartmentItems"
import DeleteModal from "components/Common/DeleteModal"

const COLUMNS = {
   ID: "Id",
   DEPARTMENT: "Station",
   STAGE: "Stage",
   INVENTORY: "Inventory",
   TIME: "Time (hr)",
   ACTION: "Action",
}

const BuildDepartments = ({
   isDetailView = null,
   isFetching,
   data = [],
   mutateDepartment = () => {},
}) => {
   const [selectedDepartment, setSelectedDepartment] = useState({})
   const [currentActionKey, setCurrentActionKey] = useState(null)

   const [actionStates, setActionStates] = useState({
      isAddingInventory: null,
      isEditDepartment: null,
      isDeleteModal: null,
   })

   const handleDeleteModal = data => {
      setCurrentActionKey(MODAL_ACTIONS.DELETE)
      setActionStates(prev => ({ ...prev, isDeleteModal: true }))
      setSelectedDepartment(data)
   }

   const handleSetDepartmentSelection = obj => {
      handleAddInventory()
      setSelectedDepartment(obj)
   }

   const MODAL_ACTIONS = {
      ADD: "1",
      DELETE: "2",
   }

   let currentAction = {
      [MODAL_ACTIONS.ADD]: {
         title: "Add Items",
         btnText: "Add Item",
      },

      [MODAL_ACTIONS.DELETE]: {
         title: "Delete Item",
         btnText: "Delete Item",
         value: "item",
      },
   }

   const handleCloseModal = () => {
      setActionStates({
         isAddingInventory: false,
         isDeleteModal: false,
      })
      setCurrentActionKey(null)
      setSelectedDepartment({})
   }

   const handleAddInventory = () => {
      setCurrentActionKey(MODAL_ACTIONS.ADD)
      setActionStates(prev => ({ ...prev, isAddingInventory: true }))
   }
   const handleUpdateSelectedDepartmentItems = obj => {
      setSelectedDepartment(obj)

      let cloneOfDepartment = data.find(item => item.id == obj.id)
      cloneOfDepartment = { ...obj }

      mutateDepartment(prev => ({ ...prev, [obj.id]: cloneOfDepartment }))
      handleCloseModal()
   }

   const handleDeleteInit = obj => {
      setSelectedDepartment(obj)
      setCurrentActionKey(MODAL_ACTIONS.DELETE)
      setActionStates(prev => ({ ...prev, isDeleteModal: true }))
   }

   const handleDelete = obj => {
      let cloneOfDepartments = data.filter(item => item.id != obj.id)

      mutateDepartment(prev => ({ ...cloneOfDepartments }))
      handleCloseModal()
   }

   return (
      <>
         <DataTable
            data={data}
            loading={isFetching}
            tableClasses="ad-tool-inventory-table my-4"
            config={[
               {
                  title: COLUMNS.ID,
                  render: data => {
                     return <>{data?.id}</>
                  },
               },

               {
                  title: COLUMNS.DEPARTMENT,
                  render: data => {
                     return <>{data?.departmentName}</>
                  },
               },

               {
                  title: COLUMNS.STAGE,
                  render: data => {
                     return <>{data?.stage || "-"}</>
                  },
               },

               {
                  title: COLUMNS.TIME,
                  render: data => {
                     return <>{data?.productionTime || "-"}</>
                  },
               },

               {
                  title: COLUMNS.INVENTORY,
                  render: data => (
                     <>
                        <span className="">
                           {data?.items?.length ? (
                              <div className="pointer">
                                 <img src={greenCheckIcon} />
                                 &nbsp;
                                 <strong
                                    onClick={() =>
                                       handleSetDepartmentSelection(data)
                                    }
                                 >
                                    {isDetailView
                                       ? "View Inventory"
                                       : "Edit Inventory"}
                                 </strong>
                              </div>
                           ) : (
                              <div className="pointer">
                                 <img src={cautionIcon} />
                                 &nbsp;
                                 <strong
                                    onClick={() =>
                                       handleSetDepartmentSelection(data)
                                    }
                                 >
                                    Add Inventory
                                 </strong>
                              </div>
                           )}
                        </span>
                     </>
                  ),
               },

               {
                  title: COLUMNS.ACTION,
                  render: data => (
                     <>
                        <span
                           style={{
                              pointerEvents: isDetailView ? "none" : "default",
                              opacity: isDetailView ? "0.2" : "1",
                           }}
                           className="text-success pointer"
                           onClick={() => handleDelete(data)}
                        >
                           <img src={delIcon} />
                        </span>
                     </>
                  ),
               },
            ]}
         />

         <Modal
            isOpen={!!currentAction[currentActionKey]}
            handleModalToggling={handleCloseModal}
            bodyClassName="p-0"
            customButton={true}
            hideModalHeaderSeparator={true}
            headerClasses="modal-header-container"
            sizeClasses={actionStates.isAddingInventory ? "10" : "2"}
            modalTitle={currentAction[currentActionKey]?.title}
         >
            {actionStates.isAddingInventory && (
               <BuildDepartmentItems
                  isDetailView={isDetailView}
                  handleModalToggling={handleCloseModal}
                  mutateDepartment={handleUpdateSelectedDepartmentItems}
                  modalCloseHandler={handleCloseModal}
                  selectedData={selectedDepartment?.items}
                  alreadyHadItems={selectedDepartment?.items}
                  departmentName={selectedDepartment.departmentName}
                  selectedDepartment={selectedDepartment}
               />
            )}
         </Modal>
      </>
   )
}

export default BuildDepartments
