import React, { useEffect, useState } from "react"
import {
  Card,
  CardBody,
  Form,
  FormGroup,
  Row,
  Col,
  Container,
} from "reactstrap"
import { Link, Redirect, useHistory, useLocation } from "react-router-dom"
import { CodeInput } from "../../components/CodeInput"
import { Button } from "../../components/Button"
import { APP_ROUTES, getRoute } from "../../helpers/routeHelpers"

import logo from "../../assets/images/logo-colored.png"
import { useDispatch, useSelector } from "react-redux"
import { withAuthentication } from "hoc"
import useTimer from "hooks/useTimer"
import { ForgotPasswordVerification, ResendOtpCode } from "api/api.service"

const OTP_CELL_COUNT = 4

const ForgetVerifyCode = props => {
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()

  const [verificationCode, setVerificationCode] = useState("")
  const [verificationCodeError, setVerificationCodeError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [disableResendBtn, setDisableResendBtn] = useState(false)

  // const [isOpenTimer, setIsOpenTimer] = useState(false)

  // const params = new URLSearchParams(location?.search)
  // const token = params.get("token")
  // if (!token) {
  //   history.push(APP_ROUTES.LOGIN)
  // }

  // const { minutes = "00", seconds = "00" } = useTimer({
  //   isOpen: isOpenTimer,
  //   initialMinute: 1,
  //   initialSeconds: 0,
  //   handleTimeOut: () => {
  //     setIsOpenTimer(false)
  //     setDisableResendBtn(false)
  //   },
  // })

  const handleSubmit = async e => {
    e.preventDefault()
    if (verificationCode) {
      if (verificationCode?.length == OTP_CELL_COUNT) {
        const data = {
          Code: verificationCode,
          TokenUUID: token,
        }
        try {
          setLoading(true)
          setVerificationCodeError(false)
          const res = await ForgotPasswordVerification(data)
          history.push({
            pathname: getRoute(APP_ROUTES.RESET_PASSWORD),
            state: {
              data: {
                code: res.Code,
                token: res.TokenUUID,
              },
            },
          })
        } catch (err) {
        } finally {
          setLoading(false)
        }
      } else {
        setVerificationCodeError(
          `Please enter a ${OTP_CELL_COUNT} digit OTP code`
        )
      }
    } else {
      setVerificationCodeError("Please enter OTP code")
    }
  }

  const handleResendOtpCode = async () => {
    const data = {
      TokenUUID: token,
    }
    try {
      const res = await ResendOtpCode(data)
      setDisableResendBtn(true)
      setIsOpenTimer(true)
    } catch (err) {
      setIsOpenTimer(false)
      setDisableResendBtn(false)
    }
  }

  const renderTimer = () => {
    return (
      <div className="fs-8">
        <h1 className="clr-blue">
          {" "}
          {minutes}:{seconds}
        </h1>
      </div>
    )
  }

  return (
    <>
      <div className="forget-verify-container p-md-4 p-sm-2">
        <div className="avatar-md mx-auto">
          <div className="avatar-title rounded-circle bg-light">
            <i className="bx bxs-envelope fs-32 mb-0 clr-purple"></i>
          </div>
        </div>
        <h1 className="mt-4 header">Enter OTP code</h1>

        <div className="p-2 mt-4">
          <h4 className="fs-19 clr-gray-dark">Verify your email</h4>
          <h6 className="mb-5 clr-gray-dark">
            Please enter the 4 digit code sent to{" "}
            <span className="font-weight-semibold">
              {location?.state?.email || "your email"}
            </span>
          </h6>
          <Form onSubmit={handleSubmit}>
            <CodeInput
              onChange={code => {
                setVerificationCode(code)
                setVerificationCodeError(false)
              }}
              characters={OTP_CELL_COUNT}
              size="sm"
            />
            {verificationCodeError && (
              <div className="form-input-error text-center">
                {verificationCodeError}
              </div>
            )}

            <div className="mt-4 sign-in-btn">
              <Button
                title="Confirm"
                size="sm"
                className="gt-btn-grad-primary"
                type="submit"
                isLoading={loading}
              />
            </div>
          </Form>
        </div>
        <div className="mt-5 text-center clr-gray-dark fs-13">
          Didn&apos;t receive a code ?{" "}
          <a
            onClick={handleResendOtpCode}
            className={`fw-medium ${
              disableResendBtn ? "resend-btn-anchor-disabled" : ""
            }`}
          >
            Resend
          </a>
          {disableResendBtn && isOpenTimer && <span> {renderTimer()}</span>}
        </div>
      </div>
    </>
  )
}

export default withAuthentication(ForgetVerifyCode)
