import { injectBaseConstantMethods } from "./BaseConstants"

const OrderManagementStatus = {
    OPEN: "Open",
    IN_PROGRESS: "InProgress",
    COMPLETED: "Completed",
}

const displayTextKeys = {
  [OrderManagementStatus.OPEN]: "Open",
  [OrderManagementStatus.IN_PROGRESS]: "In Progress",
  [OrderManagementStatus.COMPLETED]: "Completed",
}

const labelClass = {
  [OrderManagementStatus.OPEN]: "fm-bg-light-red",
  [OrderManagementStatus.IN_PROGRESS]: "fm-bg-light-yellow",
  [OrderManagementStatus.COMPLETED]: "fm-bg-light-green",
}

export default injectBaseConstantMethods(
  OrderManagementStatus,
  displayTextKeys,
  labelClass
)