import React, { useEffect, useState } from "react"

import { DataTable } from "components/DataTable"
import { Button } from "components/Button"
import { InventoryReqItemsStatus } from "constants"
import { dateTimeFormat, convertDateTime } from "utils/dateFormatUtils"
import useGetPdfReport from "hooks/useGetPdfReport"

const COLUMNS = {
    ITEM_NAME: "Item Name",
    SKU_NO: "SKU No.",
    REQUESTED_QUANTITY: "Requested Quantity",
    STATUS: "Status",
    STATUS_UPDATE: "Status Update",
    QUANTITY_SUPPLIED: "Quantity Supplied",
    REQUESTED_DATE: "Requested Date",
    AVAIALBLE_QUANTITY: "Avaiable Quantity",
    QUANTITY_DELIVERED: "Quantity Delivered",
}
const PDF_TYPE = "Department"

const DepartmentRequestItems = ({
    data = {},
    modalCloseHandler = () => {},
}) => {
    const [itemsData, setItemsData] = useState([])
    const { fetchingReport, handleGetPdfReport, reportData } = useGetPdfReport()

    useEffect(() => {
        let clone = [...data.items]
        clone = clone.map(item => ({
            ...item,
            inputQty: item.warehouseQuantity > 1 && 1,
            inputQty: 0,
            subsituteItemsOptions: null,
            isShowItemsSelectBox: false,
            selectedSubsituteItem: null,
            selectedStatusFromOptions: null,
            remainingQuantity: (
                item?.quantity - item?.quantitySupplied
            )?.toFixed(2),
            hasInputError: false,
            isDisableInputQty: true,
            isDisableItemsOptionsSelectbox: true,
            copyOfAvailableQuantity: item.warehouseQuantity,
            fetchingSubsituteItems: false,
            itemsOptionsSelectPlaceholder: "Select Item",
        }))
        setItemsData([...clone])
    }, [])

    return (
        <>
            {data?.items?.length && (
                <div>
                    <h3 className="text-center inv-req-popup-heading">
                        Request Id : {data?.id || "-"}
                    </h3>
                    <DataTable
                        data={itemsData}
                        className="view-detail-inv-req-table verticalScrollIR"
                        tableClasses="ad-depart-inventory-request-table"
                        // loading = {isFetching}
                        config={[
                            {
                                title: COLUMNS.ITEM_NAME,
                                render: data => {
                                    return data?.itemName
                                },
                            },
                            {
                                title: COLUMNS.SKU_NO,
                                render: data => {
                                    return data?.skuNo
                                },
                            },
                            {
                                title: COLUMNS.REQUESTED_QUANTITY,
                                render: data => {
                                    return data?.quantity
                                },
                            },
                            {
                                title: COLUMNS.AVAIALBLE_QUANTITY,
                                render: data => {
                                    return data?.warehouseQuantity
                                },
                            },
                            {
                                title: COLUMNS.QUANTITY_SUPPLIED,
                                render: data => {
                                    return data?.quantitySupplied
                                },
                            },
                            {
                                title: COLUMNS.REQUESTED_DATE,
                                render: data => {
                                    return convertDateTime({
                                        date: data.createdAt,
                                        customFormat:
                                            dateTimeFormat.casitaCustomDateFormat,
                                        dateOnly: true,
                                    })
                                },
                            },
                            {
                                title: COLUMNS.STATUS,
                                render: (data, idx) => {
                                    return (
                                        <div className="d-flex align-items-center justify-content-evenly">
                                            <div
                                                className={`inv-req-popup-status ${InventoryReqItemsStatus.getLabelClass(
                                                    data.status
                                                )}`}
                                            >
                                                {InventoryReqItemsStatus.getDisplayTextKey(
                                                    data.status
                                                )}
                                            </div>
                                        </div>
                                    )
                                },
                            },
                        ]}
                    />
                    <div className="text-center my-4">
                        <Button
                            size="lg"
                            className="gt-btn-grad-primary table-bottom-btn me-2"
                            title={<div>Done</div>}
                            onClick={() => modalCloseHandler()}
                        />
                        <Button
                            isLoading={fetchingReport}
                            size="lg"
                            className="gt-btn-grad-primary table-bottom-btn mx-2 px-5"
                            title={<div>Print Orders</div>}
                            onClick={() =>
                                handleGetPdfReport(data?.id, PDF_TYPE)
                            }
                        />
                    </div>
                </div>
            )}
        </>
    )
}

export default DepartmentRequestItems
