import { injectBaseConstantMethods } from "./BaseConstants"

const UserRoles = {
    User: "USER",
    Admin: "ADMIN",
    Executive: "EXECUTIVE",
    SuperAdmin: "SUPER_ADMIN",
    Production: "PRODUCTION",
    Department: "DEPARTMENT_MANAGER",
    Finance: "FINANCE_MANAGER",
    Warehouse: "WAREHOUSE_MANAGER",
}

const displayTextKeys = {
    [UserRoles.Admin]: "Admin",
    [UserRoles.SuperAdmin]: "Super Admin",
    [UserRoles.Department]: "Station Manager",
    [UserRoles.Executive]: "Executive",
    [UserRoles.Finance]: "Finance Manager",
    [UserRoles.Production]: "Production Manager",
    [UserRoles.User]: "User",
    [UserRoles.Warehouse]: "Warehouse Manager",
}

const labelClass = {
    [UserRoles.Admin]: "admin-role",
    [UserRoles.SuperAdmin]: "super-admin-role",
    [UserRoles.Department]: "department-role",
    [UserRoles.Executive]: "executive-role",
    [UserRoles.Finance]: "finance-role",
    [UserRoles.Production]: "production-role",
    [UserRoles.User]: "user-role",
    [UserRoles.Warehouse]: "warehouse-role",
}

export default injectBaseConstantMethods(UserRoles, displayTextKeys, labelClass)
