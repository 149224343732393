import * as Yup from "yup"

export const FIELDS_NAME = {
    DOWN_PAYMENT_PERCENTAGE: "percentage",
    STATUS: "status",
}

export const initialValues = {
    [FIELDS_NAME.DOWN_PAYMENT_PERCENTAGE]: "",
    [FIELDS_NAME.STATUS]: "ACTIVE",
}

export const validationSchema = Yup.object({
    [FIELDS_NAME.DOWN_PAYMENT_PERCENTAGE]: Yup.number("Enter numbers only")
        .required("Enter percentage")
        .test("", "Percentage must be between 1 and 3 numbers", item => {
            let num = item?.toString()?.length
            return num <= 3 && num >= 1
        }),

    [FIELDS_NAME.STATUS]: Yup.string().required("Select status"),
})
