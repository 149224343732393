// Public routes
import {
   Auth,
   Login,
   ForgetPassword,
   ForgetVerifyCode,
   ResetPassword,
} from "../pages/Authentication"
// Utils
import { APP_ROUTES } from "../helpers/routeHelpers"
import { withTitle } from "../hoc"
import { PageTitles } from "../constants"
// Super Admin / Admin
import { Faqs as AdminFaqs } from "pages/AdminApp/Faqs"
import { TopNavButtons as AdminInventory } from "pages/AdminApp/Inventory"
import { TopNavButtons as AdminInventoryRequest } from "pages/AdminApp/InventoryRequest"
import { LocationsListing as AdminLocations } from "pages/AdminApp/Locations"
import { OrderManagement as AdminOrderManagement } from "pages/AdminApp/OrderManagement"
import { UserManagementListing as AdminUserManagement } from "pages/AdminApp/UserManagement"
// Finance
import { Faqs as FinanceFaqs } from "pages/FinanceApp/Faqs"
import { InventoryManagement as FinanceInventoryManagement } from "pages/FinanceApp/InventoryManagement"
import { TopNavButtons as GeneralOrderManagement } from "pages/FinanceApp/OrderManagement"
// Common
import { Splash } from "pages/Splash"
import { Settings } from "pages/Settings"
import { Dashboard } from "pages/Dasboard"
import { VendorManagementListing } from "pages/VendorManagement"
import DepartmentItemCatalogListing from "pages/AdminApp/Locations/department/ItemCatalog/DepartmentItemCatalogListing"
import VendorDetails from "pages/VendorManagement/VendorDetails"

import { UserRoles } from "../constants"
import { ConfiguratorSettings } from "pages/Configurator"
import { TrailerSettings } from "pages/Configurator/TrailerSettings"
import TrailerOrdersListing from "pages/TrailerOrders/TrailerOrdersListing"
import {
   AddTrailerManagement,
   TrailerManagementDetails,
   TrailerManagementListing,
   UpdateTrailerManagement,
} from "pages/TrailerManagement"

const { Finance, Executive, SuperAdmin, Admin } = UserRoles

export const protectedRoutes = [
   {
      path: APP_ROUTES.DASHBOARD,
      component: withTitle({
         component: Dashboard,
         title: PageTitles.DASHBOARD,
      }),
      exact: true,
      permissions: [Finance, Executive, SuperAdmin],
   },

   {
      path: APP_ROUTES.INVENTORY_MANAGEMENT,
      component: withTitle({
         component: AdminInventory,
         title: PageTitles.INVENTORY_MANAGEMENT,
      }),
      exact: true,
      permissions: [Finance, Executive, SuperAdmin],
   },
   {
      path: APP_ROUTES.INVENTORY_REQUEST,
      component: withTitle({
         component: AdminInventoryRequest,
         title: PageTitles.INVENTORY_REQUEST,
      }),
      exact: false,
      permissions: [Executive, SuperAdmin],
   },
   {
      path: APP_ROUTES.LOCATION,
      component: withTitle({
         component: AdminLocations,
         title: PageTitles.LOCATION,
      }),
      exact: false,
      permissions: [Executive, SuperAdmin],
   },
   {
      path: APP_ROUTES.ORDER_MANAGEMENT,
      component: withTitle({
         component: GeneralOrderManagement,
         title: PageTitles.ORDER_MANAGEMENT,
      }),
      exact: true,
      permissions: [Finance, Executive, SuperAdmin],
   },
   {
      path: APP_ROUTES.ORDERED_TRAILERS,
      component: withTitle({
         component: TrailerOrdersListing,
         title: PageTitles.ORDERED_TRAILERS,
      }),
      exact: false,
      permissions: [Executive, SuperAdmin, Admin],
   },

   {
      path: APP_ROUTES.CONFIGURATOR_TRAILERS,
      component: withTitle({
         component: ConfiguratorSettings,
         title: PageTitles.CONFIGURATOR_TRAILERS,
      }),
      exact: false,
      permissions: [Finance, Executive, SuperAdmin],
   },
   {
      path: APP_ROUTES.CONFIGURATOR_TRAILER_SETTINGS,
      component: withTitle({
         component: TrailerSettings,
         title: PageTitles.CONFIGURATOR_TRAILERS,
      }),
      exact: false,
      permissions: [Finance, Executive, SuperAdmin],
   },
   {
      path: APP_ROUTES.SETTINGS,
      component: withTitle({
         component: Settings,
         title: PageTitles.SETTINGS,
      }),
      exact: true,
      permissions: [Finance, Executive, SuperAdmin],
   },
   {
      path: APP_ROUTES.USER_MANAGEMENT,
      component: withTitle({
         component: AdminUserManagement,
         title: PageTitles.USER_MANAGEMENT,
      }),
      exact: true,
      permissions: [SuperAdmin],
   },
   {
      path: APP_ROUTES.VENDOR_MANAGEMENT,
      component: withTitle({
         component: VendorManagementListing,
         title: PageTitles.VENDOR_MANAGEMENT,
      }),
      exact: true,
      permissions: [Finance, Executive, SuperAdmin],
   },
   {
      path: APP_ROUTES.VENDOR_MANAGEMENT_DETAILS,
      component: withTitle({
         component: VendorDetails,
         title: PageTitles.VENDOR_MANAGEMENT,
      }),
      exact: true,
      permissions: [Finance, Executive, SuperAdmin],
   },

   {
      path: APP_ROUTES.FAQS,
      component: withTitle({
         component: AdminFaqs,
         title: PageTitles.FAQS,
         path: APP_ROUTES.FAQS,
      }),
      exact: true,
      permissions: [Finance, Executive, SuperAdmin],
   },

   {
      path: APP_ROUTES.TRAILERS_MANAGEMENT,
      component: withTitle({
         component: TrailerManagementListing,
         title: PageTitles.TRAILERS_MANAGEMENT,
      }),
      exact: true,
      permissions: [Finance, Executive, SuperAdmin],
   },
   {
      path: APP_ROUTES.TRAILERS_MANAGEMENT_ADD_TRAILER,
      component: withTitle({
         component: AddTrailerManagement,
         title: PageTitles.TRAILERS_MANAGEMENT,
      }),
      exact: true,
      permissions: [Finance, Executive, SuperAdmin],
   },
   {
      path: APP_ROUTES.TRAILERS_MANAGEMENT_UPDATE_TRAILER,
      component: withTitle({
         component: UpdateTrailerManagement,
         title: PageTitles.TRAILERS_MANAGEMENT,
      }),
      exact: true,
      permissions: [Finance, Executive, SuperAdmin],
   },
   {
      path: APP_ROUTES.TRAILERS_MANAGEMENT_TRAILER_DETAILS,
      component: withTitle({
         component: TrailerManagementDetails,
         title: PageTitles.TRAILERS_MANAGEMENT,
      }),
      exact: true,
      permissions: [Finance, Executive, SuperAdmin],
   },

   // {
   //    path: APP_ROUTES.UPDATE_TRAILER,
   //    component: withTitle({
   //       component: ConfiguratorSettings,
   //       title: PageTitles.TRAILERS_MANAGEMENT,
   //    }),
   //    exact: false,
   //    permissions: [Finance, Executive, SuperAdmin],
   // },
]

export const publicRoutes = [
   // {
   //   path: APP_ROUTES.SPLASH,
   //   component: withTitle({
   //     component: Splash,
   //     title: PageTitles.SPLASH,
   //   }),
   //   exact: true,
   // },

   {
      path: APP_ROUTES.LOGIN,
      component: withTitle({
         component: Auth,
         title: PageTitles.LOGIN,
      }),
   },
   {
      path: APP_ROUTES.FORGET_PASSWORD,
      component: withTitle({
         component: Auth,
         title: PageTitles.FORGET_PASSWORD,
      }),
   },
   {
      path: APP_ROUTES.FORGET_VERIFY_CODE,
      component: withTitle({
         component: Auth,
         title: PageTitles.FORGET_VERIFY_CODE,
      }),
   },
   {
      path: APP_ROUTES.RESET_PASSWORD,
      component: withTitle({
         component: Auth,
         title: PageTitles.RESET_PASSWORD,
      }),
   },
]
