// styling header status
const customStyles = {
    AdminAppSelectStyles: {
        container: provided => ({
            display: "inline-block",
            position: "relative",
            width: "100%",
            // background: '#FFFFFF',
            // border: '1px solid #CED4DA',
            borderRadius: "4px",
            ...provided,
        }),
        control: (provided, state) => ({
            ...provided,
            minHeight: "50px",
            // borderColor: "#9d9d9d",
            borderRadius: "10px",
            background: "#EEEEEE",
            border: state.isFocused ? 0 : 0,
            boxShadow: state.isFocused ? 0 : 0,
            "&:hover": {
                borderColor: "#9d9d9d",
            },
        }),
    },
}

export default customStyles
