import React from "react"
import { useHistory } from "react-router-dom"

const BackButtonTableTop = () => {
    const history = useHistory()
    return (
        <>
            <div className="">
                <span onClick={history.goBack} className="table-top-back-btn ">
                    &lt; Back
                </span>
            </div>
        </>
    )
}

export default BackButtonTableTop
