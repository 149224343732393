import React, { useState } from "react"

import { Button } from "components/Button"
import { DataTable } from "components/DataTable"
import { Row, Col } from "reactstrap"
import {
    dateTimeFormat,
    convertDateTime,
} from "../../../../utils/dateFormatUtils"
import { GetWarehouseItemsReceiving } from "api/api.service"
import { useLocalPaginatedRequest } from "hooks/useLocalPaginatedRequest"
import manifestIcon from "../../../../assets/images/icons/manifest-icon.svg"
import MediaDetails from "../MediaDetails"
import { Pagination } from "components/Pagination"

const COLUMNS = {
    PO_NO: "PO No.",
    VENDOR_NAME: "Vendor Name",
    DATE_RECEIVED: "Date Received",
    RECEIVED_QUANTITY: "Received Qty",
    UNIT_PRICE: "Price",
    INVOICE: "Invoices",
    MANIFEST: "Manifests",
}
const MEDIAS = {
    INVOICES: "INVOICES",
    MANIFESTS: "MANIFESTS",
}

const dataKey = "data"
const totalKey = "total"
const RequestItemsReceivingListing = ({
    title,
    footerBtnType,
    modalCloseHandler,
    requestItemId,
}) => {
    const [mediaContentToDownload, setMediaContentToDownload] = useState([])
    const [mediaEntityName, setMediaEntityName] = useState("")

    const {
        data,
        page,
        pageSize,
        isFetching,
        searchText,
        total,
        setData,
        handleSearchChange,
        handlePageClick,
        onChangePageSize,
        request,
    } = useLocalPaginatedRequest({
        requestFn: GetWarehouseItemsReceiving,
        params: {},
        deps: [],
        dataKey: dataKey,
        totalKey: totalKey,
        id: requestItemId,
    })

    const handleModalClose = () => {
        setMediaContentToDownload([])
    }

    const handlePlaceMediaToDownload = (name, file) => {
        setMediaEntityName(name)
        setMediaContentToDownload(file)
    }

    const getMediaDetailsModalSubtitle = () => {
        return mediaEntityName == MEDIAS.MANIFESTS
            ? `REQUEST ID: ${data?.[0]?.purchaseOrderItem?.warehouseRequestId}`
            : `PO NUMBER: ${data?.[0]?.purchaseOrder?.poNo}`
    }

    return (
        <>
            <h3 className="text-left fm-req-popup-heading mb-0 px-5 mb-5">
                {title}
            </h3>
            <div className="pt-3 px-5">
                <Row>
                    <Col sm="6" xs="12" md="3" lg="3" className="my-auto">
                        <p className="place-order-top-date my-auto">
                            Request No.: <span>{requestItemId}</span>
                        </p>
                    </Col>
                    <Col sm="6" xs="12" md="5" lg="5" className="my-auto">
                        <p className="place-order-top-date my-auto">
                            Date of Order Placed:{" "}
                            <span>
                                {convertDateTime({
                                    date: data?.[0]?.createdAt,
                                    customFormat:
                                        dateTimeFormat.casitaCustomDateFormat,
                                    dateOnly: true,
                                })}
                            </span>
                        </p>
                    </Col>
                    <Col
                        sm="12"
                        xs="12"
                        md="12"
                        lg="12"
                        className="px-0 mb-4 mt-3"
                    >
                        <DataTable
                            loading={isFetching}
                            data={data}
                            className=" mt-3 fm-place-order-table place-order-data-table finance-vertical-scroll-om po-data-table-finance"
                            tableClasses="finance-order-management-order-by-order-modal-table"
                            config={[
                                {
                                    title: COLUMNS.PO_NO,
                                    render: data => (
                                        <span>{data.purchaseOrder.poNo}</span>
                                    ),
                                },
                                {
                                    title: COLUMNS.VENDOR_NAME,
                                    render: data => (
                                        <span>
                                            {data.purchaseOrder.vendorName}
                                        </span>
                                    ),
                                },

                                {
                                    title: COLUMNS.DATE_RECEIVED,
                                    render: data =>
                                        convertDateTime({
                                            date: data.createdAt,
                                            customFormat:
                                                dateTimeFormat.casitaCutomDateFormat,
                                            dateOnly: true,
                                        }),
                                },
                                {
                                    title: COLUMNS.UNIT_PRICE,
                                    render: data => (
                                        <div>
                                            {Number(
                                                data?.purchaseOrderItem
                                                    ?.unitPrice
                                            )?.toFixed(2) || "-"}
                                        </div>
                                    ),
                                },

                                {
                                    title: COLUMNS.RECEIVED_QUANTITY,
                                    render: data => (
                                        <span>{data.quantitySupplied}</span>
                                    ),
                                },

                                {
                                    title: COLUMNS.INVOICE,
                                    render: data => {
                                        return data?.invoices?.length ? (
                                            <div
                                                className="pointer"
                                                onClick={() =>
                                                    handlePlaceMediaToDownload(
                                                        MEDIAS.INVOICES,
                                                        data.invoices
                                                    )
                                                }
                                            >
                                                <img src={manifestIcon} />
                                            </div>
                                        ) : (
                                            "-"
                                        )
                                    },
                                },

                                {
                                    title: COLUMNS.MANIFEST,
                                    render: data => {
                                        return data?.purchaseOrderManifest ? (
                                            <div
                                                className="pointer"
                                                onClick={() =>
                                                    handlePlaceMediaToDownload(
                                                        MEDIAS.MANIFESTS,
                                                        [
                                                            {
                                                                ...data.purchaseOrderManifest,
                                                            },
                                                        ]
                                                    )
                                                }
                                            >
                                                <img src={manifestIcon} />
                                            </div>
                                        ) : (
                                            "-"
                                        )
                                    },
                                },
                            ]}
                        />
                    </Col>
                </Row>
                <Pagination
                    currentPage={page}
                    totalData={total}
                    onPageClick={handlePageClick}
                />
            </div>

            <MediaDetails
                subTitle={getMediaDetailsModalSubtitle()}
                title={mediaEntityName}
                mediaArr={mediaContentToDownload}
                isOpen={mediaContentToDownload?.length}
                onToggle={handleModalClose}
            />

            {footerBtnType !== "" && (
                <div className="text-center my-4 d-flex justify-content-center align-items-center">
                    <Button
                        size="lg"
                        className="gt-btn-grad-primary btn-height me-2 d-flex justify-content-center align-items-center place-order-btn"
                        title={<div>{footerBtnType}</div>}
                        onClick={modalCloseHandler}
                    />
                </div>
            )}
        </>
    )
}

export default RequestItemsReceivingListing
