import React, { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
import { Card, CardBody } from "reactstrap"
import { toast } from "react-hot-toast"
import { SearchBox } from "components/SearchBox"

import customStyles from "./HeaderStatusStyling"
import ActionEyeIcon from "assets/images/icons/feather-eye-icon.png"
import { ScrapReturnForm } from "pages/Common/ScrapReturn"
import { DataTable } from "components/DataTable"
import { Pagination } from "components/Pagination"
import { Modal } from "components/Modal"
import { AutoComplete } from "components/AutoComplete"
import { useLocalPaginatedRequest } from "hooks/useLocalPaginatedRequest"
import { convertDateTime, dateTimeFormat } from "utils/dateFormatUtils"
import {
    getSelectBoxOptionsFromConstants,
    truncateString,
} from "utils/commonUtils"
import {
    GetScrapReturnListing,
    GetScrapReturnRequestById,
    WarehouseListing,
} from "api/api.service"
import { ScrapReturnType } from "constants"
import Config from "Config"
import { Refresh } from "components/Refresh"
import { UserRoles } from "constants"

const COLUMNS = {
    SKU_NO: "SKU No.",
    ITEM_NAME: "Item Name",
    TYPE: "Type",
    QUANTITY: "Quantity",
    ISSUES: "Issues",
    WAREHOUSE_NAME: "Warehouse Name",
    DATE_OF_REQUEST: "Requested Date",
    ACTION: "Action",
}

const DATA_KEY = "data"
const TOTAL_KEY = "total"
const SCRAP_REQUEST = "WAREHOUSE"
const CHARACTER_LIMIT = 20

const ScrapReturnListing = ({}) => {
    let controller = new AbortController()
    let signal = controller.signal
    let typeOptions = getSelectBoxOptionsFromConstants(ScrapReturnType)

    const [filters, setFilters] = useState(null)
    const [toggleModal, setToggleModal] = useState(false)
    const [warehouseFilters, setWarehouseFilters] = useState(null)
    const [isFetchingRequest, setIsFetchingRequest] = useState(false)
    const [selectedListItem, setSelectedListItem] = useState("")
    const [warehouseListing, setWarehouseListing] = useState({
        data: [],
        fetching: true,
        page: 1,
        Q: "",
        isDataAvailable: false,
    })

    const {
        page,
        data,
        total,
        pageSize,
        isFetching,
        searchText,
        request,
        handleSearchChange,
        handlePageClick,
        onChangePageSize,
    } = useLocalPaginatedRequest({
        requestFn: GetScrapReturnListing,
        params: {
            ...{ type: UserRoles.Warehouse },
            ...(filters?.value && { requestType: filters?.value }),
            ...(warehouseFilters?.length && {
                id: warehouseFilters.map(item => item.value).join(),
            }),
        },
        deps: [filters, warehouseFilters],
        dataKey: DATA_KEY,
        totalKey: TOTAL_KEY,
    })

    const handleViewScrapDetails = async (scrapId = null) => {
        if (!scrapId) return

        let paramsObj = {
            type: UserRoles.Warehouse,
        }

        setToggleModal(true)

        try {
            setIsFetchingRequest(true)
            let res = await GetScrapReturnRequestById(scrapId, paramsObj)
            setSelectedListItem(res)
        } catch (err) {
            // toast.error(err.message)
        } finally {
            setIsFetchingRequest(false)
        }
    }

    const handleSelectOption = option => {
        setFilters(option)
    }

    useEffect(() => {
        handleWarehouseListing(warehouseListing.page)

        return () => {
            controller.abort()
        }
    }, [warehouseListing.Q])

    const handleSelectDepartments = option => {
        if (typeof option != "object" || !option) return
        setWarehouseFilters(option)
    }
    const handleDepartmentSearch = (value = "", callback) => {
        if (!value) return

        callback(prev => {
            return {
                ...prev,
                data: [],
                page: 1,
                Q: value,
            }
        })
    }

    const handleWarehouseListing = page => {
        setWarehouseListing(prev => {
            return {
                ...prev,
                fetching: true,
            }
        })

        WarehouseListing(
            {
                Limit: Config.LIMIT,
                ...(warehouseListing.Q && { Q: warehouseListing.Q }),
                Page: page,
            },
            signal
        )
            .then(res => {
                setWarehouseListing(prev => ({
                    ...prev,
                    fetching: false,
                    page: page + 1,
                    isDataAvailable: res?.data?.length == Config.LIMIT,
                    data: (() => {
                        let test = res?.data?.map(item => ({
                            ...item,
                            value: item.id,
                            label: item.warehouseName,
                        }))

                        return [...test]
                    })(),
                }))
            })
            .finally(() => {
                setWarehouseListing(prev => {
                    return {
                        ...prev,
                        fetching: false,
                    }
                })
            })
    }
    return (
        <>
            <Card className="mb-5">
                <Refresh onClick={request} />
                <CardBody className="p-2 p-md-4">
                    <div className="mb-3">
                        <div className="d-flex flex-column flex-lg-row align-items-center justify-content-between  finance-om-sr-header">
                            <div className="d-flex justify-content-lg-start justify-content-between align-items-center finance-om-scrap-return">
                                <div className="min-250-w me-2 autocomplete-first">
                                    <AutoComplete
                                        isMulti
                                        placeholder="Warehouses"
                                        value={warehouseFilters}
                                        customStyles={customStyles.WarehouseIM}
                                        handleBlur={() =>
                                            setWarehouseListing(prev => {
                                                return {
                                                    ...prev,
                                                    page: 1,
                                                    Q: "",
                                                }
                                            })
                                        }
                                        onInputChange={val =>
                                            handleDepartmentSearch(
                                                val,
                                                setWarehouseListing
                                            )
                                        }
                                        options={warehouseListing.data}
                                        onChange={val =>
                                            handleSelectDepartments(val)
                                        }
                                        closeMenuOnSelect={true}
                                        isLoading={warehouseListing?.fetching}
                                        classNamePrefix="status-header-search-admin"
                                    />
                                </div>
                                <div className="min-250-w ">
                                    <AutoComplete
                                        value={filters}
                                        isClearable
                                        placeholder="Select status"
                                        isSearchable={false}
                                        customStyles={customStyles.WarehouseIM}
                                        classNamePrefix="status-header-search-admin"
                                        options={typeOptions}
                                        onChange={val =>
                                            handleSelectOption(val)
                                        }
                                    />
                                </div>
                            </div>
                            <div className="location-search-box finance-location-search">
                                <SearchBox
                                    inputClasses="vendor-search-input input-search"
                                    labelClass="d-none"
                                    placeholder="Search"
                                    searchIcon={true}
                                    searchIconClass="vendor-table-search-icon"
                                    conClass="vendor-table-search-inner-class"
                                    searchText={searchText}
                                    onChange={handleSearchChange}
                                />
                            </div>
                        </div>
                    </div>
                    <DataTable
                        data={data}
                        tableClasses="finance-order-mang-scrap-return-table"
                        loading={isFetching}
                        config={[
                            {
                                title: COLUMNS.SKU_NO,
                                render: data => {
                                    return (
                                        <>
                                            <div
                                                onClick={() =>
                                                    setInventoryDetailsModal(
                                                        true
                                                    )
                                                }
                                            >
                                                {data.skuNo || "-"}
                                            </div>
                                        </>
                                    )
                                },
                            },
                            {
                                title: COLUMNS.ITEM_NAME,
                                render: data => {
                                    return data?.itemName || "-"
                                },
                            },
                            {
                                title: COLUMNS.TYPE,
                                render: data => {
                                    return data?.type || "-"
                                },
                            },

                            {
                                title: COLUMNS.QUANTITY,
                                render: data => {
                                    return <>{data.quantity || "-"}</>
                                },
                            },
                            {
                                title: COLUMNS.ISSUES,
                                render: data => {
                                    if (data?.issue?.length > CHARACTER_LIMIT) {
                                        return (
                                            <div data-content={data?.issue}>
                                                {truncateString(
                                                    data?.issue,
                                                    CHARACTER_LIMIT
                                                )}
                                            </div>
                                        )
                                    } else {
                                        return data?.issue || "-"
                                    }
                                },
                            },
                            {
                                title: COLUMNS.WAREHOUSE_NAME,
                                render: data => {
                                    return data?.name || "-"
                                },
                            },

                            {
                                title: COLUMNS.DATE_OF_REQUEST,
                                render: data => {
                                    return (
                                        convertDateTime({
                                            date: data.createdAt,
                                            customFormat:
                                                dateTimeFormat.casitaCustomDateFormat,
                                            dateOnly: true,
                                        }) || "-"
                                    )
                                },
                            },
                            {
                                className: "text-center",
                                title: COLUMNS.ACTION,
                                render: data => {
                                    return (
                                        <span
                                            className="text-success pointer"
                                            onClick={() =>
                                                handleViewScrapDetails(data.id)
                                            }
                                        >
                                            <img src={ActionEyeIcon} />
                                        </span>
                                    )
                                },
                            },
                        ]}
                    />
                    <Pagination
                        currentPage={page}
                        totalData={total}
                        onPageClick={handlePageClick}
                        rowLimit={pageSize}
                    />
                </CardBody>
            </Card>
            <Modal
                isOpen={toggleModal}
                handleModalToggling={() => setToggleModal(false)}
                sizeClasses="10"
            >
                <ScrapReturnForm
                    data={selectedListItem}
                    closeModal={() => setToggleModal(false)}
                />
            </Modal>
        </>
    )
}

export default ScrapReturnListing
