import React, { useState } from "react"

import { Button } from "components/Button"
import { DataTable } from "components/DataTable"
import { Row, Col } from "reactstrap"
import {
    dateTimeFormat,
    convertDateTime,
} from "../../../../utils/dateFormatUtils"
import { GetWarehouseItemsReceiving } from "api/api.service"
import { useLocalPaginatedRequest } from "hooks/useLocalPaginatedRequest"
import manifestIcon from "../../../../assets/images/icons/manifest-icon.svg"
import MediaDetails from "../MediaDetails"
import { Pagination } from "components/Pagination"

const COLUMNS = {
    PO_NO: "PO No.",
    VENDOR_NAME: "Vendor Name",
    DATE_RECEIVED: "Date Received",
    RECEIVED_QUANTITY: "Received Qty",
    REMAINING_QUANTITY: "Remaining Qty",
    UNIT_PRICE: "Price",
    INVOICE: "Invoice",
    MANIFEST: "Manifest",
}
const MEDIAS = {
    INVOICES: "INVOICES",
    MANIFESTS: "MANIFESTS",
}

const dataKey = "data"
const totalKey = "total"

const POItemsBackOrder = ({
    footerBtnType,
    modalCloseHandler,
    requestItemId,
    selectedPO,
}) => {
    const [mediaContentToDownload, setMediaContentToDownload] = useState([])
    const [mediaEntityName, setMediaEntityName] = useState("")

    const {
        data,
        page,
        pageSize,
        isFetching,
        searchText,
        total,
        setData,
        handleSearchChange,
        handlePageClick,
        onChangePageSize,
        request,
    } = useLocalPaginatedRequest({
        requestFn: GetWarehouseItemsReceiving,
        params: {},
        deps: [],
        dataKey: dataKey,
        totalKey: totalKey,
        id: requestItemId,
    })

    const handleModalClose = () => {
        setMediaContentToDownload([])
    }

    const handlePlaceMediaToDownload = (name, file) => {
        setMediaEntityName(name)
        setMediaContentToDownload(file)
    }

    const getMediaDetailsModalSubtitle = () => {
        return mediaEntityName == MEDIAS.MANIFESTS
            ? `ORDER ID: ${data[0]?.purchaseOrderItem?.warehouseRequestId}`
            : `PO NUMBER: ${data[0]?.purchaseOrder?.poNo}`
    }
    return (
        <>
            <div className="d-flex align-items-center flex-column mb-4 bold">
                <h3>PO # {selectedPO.poNo}</h3>
                <p>Back Orders / Logs</p>
            </div>
            <Row className="px-5">
                <Col sm="6" xs="12" md="4" lg="4">
                    <p className="place-order-top-date my-auto">
                        SKU : <span>{data?.[0]?.item?.skuNo}</span>
                    </p>
                </Col>
                <Col sm="6" xs="12" md="4" lg="4">
                    <p className="place-order-top-date my-auto">
                        Item Name : <span>{data?.[0]?.item?.itemName}</span>
                    </p>
                </Col>

                <Col sm="6" xs="12" md="4" lg="4">
                    <p className="place-order-top-date my-auto">
                        Req. Qty: {data?.[0]?.warehouseRequestItemQuantity}
                    </p>
                </Col>
            </Row>
            <div className="pt-3 px-5">
                <Row>
                    <Col
                        sm="12"
                        xs="12"
                        md="12"
                        lg="12"
                        className="px-0 mb-4 mt-3"
                    >
                        <DataTable
                            loading={isFetching}
                            data={data}
                            // className=" mt-3 fm-place-order-table place-order-data-table finance-vertical-scroll-om po-data-table-finance"
                            // tableClasses="finance-order-management-order-by-order-modal-table"
                            config={[
                                {
                                    title: COLUMNS.DATE_RECEIVED,
                                    render: data =>
                                        convertDateTime({
                                            date: data.createdAt,
                                            customFormat:
                                                dateTimeFormat.casitaCustomDateFormat,
                                            dateOnly: true,
                                        }),
                                },

                                {
                                    title: COLUMNS.RECEIVED_QUANTITY,
                                    render: data => (
                                        <span>{data.quantitySupplied}</span>
                                    ),
                                },

                                {
                                    title: COLUMNS.REMAINING_QUANTITY,
                                    render: data => (
                                        <span>
                                            {
                                                data.purchaseOrderItem
                                                    .remainingQuantity
                                            }
                                        </span>
                                    ),
                                },
                            ]}
                        />
                    </Col>
                </Row>
                <Pagination
                    currentPage={page}
                    totalData={total}
                    onPageClick={handlePageClick}
                />
            </div>

            <MediaDetails
                subTitle={() => getMediaDetailsModalSubtitle()}
                // isPdfFile={mediaEntityName == MEDIAS.MANIFESTS}
                // isOtherFile={mediaEntityName == MEDIAS.INVOICES}
                title={mediaEntityName}
                mediaArr={mediaContentToDownload}
                isOpen={mediaContentToDownload?.length}
                onToggle={handleModalClose}
            />

            {footerBtnType !== "" && (
                <div className="text-center my-4 d-flex justify-content-center align-items-center">
                    <Button
                        size="lg"
                        className="gt-btn-grad-primary btn-height me-2 d-flex justify-content-center align-items-center place-order-btn"
                        title={<div>{footerBtnType}</div>}
                        onClick={modalCloseHandler}
                    />
                </div>
            )}
        </>
    )
}

export default POItemsBackOrder
