import { SET_SIZES } from "../actions"

const initialState = {
  sizes: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_SIZES:
      return {
        ...state,
        sizes: [...state.sizes, ...action.payload.Sizes],
      }
    default:
      return state
  }
}
