import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import {
   AccordionBody,
   AccordionHeader,
   AccordionItem,
   UncontrolledAccordion,
} from "reactstrap"

import { GetCustomizationCategoryListing } from "api/api.service"
import { Loader } from "components/Loader"
import { ConfiguratorStatus } from "constants"
import ImageGallery from "pages/Configurator/CommonUI/ImageGallery/ImageGallery"
import { ConfiguratorCustomizationItemSelectionType } from "constants"

const Customization = () => {
   let { trailerId } = useParams()
   const [customizationCategoriesData, setCustomizationCategoriesData] =
      useState([])
   const [loading, setLoading] = useState(false)

   useEffect(() => {
      getCustomizationCategories()
   }, [trailerId])

   const getCustomizationCategories = async () => {
      try {
         setLoading(true)
         let res = await GetCustomizationCategoryListing({
            trailerId: trailerId,
            limit: -1,
         })
         setCustomizationCategoriesData(res)
      } catch (error) {
      } finally {
         setLoading(false)
      }
   }

   if (loading) {
      return <Loader />
   }

   if (customizationCategoriesData.total < 1) {
      return (
         <div className="d-flex justify-content-center align-items-center">
            <h2 className="p-4">No Data</h2>
         </div>
      )
   }

   return (
      <UncontrolledAccordion defaultOpen={0} className="my-4">
         {customizationCategoriesData?.data?.map((pkgObj, ind) => (
            <AccordionItem key={ind}>
               <AccordionHeader targetId={ind}>
                  <div className="d-flex justify-content-between w-100">
                     <div className="accordion-title-container d-flex gap-4">
                        <h4 className="accordion-title-blue  p-0 m-0">
                           {pkgObj.name}
                        </h4>

                        <div
                           className={`configurator-status ${ConfiguratorStatus.getLabelClass(
                              pkgObj.status
                           )}`}
                        >
                           <span>
                              {ConfiguratorStatus.getDisplayTextKey(
                                 pkgObj.status
                              )}
                           </span>
                        </div>
                     </div>
                     <div className="accordion-header-right">
                        {pkgObj.configuratorBaseFeatures.name}
                     </div>
                  </div>
               </AccordionHeader>
               <AccordionBody accordionId={ind}>
                  <UncontrolledAccordion defaultOpen={0} className="my-4">
                     {pkgObj?.subTypeProperties?.map((catItemObj, ind) => (
                        <AccordionItem key={ind}>
                           <AccordionHeader targetId={ind}>
                              <div className="accordion-title-container d-flex gap-4">
                                 <h4 className="accordion-title-blue  p-0 m-0">
                                    {catItemObj?.item?.itemName}
                                 </h4>
                                 <h4 className="accordion-title-black p-0 m-0">
                                    $ {catItemObj.price}
                                 </h4>
                              </div>
                           </AccordionHeader>
                           <AccordionBody accordionId={ind}>
                              <h4 className="accordion-title-black">
                                 Description
                              </h4>
                              <p>{catItemObj.description}</p>
                              <h4 className="accordion-title-black">Images</h4>

                              <ImageGallery
                                 previousImages={catItemObj.images}
                                 previewOnly
                                 isDetailView
                              />

                              {catItemObj.variationFlag && (
                                 <div className="my-2">
                                    <h4 className="accordion-title-black">
                                       <h4 className="accordion-title-black">
                                          Variations :{" "}
                                          {ConfiguratorCustomizationItemSelectionType.getDisplayTextKey(
                                             catItemObj.selectionType
                                          )}
                                       </h4>
                                    </h4>
                                    {
                                       <ul>
                                          {catItemObj.subTypePropertyVariations.map(
                                             (item, ind) => {
                                                return (
                                                   <li key={ind}>
                                                      {`${item?.item?.itemName} - $${item.price}`}
                                                   </li>
                                                )
                                             }
                                          )}
                                       </ul>
                                    }
                                 </div>
                              )}
                           </AccordionBody>
                        </AccordionItem>
                     ))}
                  </UncontrolledAccordion>
               </AccordionBody>
            </AccordionItem>
         ))}
      </UncontrolledAccordion>
   )
}

export default Customization
