import React, { useState } from "react"
import { Row, Col } from "reactstrap"
import { Pagination } from "components/Pagination"
import { DataTable } from "components/DataTable"
import { useLocalPaginatedRequest } from "hooks/useLocalPaginatedRequest"

import { PlacedOrdersOMListing } from "api/api.service"
import { SearchBox } from "components/SearchBox"
import { dateTimeFormat, convertDateTime } from "../../../utils/dateFormatUtils"
import { OrderManagementStatus } from "../../../constants"
import { Modal } from "components/Modal"
import PlacedOrderDetailView from "./PlacedOrdersDetailView"
import ActionEyeIcon from "assets/images/icons/feather-eye-icon.png"
import { Refresh } from "components/Refresh"
import MediaDetails from "./MediaDetails"
import manifestIcon from "../../../assets/images/icons/manifest-icon.svg"
import { AutoComplete } from "components/AutoComplete"
import customStyles from "./HeaderStatusStyling"

const COLUMNS = {
    PO_NO: "PO No.",
    VENDOR: "Vendor",
    ORDER_DATE: "Order Date",
    STATUS: "Status",
    LEAD_TIME: "Lead Time",
    TIME_TAKEN: "Time Taken",
    TOTAL_AMOUNT: "PO Total",
    SHIPPING_MANIFEST: "Shipping Manifests",
    INVOICES: "Invoices",
    ACTIONS: "Action",
}

const dataKey = "data"
const totalKey = "total"

const MEDIAS = {
    INVOICES: "INVOICES",
    MANIFESTS: "MANIFESTS",
}

const PlacedOrdersListing = () => {
    const [mediaContentToDownload, setMediaContentToDownload] = useState([])
    const [mediaContentRecord, setMediaContentRecord] = useState([])
    const [mediaEntityName, setMediaEntityName] = useState("")
    const [modalState, setModalState] = useState(false)
    const [selectedPO, setSelectedPO] = useState([])
    const [dataRefetch, setDataRefetch] = useState(false)
    const [filters, setFilters] = useState(null)
    const {
        data,
        isFetching,
        page,
        handlePageClick,
        searchText,
        handleSearchChange,
        pageSize,
        onChangePageSize,
        total,
        request,
    } = useLocalPaginatedRequest({
        requestFn: PlacedOrdersOMListing,
        params: {
            ...(filters?.Status && { requestStatus: filters?.Status }),
        },
        deps: [dataRefetch, filters],
        dataKey: dataKey,
        totalKey: totalKey,
    })

    const handleModalClose = () => {
        setMediaContentToDownload([])
        setMediaContentRecord([])
    }

    const getMediaDetailsModalSubtitle = data => {
        return mediaEntityName == MEDIAS.MANIFESTS
            ? ``
            : `PO NUMBER: ${mediaContentRecord?.poNo}`
    }

    const handlePlaceMediaToDownload = (name, file) => {
        setMediaEntityName(name)
        setMediaContentToDownload(file)
    }

    const statusOptions =
        OrderManagementStatus &&
        Object.keys(OrderManagementStatus).map(el => {
            return {
                label: OrderManagementStatus.getDisplayTextKey(
                    OrderManagementStatus[el]
                ),
                value: OrderManagementStatus[el],
            }
        })
    const handleSelectOptionChange = val => {
        setFilters({ Status: val.value })
    }

    return (
        <>
            <div>
                <Row>
                    <Col>
                        <div className="d-flex justify-content-end align-items-center">
                            <div className="d-flex gap-2 flex-wrap">
                                <div className="status-search-con">
                                    <AutoComplete
                                        isSearchable={false}
                                        placeholder="Select Status"
                                        customStyles={customStyles.WarehouseIM}
                                        classNamePrefix="fm-status-header-search"
                                        options={statusOptions}
                                        onChange={handleSelectOptionChange}
                                    />
                                </div>
                                <div className="fm-search-input finance-byitems-search-ip">
                                    <SearchBox
                                        searchText={searchText}
                                        onChange={handleSearchChange}
                                        inputClasses="table-main-search-input fm-table-input "
                                        labelClass="d-none"
                                        placeholder="Search"
                                        searchIcon={true}
                                        searchIconClass="fm-table-search-icon"
                                        conClass="fm-table-search-con"
                                    />
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col md={12}>
                        <Refresh onClick={request} />
                        <DataTable
                            data={data}
                            className="fm-table-con mt-3"
                            tableClasses="finance-om-orderplaced-table"
                            loading={isFetching}
                            config={[
                                {
                                    title: COLUMNS.PO_NO,
                                    className: "black-color fm-ftwg-600",
                                    render: data => data?.poNo,
                                },

                                {
                                    title: COLUMNS.VENDOR,
                                    render: data => data?.vendorName,
                                },
                                {
                                    title: COLUMNS.ORDER_DATE,
                                    render: data => {
                                        return convertDateTime({
                                            date: data.createdAt,
                                            customFormat:
                                                dateTimeFormat.casitaCustomDateFormat,
                                            dateOnly: true,
                                        })
                                    },
                                },
                                {
                                    title: COLUMNS.TOTAL_AMOUNT,
                                    className: "black-color fm-ftwg-600",
                                    render: data =>
                                        `$${Number(
                                            data?.orderItemsTotal
                                        )?.toFixed(2)}`,
                                },
                                {
                                    title: COLUMNS.LEAD_TIME,
                                    render: data => {
                                        return data.averageLeadTimeDays || "-"
                                    },
                                },
                                {
                                    title: COLUMNS.TIME_TAKEN,
                                    render: data => {
                                        return data?.orderLeadTime || "-"
                                    },
                                },

                                {
                                    title: COLUMNS.STATUS,
                                    render: data => {
                                        return (
                                            <div
                                                className={`fm-om-status ${OrderManagementStatus.getLabelClass(
                                                    data?.requestStatus
                                                )}`}
                                            >
                                                {OrderManagementStatus.getDisplayTextKey(
                                                    data?.requestStatus
                                                )}
                                            </div>
                                        )
                                    },
                                },
                                {
                                    title: COLUMNS.INVOICES,
                                    render: data => {
                                        return data?.invoices?.length ? (
                                            <div
                                                className="pointer"
                                                onClick={() => {
                                                    setMediaContentRecord(data)
                                                    return handlePlaceMediaToDownload(
                                                        MEDIAS.INVOICES,
                                                        data.invoices
                                                    )
                                                }}
                                            >
                                                <img src={manifestIcon} />
                                            </div>
                                        ) : (
                                            "-"
                                        )
                                    },
                                },

                                {
                                    title: COLUMNS.SHIPPING_MANIFEST,
                                    render: data => {
                                        return data?.purchaseOrderItemReceiving
                                            ?.length ? (
                                            <div
                                                className="pointer"
                                                onClick={() => {
                                                    let mediaArr =
                                                        data.purchaseOrderItemReceiving.map(
                                                            item => ({
                                                                ...item.purchaseOrderManifest,
                                                                imageTitle: `REQUEST ID: ${item.warehouseRequestItem.warehouseRequestId}`,
                                                            })
                                                        )
                                                    setMediaContentRecord(data)

                                                    return handlePlaceMediaToDownload(
                                                        MEDIAS.MANIFESTS,
                                                        mediaArr
                                                    )
                                                }}
                                            >
                                                <img src={manifestIcon} />
                                            </div>
                                        ) : (
                                            "-"
                                        )
                                    },
                                },
                                {
                                    className: "text-center",
                                    title: COLUMNS.ACTIONS,
                                    render: data => {
                                        return (
                                            <span
                                                className="text-success pointer"
                                                onClick={() => {
                                                    setSelectedPO(data)
                                                    setModalState(true)
                                                }}
                                            >
                                                <img src={ActionEyeIcon} />
                                            </span>
                                        )
                                    },
                                },
                            ]}
                        />
                    </Col>
                </Row>
                <Pagination
                    currentPage={page}
                    totalData={total}
                    onPageClick={handlePageClick}
                />
                <MediaDetails
                    subTitle={getMediaDetailsModalSubtitle()}
                    title={mediaEntityName}
                    mediaArr={mediaContentToDownload}
                    isOpen={mediaContentToDownload?.length}
                    onToggle={handleModalClose}
                />

                <Modal
                    isOpen={modalState}
                    handleModalToggling={() => setModalState(prev => !prev)}
                    bodyClassName="p-0"
                    customButton={true}
                    hideModalHeaderSeparator={true}
                    headerClasses="pb-0"
                    className="inv-req-popup-viewdetails"
                    sizeClasses="10"
                >
                    <PlacedOrderDetailView
                        modalCloseHandler={() => setModalState(prev => !prev)}
                        selectedPO={selectedPO}
                        title="View Order"
                    />
                </Modal>
            </div>
        </>
    )
}

export default PlacedOrdersListing
