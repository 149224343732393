import { injectBaseConstantMethods } from "./BaseConstants"

const EquipmentCategoryItemsStatus = {
    Active: "ACTIVE",
    Inactive: "INACTIVE",
}

const displayTextKeys = {
    [EquipmentCategoryItemsStatus.Active]: "Active",
    [EquipmentCategoryItemsStatus.Inactive]: "Inactive",
}

const labelClass = {}

export default injectBaseConstantMethods(
    EquipmentCategoryItemsStatus,
    displayTextKeys,
    labelClass
)
