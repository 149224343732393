import React from "react"

export default ({ component: Component, title = "Casita Travel Trailors" }) => {
  class Title extends React.Component {
    constructor(props) {
      super(props)
      document.title = title + " - Casita Travel Trailors"
    }

    render() {
      return <Component {...this.props} />
    }
  }
  return Title
}
