export const PLACE_ORDER_BUTTON = 'PLACE_ORDER_BUTTON'
export const UPDATE_SELECTED_ITEMS_LENGTH = 'UPDATE_SELECTED_ITEMS_LENGTH'

export const updatePlaceOrderButton = payload => (dispatch, getState) => {
  dispatch({type: PLACE_ORDER_BUTTON, payload})
}

export const updateSelectedItemsLength = payload => (dispatch, getState) => {
  dispatch({type: UPDATE_SELECTED_ITEMS_LENGTH, payload})
}
