import { PLACE_ORDER_BUTTON, UPDATE_SELECTED_ITEMS_LENGTH } from "../actions/placeOrderButton"

let initialState = {
  updateCheckBoxData : false,
  selectedItemsListLength: 0,
}

export default (state=initialState, action) => {
  switch(action.type){
    case PLACE_ORDER_BUTTON:
      return {...state,
        updateCheckBoxData: !state.updateCheckBoxData
      }
    case UPDATE_SELECTED_ITEMS_LENGTH:
      return {
        ...state, 
        selectedItemsListLength: action.payload.data,
      }
      default:
        return state
  }
}