import React, { useEffect, useState } from "react"
import { CardBody, Col, Form, FormGroup, Label, Row } from "reactstrap"
import { useFormik } from "formik"

import { Button } from "components/Button"
import { TextInput } from "components/TextInput"
import {
    initialValuesChangePass,
    validationSchemaChangePass,
} from "./FormConfig"
import toast from "react-hot-toast"
import { UpdateUserPassword } from "api/api.service"

const updatedMessages = "Password has been updated successfully!"
const ChangePassword = ({ closeModalHandler = () => {}, userId }) => {
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        return () => {
            formik.setFieldValue("password", "")
            formik.setFieldValue("confirmPassword", "")
        }
    }, [])

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: initialValuesChangePass,
        validationSchema: validationSchemaChangePass,
        onSubmit: values => {
            handleChangePasswordSubmit(values)
        },
    })

    async function handleChangePasswordSubmit(values) {
        try {
            let payload = {
                CurrentPassword: values?.confirmPassword,
                NewPassword: values?.password,
            }
            setLoading(true)
            let res = await UpdateUserPassword(userId, payload)
            toast.success(updatedMessages)
            closeModalHandler()
        } catch (err) {
            // toast.error(err.message)
        } finally {
            setLoading(false)
        }
    }
    return (
        <Form onSubmit={formik.handleSubmit}>
            <CardBody className="user-add-edit-card-container shadow-none">
                <FormGroup className="d-flex user-validation-handler">
                    <Label
                        for="password"
                        className="user-mang-modal-label "
                        sm={4}
                    >
                        Password
                    </Label>
                    <Col sm={7} className="input-text-container ">
                        <TextInput
                            withIcon={true}
                            className="user-mang-modal-input "
                            placeholder="Password"
                            type="password"
                            name="password"
                            id="password"
                            invalid={
                                formik.touched.password &&
                                !!formik.errors.password
                            }
                            valid={
                                formik.touched.password &&
                                !formik.errors.password
                            }
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.password}
                        />
                    </Col>
                </FormGroup>

                <Row className="mb-3">
                    <Col sm={4}></Col>
                    <Col sm={7}>
                        {formik.touched.password && formik.errors.password ? (
                            <span className="error fs-10">
                                {formik.errors.password}
                            </span>
                        ) : null}
                    </Col>
                </Row>
                <FormGroup className="d-flex user-validation-handler">
                    <Label
                        for="confirmPassord"
                        className="user-mang-modal-label "
                        sm={4}
                    >
                        Confirm Password
                    </Label>
                    <Col sm={7} className="input-text-container ">
                        <TextInput
                            withIcon={true}
                            className="user-mang-modal-input "
                            placeholder="Confirm password"
                            type="password"
                            name="confirmPassword"
                            id="confirmPassord"
                            invalid={
                                formik.touched.confirmPassword &&
                                !!formik.errors.confirmPassword
                            }
                            valid={
                                formik.touched.confirmPassword &&
                                !formik.errors.confirmPassword
                            }
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.confirmPassword}
                        />
                    </Col>
                </FormGroup>

                <Row className="mb-3">
                    <Col sm={4}></Col>
                    <Col sm={7}>
                        {formik.touched.confirmPassword &&
                        formik.errors.confirmPassword ? (
                            <span className="error fs-10">
                                {formik.errors.confirmPassword}
                            </span>
                        ) : null}
                    </Col>
                </Row>
                <Button
                    type="submit"
                    title={"Save"}
                    className="gt-btn-grad-primary mt-5 button-width-container"
                    isLoading={loading}
                />
            </CardBody>
        </Form>
    )
}

export default ChangePassword
