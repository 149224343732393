import { injectBaseConstantMethods } from "./BaseConstants"

const TrailerModels = {
    Standard: "Standard",
    Deluxe: "Deluxe",
}

const displayTextKeys = {
    [TrailerModels.Standard]: "Standard",
    [TrailerModels.Deluxe]: "Deluxe",
}

const labelClass = {}

export default injectBaseConstantMethods(
    TrailerModels,
    displayTextKeys,
    labelClass
)
