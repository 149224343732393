import React, { useState } from "react"
import toast from "react-hot-toast"

import "./style.scss"
import { DataTable } from "../../../../components/DataTable"
import { AutoComplete } from "../../../../components/AutoComplete"
import { Button } from "../../../../components/Button"
import { GetItemsListing, WarehouseListing } from "../../../../api/api.service"
import DeleteIcon from "../../../../assets/images/icons/finance-vendor-mang-del-icon.svg"
import AddNewItemSelectStyling from "./AddNewItemSelectStyling"
import {
   makeOptionsDisabled,
   validateOptionSelect,
} from "../../../../utils/commonUtils"
import useAsyncSelect from "hooks/useAsyncSelect"

const COLUMNS = {
   SKU_NO: "SKU No.",
   ITEM_NAME: "Item Name",
   QUANTITY: "Quantity",
   ACTION: "Action",
}

const DirectTransferModal = ({ onSubmit }) => {
   const [isAddingRequest, setIsAddingRequest] = useState()
   const [selectedItems, setSelectedItems] = useState([])

   const {
      setSelectedOption: setSelectedItem,
      selectEntity: itemsOptions,
      setSelectEntity: setItemsOptions,
      handleSearchOnOptions: handleSearchItems,
      selectedOption: selectedItem,
      handleSelectAOption: handleSelectAItem,
   } = useAsyncSelect({
      apiFunc: GetItemsListing,
      params: {
         limit: -1,
      },
      labelKey: "itemName",
      valueKey: "id",
   })

   const {
      setSelectedOption: setSelectedWarehouseOption,
      selectEntity: warehouseOptions,
      setSelectEntity: setWarehouseOption,
      handleSearchOnOptions: handleSearchWarehouses,
      selectedOption: selectedWarehouse,
      handleSelectAOption: handleSelectAWarehouseOption,
   } = useAsyncSelect({
      apiFunc: WarehouseListing,
      labelKey: "warehouseName",
      valueKey: "id",
   })

   const handleSelectOption = option => {
      if (option.maxQuantity <= option.warehouseQuantity) {
         return toast.error(
            "Cant add this item, Warehouse already has enough quantity"
         )
      }

      if (
         typeof option != "object" ||
         validateOptionSelect(option, selectedItems, "id") ||
         !Object.keys(option)
      )
         return
      setSelectedItems(prev => {
         return [...prev, { ...option, requiredQuantity: "", hasError: false }]
      })
   }

   const handleDeleteSelectedItem = (id = "", sku) => {
      if (!id) return

      let filteredData = selectedItems.filter(obj => obj.id != id)
      setSelectedItems([...filteredData])
      if (filteredData.length < 1) {
      }
      itemsOptions?.data.map(el => {
         if (el.skuNo === sku) {
            el.isDisabled = false
         }
      })
   }

   const handleSubmit = async () => {
      if (!selectedWarehouse?.id) {
         return toast.error("Please select warehouse !")
      }

      onSubmit(selectedItems, selectedWarehouse)
   }

   return (
      <>
         <h3
            className=" inv-req-popup-heading px-3"
            style={{
               fontWeight: "bold",
               color: "black",
            }}
         >
            Direct P.O.
         </h3>

         <div className="pt-3 pb-4  d-flex align-items-center justify-content-between px-3">
            <div>
               <span
                  className="font-size-13 "
                  style={{
                     width: "100px",
                     fontWeight: "bold",
                     color: "black",
                  }}
               >
                  Add Item
               </span>
               <div style={{ width: "250px" }}>
                  <AutoComplete
                     placeholder="Search Item"
                     classNamePrefix="add-new-req"
                     value={null}
                     customStyles={AddNewItemSelectStyling}
                     onInputChange={handleSearchItems}
                     options={makeOptionsDisabled(
                        selectedItems,
                        itemsOptions?.data,
                        true
                     )}
                     onChange={val => handleSelectOption(val)}
                     closeMenuOnSelect={true}
                     isLoading={itemsOptions?.fetching}
                     handleBlur={() =>
                        setItemsOptions(prev => {
                           return {
                              ...prev,
                              page: 1,
                              Q: "",
                           }
                        })
                     }
                  />
               </div>
            </div>

            <div>
               <span
                  className="font-size-13 "
                  style={{
                     width: "100px",
                     fontWeight: "bold",
                     color: "black",
                  }}
               >
                  Select Warehouse
               </span>
               <div style={{ width: "250px" }}>
                  <AutoComplete
                     customStyles={AddNewItemSelectStyling}
                     handleBlur={() =>
                        setWarehouseOption(prev => {
                           return {
                              ...prev,
                              page: 1,
                              Q: "",
                           }
                        })
                     }
                     onChange={handleSelectAWarehouseOption}
                     onInputChange={handleSearchWarehouses}
                     placeholder="Search warehouse"
                     classNamePrefix="add-new-req"
                     options={warehouseOptions?.data}
                     value={selectedWarehouse}
                     isLoading={warehouseOptions?.fetching}
                     name={"warehouse"}
                  />
               </div>
            </div>
         </div>
         <DataTable
            data={selectedItems}
            className="view-detail-inv-req-table  height-scroll-table w-100"
            tableClasses="add-new-request-table"
            config={[
               {
                  title: COLUMNS.SKU_NO,
                  render: data => {
                     return data?.skuNo
                  },
               },
               {
                  title: COLUMNS.ITEM_NAME,
                  render: data => {
                     return data?.itemName
                  },
               },

               {
                  title: COLUMNS.ACTION,
                  render: data => {
                     return (
                        <img
                           onClick={() =>
                              handleDeleteSelectedItem(data.id, data.skuNo)
                           }
                           className="view-detail-modal-delete"
                           src={DeleteIcon}
                        />
                     )
                  },
               },
            ]}
         />
         <div className="text-center my-4">
            <Button
               disabled={!Boolean(selectedItems?.length)}
               isLoading={isAddingRequest}
               size="lg"
               className="gt-btn-grad-primary table-bottom-btn m-0 p-0 btn-alignment"
               title="Proceed"
               onClick={handleSubmit}
            />
         </div>
      </>
   )
}

export default DirectTransferModal
