import React from "react"
import PricingDetail from "./PricingDetail"
import { Col, Row } from "reactstrap"

const PricingSection = ({ title, price, details }) => {
   return (
      <div>
         <Row className="d-flex justify-content-between my-1">
            {title && <Col className="text-left">{title}</Col>}
            {price && <Col className="text-right">${price}</Col>}
         </Row>
         {details.map((detail, index) => {
            if (detail.checked) {
               return (
                  <PricingDetail
                     key={index}
                     label={detail.name || detail.item.itemName}
                     price={Number(detail.price)?.toFixed(2)}
                  />
               )
            }
            return null
         })}
      </div>
   )
}

export default PricingSection
