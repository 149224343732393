import React, { useState } from "react"
import { Card, CardBody, Col, ModalBody, Row } from "reactstrap"
import toast from "react-hot-toast"
import { useHistory, useParams } from "react-router-dom"

import delIcon from "assets/images/icons/finance-vendor-mang-del-icon.svg"
import { DataTable } from "components/DataTable"
import { Modal } from "components/Modal"
import { Pagination } from "components/Pagination"
import DeleteModal from "../../modals/DeleteModal"
import { Header } from "../../../Common/Header"
import { useLocalPaginatedRequest } from "hooks/useLocalPaginatedRequest"
import {
    GetItemCatalogDetailsById,
    DeleteItemFromItemCatalog,
} from "api/api.service"
import AddNewItemModal from "./AddNewItemModal"
import { TextInput } from "components/TextInput"

const COLUMNS = {
    S_NO: "S.No",
    SKU: "SKU",
    ITEM_NAME: "Item Name",
    QUANTITY: "Quantity",
    ACTIONS: "Action",
}

const MODAL_ACTIONS = {
    ADD: "1",
    DELETE: "2",
}

const dataKey = "data"
const totalKey = "total"

const DepartmentCatalogListing = ({}) => {
    let history = useHistory()
    let { departmentId } = useParams()
    const [isLoading, setIsLoading] = useState(false)
    const [selectedItemFromCatalog, setSelectedItemFromCatalog] = useState({})
    const [selectedItems, setSelectedItems] = useState([])

    const [actionStates, setActionStates] = useState({
        isAddCatalogItems: null,
        isEditDepartment: null,
        isDeleteModal: null,
    })
    const [currentActionKey, setCurrentActionKey] = useState(null)
    const [isDataUpdated, setIsDataUpdated] = useState(false)
    const [filters, setFilters] = useState(null)
    const {
        wholeResponse,
        data = [],
        isFetching,
        page,
        searchText,
        total,
        pageSize,
        request,
        onChangePageSize,
        handlePageClick,
        handleSearchChange,
    } = useLocalPaginatedRequest({
        requestFn: GetItemCatalogDetailsById,
        params: {
            ...(filters?.Status && { Status: filters?.Status }),
        },
        deps: [filters, isDataUpdated],
        dataKey: dataKey,
        totalKey: totalKey,
        id: departmentId,
    })

    const handleDeleteModal = data => {
        setCurrentActionKey(MODAL_ACTIONS.DELETE)
        setActionStates(prev => ({ ...prev, isDeleteModal: true }))
        setSelectedItemFromCatalog(data)
    }

    let currentAction = {
        [MODAL_ACTIONS.ADD]: {
            title: "Add Item Catalog",
            btnText: "Add Item Catalog",
        },

        [MODAL_ACTIONS.DELETE]: {
            title: "Delete Catalog Item",
            btnText: "Delete Catalog Item",
            value: "item",
        },
    }

    const handleCloseModal = () => {
        setActionStates({
            isAddCatalogItems: null,
            isDeleteModal: null,
        })
        setCurrentActionKey(null)
        setSelectedItemFromCatalog({})
        setSelectedItems([])
    }

    const handleAddCatalogItems = () => {
        setCurrentActionKey(MODAL_ACTIONS.ADD)
        setActionStates(prev => ({ ...prev, isAddCatalogItems: true }))
    }

    const handleDeleteItemFromCatalog = async () => {
        try {
            setIsLoading(true)
            let res = await DeleteItemFromItemCatalog(
                selectedItemFromCatalog.id
            )
            toast.success("Item has been deleted successfully!")
            handleCloseModal()

            if (res.count == 0) {
                history.goBack()
                return
            }
            if (res.count == 10) {
                handlePageClick(1)
            }

            request()
        } catch (err) {
            // toast.error(err.message)
        } finally {
            setIsLoading(false)
        }
    }

    const handleCheckedBoxSelection = data => {
        let isAdded = selectedItems?.find(item => item.id == data.id)

        if (isAdded) {
            let updatedItems = selectedItems?.filter(item => item.id != data.id)
            setSelectedItems([...updatedItems])
        } else {
            setSelectedItems(prev => {
                return [
                    ...prev,
                    {
                        ...data,
                        requiredQuantity: data.quantity,
                        hasError: false,
                    },
                ]
            })
        }
    }

    return (
        <Card className="">
            <CardBody className="p-2">
                <Row>
                    <Col>
                        <Header
                            headerTitle={
                                <span
                                    style={{
                                        fontSize: "1.05rem",
                                    }}
                                >
                                    {`Station Name:  ${
                                        wholeResponse?.departmentName || "-"
                                    }`}
                                </span>
                            }
                            btnTitle="Add Item Catalog"
                            onClick={handleAddCatalogItems}
                            searchText={searchText}
                            onChangeHandler={handleSearchChange}
                            flexResponsive="location-flex-responsiveness p-2"
                            searchResponsiveness="location-searchResponsiveness"
                            flexResponsiveInner="location-flexResponsiveInner"
                            headerBtnResponsive="ad-location-header-btn-responsive"
                            SearchBoxContainer="admin-location-search-box-container"
                        />
                    </Col>
                    <Col md={12}>
                        <DataTable
                            data={data}
                            loading={isFetching}
                            className="location-data-container mt-3"
                            tableClasses="ad-location-table"
                            config={[
                                {
                                    title: COLUMNS.CHECK_BOXES,
                                    render: data => {
                                        return (
                                            <div className="form-check">
                                                <TextInput
                                                    onClick={() =>
                                                        handleCheckedBoxSelection(
                                                            data
                                                        )
                                                    }
                                                    labelClass="mb-0"
                                                    type="checkbox"
                                                    inputClass="form-check-input-custom pointer"
                                                    checked={
                                                        selectedItems
                                                            ? selectedItems?.find(
                                                                  item =>
                                                                      item.id ==
                                                                      data.id
                                                              )
                                                            : false
                                                    }
                                                />
                                            </div>
                                        )
                                    },
                                },

                                {
                                    title: COLUMNS.S_NO,
                                    className: "black-color fm-ftwg-600  ",
                                    render: data => data?.id || "-",
                                },
                                {
                                    title: COLUMNS.SKU,
                                    className: "",
                                    render: data => data?.skuNo || "-",
                                },

                                {
                                    title: COLUMNS.ITEM_NAME,
                                    className: "",
                                    render: data => data?.itemName || "-",
                                },
                                {
                                    title: COLUMNS.QUANTITY,
                                    className: "",
                                    render: data => data?.quantity || "-",
                                },

                                {
                                    title: COLUMNS.ACTIONS,
                                    className: "",
                                    render: data => (
                                        <div className="d-flex justify-content-between location-icon-container">
                                            <span
                                                className="pointer"
                                                onClick={() =>
                                                    handleDeleteModal(data)
                                                }
                                            >
                                                <img src={delIcon} />
                                            </span>
                                        </div>
                                    ),
                                },
                            ]}
                        />
                    </Col>
                </Row>

                <Pagination
                    currentPage={page}
                    totalData={total}
                    onPageClick={handlePageClick}
                />

                <Modal
                    isOpen={!!currentAction[currentActionKey]}
                    handleModalToggling={handleCloseModal}
                    bodyClassName="p-0"
                    customButton={true}
                    hideModalHeaderSeparator={true}
                    headerClasses="modal-header-container"
                    sizeClasses={actionStates.isAddCatalogItems ? "10" : "2"}
                    modalTitle={currentAction[currentActionKey]?.title}
                >
                    {actionStates.isAddCatalogItems && (
                        <AddNewItemModal
                            modalCloseHandler={handleCloseModal}
                            listingRequestHandler={request}
                            selectedData={[...selectedItems]}
                            alreadyHadItems={[...data]}
                            currentItemsCount={total}
                            catalogId={data[0]?.departmentsCatalogId}
                            departmentName={wholeResponse.departmentName}
                        />
                    )}
                    {actionStates.isDeleteModal && (
                        <DeleteModal
                            value={currentAction[currentActionKey]?.value}
                            handleModalClose={handleCloseModal}
                            handleDeleteRecord={handleDeleteItemFromCatalog}
                        />
                    )}
                </Modal>
            </CardBody>
        </Card>
    )
}

export default DepartmentCatalogListing
