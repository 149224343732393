import React, { useRef } from "react"
import uploadClickIcon from "assets/images/icons/upload-click-icon.png"
import modalCloseIcon from "../../../assets/images/inventory-request/modal-close-icon.png"

const ScrapImageUpload = ({
  isReadOnly,
  image,
  onChange,
  isNewImageSet = false,
  dumyimage,
  removeImageHandler = () => {},
}) => {
  const profileFileUpload = useRef(null)

  const handleFileUploadClick = () => {
    profileFileUpload.current.click()
  }

  return (
    <div className="image-upload-container">
      {!isNewImageSet && (
        <>
          <div
            role="button"
            onClick={handleFileUploadClick}
            className="avatar-edit-icon bg-white rounded-circle d-inline-flex position-relative"
          >
            {!isReadOnly && (
              <div
                className="d-flex p-2 justify-content-between"
                style={{ width: "150px" }}
              >
                <div style={{ width: "15px", height: "19px" }}>
                  <img
                    className="clr-theme-primary w-100 h-100 mr-2"
                    src={uploadClickIcon}
                  />
                </div>

                <p className="pl-2">no file uploaded</p>
              </div>
            )}

            <input
              id="profilePicUpload"
              ref={profileFileUpload}
              type="file"
              hidden
              onChange={onChange}
              accept="image/*"
            />
          </div>
        </>
      )}
      {isNewImageSet && (
        <>
          <div className="div-container">
            <button
              className="custom-btn-close-popup "
              onClick={() => removeImageHandler()}
              type="button"
            >
              <img src={modalCloseIcon} />
            </button>
          </div>
          <>
            <div className="d-flex justify-content-center align-items-center container">
              <img src={image} className="imageHandle" />
            </div>
          </>
        </>
      )}

      {!isNewImageSet && (
        <>
          <div className="d-flex justify-content-center align-items-center container image-preview-container">
            <img src={image ? image : dumyimage} className="image-preview" />
          </div>
        </>
      )}
    </div>
  )
}

export default ScrapImageUpload
