import React, { useEffect, useState } from "react"

import { DataTable } from "components/DataTable"
import { Button } from "components/Button"
import { InventoryReqItemsStatus } from "constants"
import { dateTimeFormat, convertDateTime } from "utils/dateFormatUtils"
import useGetPdfReport from "hooks/useGetPdfReport"

const PDF_TYPE = "Pullback"

const COLUMNS = {
    ITEM_NAME: "Item Name",
    SKU_NO: "SKU No.",
    REQUESTED_QUANTITY: "Requested Quantity",
    STATUS: "Status",
    STATUS_UPDATE: "Status Update",
    QUANTITY_SUPPLIED: "Supplied Quantity",
    REQUESTED_DATE: "Requested Date",
    QUANTITY_DELIVERED: "Deliver Quantity",
}

const PullbackRequestItems = ({ data = {}, modalCloseHandler = () => {} }) => {
    const { fetchingReport, handleGetPdfReport, reportData } = useGetPdfReport()

    const [itemsData, setItemsData] = useState([])

    useEffect(() => {
        if (data?.items?.length) {
            setItemsData([...data?.items])
        }
    }, [data])

    return (
        <>
            {itemsData?.length && (
                <div>
                    <h3 className="text-center inv-req-popup-heading">
                        Request Id : {data?.id || "-"}
                    </h3>
                    <DataTable
                        data={itemsData}
                        className="view-detail-inv-req-table department-req-items-table finance-vertical-scroll-om"
                        tableClasses="finance-om-pullback-detail-table"
                        config={[
                            {
                                title: COLUMNS.ITEM_NAME,
                                render: data => {
                                    return data?.itemName
                                },
                            },
                            {
                                title: COLUMNS.SKU_NO,
                                render: data => {
                                    return data?.skuNo
                                },
                            },
                            {
                                title: COLUMNS.REQUESTED_QUANTITY,
                                render: data => {
                                    return data?.quantity
                                },
                            },
                            {
                                title: COLUMNS.QUANTITY_SUPPLIED,
                                render: data => {
                                    return data?.quantitySupplied
                                },
                            },
                            {
                                title: COLUMNS.REQUESTED_DATE,
                                render: data => {
                                    return convertDateTime({
                                        date: data.createdAt,
                                        customFormat:
                                            dateTimeFormat.casitaCustomDateFormat,
                                        dateOnly: true,
                                    })
                                },
                            },
                            {
                                title: COLUMNS.STATUS,
                                render: (data, idx) => {
                                    return (
                                        <div
                                            className="d-flex align-items-center justify-content-evenly"
                                            key={idx}
                                        >
                                            <div
                                                className={`inv-req-popup-status ${InventoryReqItemsStatus.getLabelClass(
                                                    data.status
                                                )}`}
                                            >
                                                {InventoryReqItemsStatus.getDisplayTextKey(
                                                    data.status
                                                )}
                                            </div>
                                        </div>
                                    )
                                },
                            },
                        ]}
                    />
                    <div className="text-center my-4 pullback-detail-btn-con">
                        <Button
                            size="lg"
                            className="gt-btn-grad-primary table-bottom-btn me-2"
                            title={<div>Done</div>}
                            onClick={modalCloseHandler}
                        />
                        <Button
                            size="lg"
                            className="gt-btn-grad-primary table-bottom-btn mx-2 px-5"
                            title={<div>Print Orders</div>}
                            isLoading={fetchingReport}
                            onClick={() =>
                                handleGetPdfReport(data?.id, PDF_TYPE)
                            }
                        />
                    </div>
                </div>
            )}
        </>
    )
}

export default PullbackRequestItems
