import React from "react"
import { useParams } from "react-router-dom"

import ManageTrailerManagement from "./ManageTrailerManagement"

const UpdateTrailerManagement = () => {
   const { trailerId } = useParams()

   return <ManageTrailerManagement id={trailerId} isDetailView={null} />
}

export default UpdateTrailerManagement
