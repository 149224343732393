import React, { useEffect, useState } from "react"
import { Col, Row } from "reactstrap"
import {
   Switch,
   Route,
   useHistory,
   Redirect,
   useParams,
} from "react-router-dom"

import baseFeaturesInactiveIcon from "../../../assets/images/icons/base-features-inactive-icon.png"
import baseFeaturesActiveIcon from "../../../assets/images/icons/base-features-active-icon.png"
import packagesInactiveIcon from "../../../assets/images/icons/packages-inactive-icon.png"
import packagesActiveIcon from "../../../assets/images/icons/packages-active-icon.png"
import customizationsInactiveIcon from "../../../assets/images/icons/customization-inactive-icon.png"
import customizationsActiveIcon from "../../../assets/images/icons/customization-active-icon.png"
import equipmentInactiveIcon from "../../../assets/images/icons/equipment-inactive-icon.png"
import equipmentActiveIcon from "../../../assets/images/icons/equipment-active-icon.png"
import summaryInactiveIcon from "../../../assets/images/icons/summary-inactive-icon.png"
import summaryActiveIcon from "../../../assets/images/icons/summary-active-icon.png"
import { NavTabsRoutingBasedButtons as NavButtons } from "../../AdminApp/Common/NavTabsRoutingBased"
import { BaseFeatures, BaseFeaturesCategoryItems } from "../BaseFeatures"
import { Packages } from "../Packages"
import { Customization, CustomizationCategoryItems } from "../Customization"
import { Equipment, EquipmentCategoryItems } from "../Equipment"
import { Summary } from "../Summary"
import { Build, BuildStationItems } from "../Build"
import { APP_ROUTES, getRoute } from "../../../helpers/routeHelpers"
import { GetTrailersDetails } from "api/api.service"
import { TrailerSize } from "../../../constants"
import { PackageItems } from "../Packages"

const TABS_KEYS = {
   BuildTab: "buildTab",
   BaseFeatureTab: "baseFeaturesTab",
   PackagesTab: "packagesTab",
   CustomizationTab: "customizationTab",
   EquipmentTab: "equipmentTab",
   SummaryTab: "summaryTab",
}

const TABS_NAME = {
   Build: "Build",
   BaseFeatures: "Base Features",
   Packages: "Packages",
   Customization: "Customization",
   Equipment: "Equipment",
   Summary: "Summary",
}

const TABS_STATUS = {
   [TABS_KEYS.BuildTab]: false,
   [TABS_KEYS.BaseFeatureTab]: false,
   [TABS_KEYS.PackagesTab]: false,
   [TABS_KEYS.CustomizationTab]: false,
   [TABS_KEYS.EquipmentTab]: false,
   [TABS_KEYS.SummaryTab]: false,
}

const ROUTES_CONFIG = [
   {
      name: TABS_NAME.Build,
      isExact: true,
      component: Build,
      path: APP_ROUTES.CONFIGURATOR_TRAILER_SETTINGS_BUILD,
   },

   {
      name: TABS_NAME.BaseFeatures,
      isExact: true,
      component: BaseFeatures,
      path: APP_ROUTES.CONFIGURATOR_TRAILER_SETTINGS_BASE_FEATURES,
   },

   {
      name: TABS_NAME.Packages,
      isExact: true,
      component: Packages,
      path: APP_ROUTES.CONFIGURATOR_TRAILER_SETTINGS_PACKAGES,
   },
   {
      name: TABS_NAME.Customization,
      isExact: true,
      component: Customization,
      path: APP_ROUTES.CONFIGURATOR_TRAILER_SETTINGS_CUSTOMIZATION,
   },

   {
      name: null,
      isExact: true,
      component: CustomizationCategoryItems,
      path: APP_ROUTES.CONFIGURATOR_TRAILER_SETTINGS_CUSTOMIZATION_ITEMS,
   },
   {
      name: TABS_NAME.Equipment,
      isExact: true,
      component: Equipment,
      path: APP_ROUTES.CONFIGURATOR_TRAILER_SETTINGS_EQUIPMENTS,
   },
   {
      name: null,
      isExact: true,
      component: EquipmentCategoryItems,
      path: APP_ROUTES.CONFIGURATOR_TRAILER_SETTINGS_EQUIPMENT_ITEMS,
   },

   {
      name: TABS_NAME.Summary,
      isExact: false,
      component: Summary,
      path: APP_ROUTES.CONFIGURATOR_TRAILER_SETTINGS_SUMMARY,
   },
   {
      name: null,
      isExact: true,
      component: BaseFeaturesCategoryItems,
      path: APP_ROUTES.CONFIGURATOR_TRAILER_SETTINGS_BASE_FEATURES_ITEMS,
   },

   {
      name: null,
      isExact: true,
      component: BuildStationItems,
      path: APP_ROUTES.CONFIGURATOR_TRAILER_SETTINGS_BUILD_STATION_ITEMS,
   },
   {
      name: null,
      isExact: true,
      component: PackageItems,
      path: APP_ROUTES.CONFIGURATOR_TRAILER_SETTINGS_PACKAGES_ITEMS,
   },
]

const TrailerSettings = () => {
   const history = useHistory()
   const { trailerId } = useParams()
   const trailerIdParamObj = { trailerId: trailerId }

   const ROUTES_BASED_KEYS = {
      [getRoute(APP_ROUTES.CONFIGURATOR_TRAILER_SETTINGS_BUILD, {
         ...trailerIdParamObj,
      })]: TABS_KEYS.BuildTab,

      [getRoute(APP_ROUTES.CONFIGURATOR_TRAILER_SETTINGS_BASE_FEATURES, {
         ...trailerIdParamObj,
      })]: TABS_KEYS.BaseFeatureTab,
      [getRoute(APP_ROUTES.CONFIGURATOR_TRAILER_SETTINGS_PACKAGES, {
         ...trailerIdParamObj,
      })]: TABS_KEYS.PackagesTab,
      [getRoute(APP_ROUTES.CONFIGURATOR_TRAILER_SETTINGS_CUSTOMIZATION, {
         ...trailerIdParamObj,
      })]: TABS_KEYS.CustomizationTab,
      [getRoute(APP_ROUTES.CONFIGURATOR_TRAILER_SETTINGS_EQUIPMENTS, {
         ...trailerIdParamObj,
      })]: TABS_KEYS.EquipmentTab,
      [getRoute(
         APP_ROUTES.CONFIGURATOR_TRAILER_SETTINGS_SUMMARY_BASE_SETTINGS,
         {
            ...trailerIdParamObj,
         }
      )]: TABS_KEYS.SummaryTab,
   }
   const [isActive, setIsActive] = useState({
      ...TABS_STATUS,
      [ROUTES_BASED_KEYS[
         getRoute(history.location.pathname, { ...trailerIdParamObj })
      ]]: true,
   })

   const [trailerDetails, setTrailerDetails] = useState({})
   const [isFetching, setIsFetching] = useState(false)

   useEffect(() => {
      handleGetTrailerDetails()
   }, [trailerId])

   const TABS_CONFIG = [
      {
         title: TABS_NAME.Build,
         onClick: () => {
            setIsActive({
               ...TABS_STATUS,
               buildTab: true,
            })
            history.push(
               getRoute(APP_ROUTES.CONFIGURATOR_TRAILER_SETTINGS_BUILD, {
                  ...trailerIdParamObj,
               })
            )
         },
         classesButton: "al-nav-link",
         identifier: TABS_KEYS.BuildTab,
         icon: baseFeaturesInactiveIcon,
         activeIcon: baseFeaturesActiveIcon,
      },

      {
         title: TABS_NAME.BaseFeatures,
         onClick: () => {
            setIsActive({
               ...TABS_STATUS,
               baseFeaturesTab: true,
            })
            history.push(
               getRoute(
                  APP_ROUTES.CONFIGURATOR_TRAILER_SETTINGS_BASE_FEATURES,
                  {
                     ...trailerIdParamObj,
                  }
               )
            )
         },
         classesButton: "al-nav-link",
         identifier: TABS_KEYS.BaseFeatureTab,
         icon: baseFeaturesInactiveIcon,
         activeIcon: baseFeaturesActiveIcon,
      },

      {
         title: TABS_NAME.Packages,
         onClick: () => {
            setIsActive({
               ...TABS_STATUS,
               packagesTab: true,
            })
            history.push(
               getRoute(APP_ROUTES.CONFIGURATOR_TRAILER_SETTINGS_PACKAGES, {
                  ...trailerIdParamObj,
               })
            )
         },
         classesButton: "al-nav-link",
         identifier: TABS_KEYS.PackagesTab,
         icon: packagesInactiveIcon,
         activeIcon: packagesActiveIcon,
      },

      {
         title: TABS_NAME.Customization,
         onClick: () => {
            setIsActive({
               ...TABS_STATUS,
               customizationTab: true,
            })
            history.push(
               getRoute(
                  APP_ROUTES.CONFIGURATOR_TRAILER_SETTINGS_CUSTOMIZATION,
                  {
                     ...trailerIdParamObj,
                  }
               )
            )
         },
         classesButton: "al-nav-link",
         identifier: TABS_KEYS.CustomizationTab,
         icon: customizationsInactiveIcon,
         activeIcon: customizationsActiveIcon,
      },

      {
         title: TABS_NAME.Equipment,
         onClick: () => {
            setIsActive({
               ...TABS_STATUS,
               equipmentTab: true,
            })
            history.push(
               getRoute(APP_ROUTES.CONFIGURATOR_TRAILER_SETTINGS_EQUIPMENTS, {
                  ...trailerIdParamObj,
               })
            )
         },
         classesButton: "al-nav-link",
         identifier: TABS_KEYS.EquipmentTab,
         icon: equipmentInactiveIcon,
         activeIcon: equipmentActiveIcon,
      },

      {
         title: TABS_NAME.Summary,
         onClick: () => {
            setIsActive({
               ...TABS_STATUS,
               summaryTab: true,
            })
            history.push(
               getRoute(APP_ROUTES.CONFIGURATOR_TRAILER_SETTINGS_SUMMARY, {
                  ...trailerIdParamObj,
               })
            )
         },
         classesButton: "al-nav-link",
         identifier: TABS_KEYS.SummaryTab,
         icon: summaryInactiveIcon,
         activeIcon: summaryActiveIcon,
      },
   ]

   const handleGetTrailerDetails = async () => {
      try {
         setIsFetching(true)
         let res = await GetTrailersDetails(trailerId)
         setTrailerDetails(res)
      } catch (error) {
      } finally {
         setIsFetching(false)
      }
   }

   const makeTrailerName = obj => {
      return isFetching
         ? "...."
         : `${TrailerSize.getDisplayTextKey(obj.configuratorTrailerSizeType)} ${
              obj?.configuratorTrailerType
           } ${obj?.model}`
   }
   return (
      <>
         <Row>
            <Col className="col-12">
               <div className="d-flex align-items-baseline">
                  <h3>Configurator Settings</h3>
               </div>
               {/* 
                    Hide due to api changes
                    <h1 className="m-0 p-0 fw-bold text-blue">
                        {makeTrailerName(trailerDetails)}
                    </h1> */}

               <div className=" admin-location mb-3 mt-4">
                  <div className="al-navtabs-main">
                     <div className="al-nav-tabs">
                        <div className="al-nav-item d-flex">
                           <NavButtons
                              data={TABS_CONFIG}
                              classes={"ps-1 flex-wrap d-inline-block"}
                              isActive={isActive}
                           />
                        </div>
                     </div>
                  </div>
               </div>
               <Switch>
                  {ROUTES_CONFIG.map(
                     ({ name, isExact, path, component }, idx) => (
                        <Route
                           key={idx}
                           name={name}
                           exact={isExact}
                           path={path}
                           component={component}
                        />
                     )
                  )}
                  <Redirect
                     to={APP_ROUTES.CONFIGURATOR_TRAILER_SETTINGS_BASE_FEATURES}
                  />
               </Switch>
            </Col>
         </Row>
      </>
   )
}

export default TrailerSettings
